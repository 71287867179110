import React, { useEffect } from 'react'
import { Paper } from '@material-ui/core'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeeDetails } from '../../slice/employee'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import PageHeader from '../Common/PageHeader'
import { CUST_LIST } from '../../utils/links';
import { VIEW_CUST_DETAILS_HEADER, VIEW_CUST_DETAILS_SUBHEADER, BACK_BUTTON } from '../../utils/pageHeadings'
import GridView from './GridView';


const CustomerDetails = () => {
  const { _id } = useParams();
  const classes = makemyStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployeeDetails(_id));
  }, []);

  const { employeeDetails } = useSelector((state) => state.employee)

  return (
    <>
      <Paper className={classes.pageContent}>

        <PageHeader header={VIEW_CUST_DETAILS_HEADER} subHeader={VIEW_CUST_DETAILS_SUBHEADER} icon={BACK_BUTTON} link={CUST_LIST} />

        <Paper className={classes.detailsCard}>

          <GridView
            leftHeader="Name"
            leftDetail={employeeDetails.name}
            rightHeader="RT/DT Code"
            rightDetail={employeeDetails.userid}
          />
          <GridView
            leftHeader="Address"
            leftDetail={employeeDetails.address}
            rightHeader="Mobile No"
            rightDetail={employeeDetails.mobile}
          />
          <GridView
            leftHeader="Pin Code"
            leftDetail={employeeDetails.pincode}
            rightHeader="Region"
            rightDetail={employeeDetails && employeeDetails.region && employeeDetails.region.name}
          />
          <GridView
            leftHeader="User Id"
            leftDetail={employeeDetails.userid}
            rightHeader="Email"
            rightDetail={employeeDetails.email}
          />
          <GridView
            leftHeader="Aadhar Card No"
            leftDetail={employeeDetails.aadhar}
            rightHeader="Pan Card No"
            rightDetail={employeeDetails.pan}
          />
          <GridView
            leftHeader="Role"
            leftDetail={employeeDetails.role}
            rightHeader="Customer Type"
            rightDetail={employeeDetails.user_type}
          />

        </Paper>
      </Paper>
    </>
  )
}

export default CustomerDetails;
