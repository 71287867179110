import React from 'react'
import './dashboard.css'
import WelcomeCard from './WelcomeCard/WelcomeCard';
import LoanStatus from './LoanStatus/LoanStatus';
import TotalRevenue from './TotalRevenue/TotalRevenue';
import BusinessSummary from './BusinessSummary/BusinessSummary';
import Repayments from './Repayments/Repayments';
import ApplicationStatus from './ApplicationStatus/ApplicationStatus';

const Dashboard = () => {
    return (
        <>
            <section className="welcome section-container">
                <WelcomeCard />
            </section>
            <section className="section-container displayflexcol">
                <LoanStatus />
                <TotalRevenue />
            </section>
            <section className="section-container displayflexcol">
                <BusinessSummary />
                <Repayments />
            </section>
            <section className="section-container displayflexcol">
                <ApplicationStatus />
            </section>
        </>
    )
}

export default Dashboard