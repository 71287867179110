import React, { useState } from 'react'
import { makeStyles, Paper } from '@material-ui/core'
export function useForm(initialValues, validateOnChange = false, validate) {
    
    const [values, setValues] = useState(initialValues);

    const [errors, setErrors] = useState({});
    const [anchor, setAnchor] =useState();

    const handleInputChange = e => {
        const { name, value } = e.target

        setValues({
            ...values,
            [name]: value
        })
        // console.log("VALUES", values) 

        if (validateOnChange)
            validate({ [name]: value })
    }
    const handleDateChange = (fieldname,dates, dateStrings) => {
        setValues({
            ...values,
            [fieldname]: dateStrings
        })
        //console.log("VALUES", values) 

        if (validateOnChange)
            validate({ [fieldname]: dateStrings })
    }

    const handleClick = (event) => {
        setAnchor(event.current.target);
    }

    const resetForm = () => {
        setValues(initialValues);
        setErrors({})
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        anchor,
        setAnchor,
        handleInputChange,
        handleDateChange,
        handleClick,
        resetForm,
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginTop: "100px"
    },
}))

export function Form(props) {
    const classes = useStyles()
    const { children, ...other } = props;
    return (
        // <Paper className={classes.pageContent}>
            <form className={classes.root} autoComplete="off" {...other}>
                {props.children}
            </form>
        // </Paper>
    )
}

