import React from "react";
import { Grid, Typography, ListItemText } from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'

const CurrencyView = () => {

  const classes = makemyStyles();
  const { loanDetails } = useSelector((store) => store.loanProducts);

  return (
    <>
      <Grid>
        <h1 style={{ paddingLeft: "30px" }} className={classes.secHeading}>Currency</h1>
        <hr />
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={
                  <Typography className={classes.employeeItems}>
                    Currency:
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography className={classes.employeeItemsDetails}>
                    {loanDetails.currency || <Skeleton />}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Decimal Places:</Typography>}
              />
              <ListItemText
                primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.decimalplaces || <Skeleton />}</Typography>}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Installment in multiples of:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.installment_in_multiples_of || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "35px" }}></Grid>
      </Grid>
    </>
  );
};

export default CurrencyView;
