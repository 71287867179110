import React, { useEffect, useState } from 'react'
import './Dashboardstyles.css'
import { Grid, Paper } from '@material-ui/core'
import InterestCard from './InterestCard'
import DashboardGreeting from './DashboardGreeting';
import DateTimeBar from './DateTimeBar'
import LoanOffersGenerated from './LoanOffersGenerated'
import DisbursedChart from './DisbursedChart'
import SanctionedChart from './SanctionedChart'
import DefaultedChart from './DefaultedChart'
import TrancheRequestChart from './TrancheRequestChart'
import { useDispatch } from 'react-redux';
import RegionGraphs from './RegionGraphs';
import GridView from './GridView';
import RepaymentsChart from './RepaymentsChart';
import SelectYear from './SelectYear';


const Dashboardthree = () => {

    let curYear = new Date().getFullYear()
    let years = []
    for (let i = curYear; i >= 2022; i--) {
        years.push(i)
    }
    const [year, setYear] = useState(years[0])

    const dashComponents = {
        'greeting': <DashboardGreeting />,
        'selectYear': <SelectYear year={year} setYear={setYear} years={years} />,
        'datetime': <DateTimeBar />,
        'intCard': <InterestCard year={year} />,
        'sancChart': <SanctionedChart year={year} />,
        'disbChart': <DisbursedChart year={year} />,
        'defaultChart': <DefaultedChart year={year} />,
        'loanOffersGen': <LoanOffersGenerated year={year} />,
        'repayments': <RepaymentsChart year={year} />,
        'trancheReq': <TrancheRequestChart />,
        'regionGraphs': <RegionGraphs year={year} />,
        // 'disbRegionWise' : <DisbursedRegionWise />
    }

    return (
        <Paper style={{position: 'relative'}}>
            <GridView {...dashComponents} />
        </Paper>
    )
}

export default Dashboardthree