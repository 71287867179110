import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { ApplicationContext } from './LoanSanctioned'

const Bank = () => {

  const loanSanctionedView = useContext(ApplicationContext)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Bank Name:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.bankInfo && loanSanctionedView.bankInfo.bankName}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Branch:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.bankInfo && loanSanctionedView.bankInfo.branchName}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Account Holder Name:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.bankInfo && loanSanctionedView.bankInfo.accountHolderName}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Account Number:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.bankInfo && loanSanctionedView.bankInfo.accountNumber}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            IFSC Code:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView && loanSanctionedView.bankInfo && loanSanctionedView.bankInfo.ifscCode}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Bank