import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'
export const getInProgressRepaymentsList = createAsyncThunk(
    'getInProgressRepaymentslist',
    async ({ pageNo, searchQry, fromDate, toDate }) => {
        let dates = '';
        if(fromDate && toDate){
            dates = `&fromDate=${fromDate}&toDate=${toDate}`
        }
        const url = `/loans/inprogress-repayments?page=${pageNo}&limit=10&searchqry=${searchQry}${dates}`;
        const res = await DataService.get(url)
        return res.data;
    }
)
export const getInProgressRepaymentsDataExcel = createAsyncThunk(
    'getInProgressRepaymentsDataExcel',
    async (data, thunkAPI) => {
        const { searchQry, fromDate, toDate, totalCount } = data;
        try {
            let dates = '';
            if(fromDate && toDate){
                dates = `&fromDate=${fromDate}&toDate=${toDate}`
            }
            const url = `/loans/inprogress-repayments?page=1&limit=${totalCount}&searchqry=${searchQry}${dates}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);
const initialState = {
    inProgressRepaymentsList: [],
    totalCount: 10,
    excelDataSheet: [],
    inProgressRepaymentsDataExcel: [],
    isLoading: true
}
const inProgressRepaymentsSlice = createSlice({
    name: 'InProgressRepaymentsSlice',
    initialState,
    extraReducers: {
        [getInProgressRepaymentsList.pending]: (state) => {
            state.isLoading = true
        },
        [getInProgressRepaymentsList.fulfilled]: (state, action) => {
            state.inProgressRepaymentsList = action.payload.data
            state.totalCount = action.payload.totalItems
            state.isLoading = false
        },
        [getInProgressRepaymentsList.rejected]: (state) => {
            state.isLoading = false
        },
        [getInProgressRepaymentsDataExcel.fulfilled]: (state, action) => {
            state.inProgressRepaymentsDataExcel = action.payload.data;
        },
        // [generateExcel.fulfilled]: (state, action) => {
        //     state.excelDataSheet = action.payload.data
        // }
    }
})
export default inProgressRepaymentsSlice.reducer