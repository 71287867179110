import React from 'react'
import { Toolbar, Typography, Container, Tooltip, IconButton } from '@material-ui/core'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import { Link } from 'react-router-dom'

const PageHeader = ({ header, subHeader, icon, link }) => {

    const classes = makemyStyles()

    return (
        <div style={{display:'flex', gap:'20px', alignItems:'center'}}>
            {
                icon ? (
                    <div className={classes.headerBackButton}>
                        <Link to={link}>
                            <Tooltip title="Go Back">
                                <IconButton>
                                    {icon}
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </div>
                ) : ''
            }
            <div className={icon ? classes.headerParentBack : classes.headerParentNoBack}>
                {/* <h2 className={classes.pageHeader}>{header}</h2> */}
                <h4 className={classes.subHeader}>{subHeader}</h4>
            </div>
        </div>
    )
}

export default PageHeader