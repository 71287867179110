import React, { useEffect, useState } from 'react'
import {
    Paper,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Button,
    TextField
} from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import { REPAYMENTS_HEADER, REPAYMENTS_SUB_HEADER } from "../../utils/pageHeadings";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../Common/HeadCells";
import useTable from "../Sharedfeatures/useTable";
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import { useDispatch, useSelector } from "react-redux";
import ProgressSpinner from "../Common/ProgressSpinner";
import { getRepaymentsList, getRepaymentsDataExcel, generateExcel } from '../../slice/repayments'
import { PAGE_SIZE } from "../../utils/variable";
import DownloadIcon from '@mui/icons-material/Download';
import { DatePicker } from 'antd';
import moment from 'moment';
import { disableUpcomingDates } from '../../utils/disableDates';

const Repayment = () => {

    //For getting today and yesterday date
    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const classes = makemyStyles();
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const [excelSheetData, setExcelSheetData] = useState([]);

    const { repaymentsList, repaymentDataExcel, totalCount, isLoading } = useSelector((store) => store.repayments)

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(
        repaymentsList,
        HeadCells.repaymentHead,
        totalCount
    );

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    // useEffect(() => {
    //     const dates = {
    //         fromDate,
    //         toDate
    //     }
    //     dispatch(generateExcel(dates))
    // }, [fromDate, toDate])

    const excelData = useExcelDownload()

    useEffect(() => {
        const data = {
            searchTerm: search,
            pageNum: pageNo,
            fromDate,
            toDate,
            totalCount
        }
        dispatch(getRepaymentsList(data));
        dispatch(getRepaymentsDataExcel(data));
        setShowPageNtn(true);
    }, [dispatch, fromDate, toDate, pageNo, search, totalCount]);

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    useEffect(() => {
        let excelsheetdata = []
        repaymentDataExcel?.map(item => {
            excelsheetdata.push({
                "Tranche Id": item.trancheid,
                "Name": item.name,
                "Amount": item.amount,
                "Bank Reference Num": item.bank_reference_number,
                "Principal": item.principal_portion,
                "Interest": item.interest_portion,
                "Penalty": item.penalty,
                "Transaction Date": moment(item.transaction_date).format('DD MMMM YYYY'),
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [repaymentDataExcel])

    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader header={`${REPAYMENTS_HEADER}`} subHeader={`${REPAYMENTS_SUB_HEADER}`} />
                <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                    <TextField
                        size="small"
                        className={classes.searchTextBar}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    />
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                            format={dateFormat}
                            className={classes.datePicker}
                            onChange={onDateChange}
                            disabledDate={disableUpcomingDates}

                        />
                        <Button className={classes.excelUploadButton}
                            onClick={() => excelData.generateExcelFile(excelSheetData, 'RepaymentDataSheet', 'RepaymentData.xlsx')}
                            endIcon={<DownloadIcon />}>Download Ledger</Button>
                    </div>
                </div>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8} align='center' className={classes.tableCell}>
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (repaymentsList.length !== 0) ? (
                                            repaymentsList && repaymentsList.map((item, key) => {
                                                key++;
                                                serialNo++;
                                                return (
                                                    <TableRow key={key.toString()}>
                                                        <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.trancheid}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                        <TableCell className={classes.tableCell}>{Number(item.amount).toFixed(2)}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.bank_reference_number}</TableCell>
                                                        <TableCell className={classes.tableCell}>{Number(item.principal_portion).toFixed(2)}</TableCell>
                                                        <TableCell className={classes.tableCell}>{Number(item.interest_portion).toFixed(2)}</TableCell>
                                                        <TableCell className={classes.tableCell}>{Number(item.penalty).toFixed(2)}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.transaction_date}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={8} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {showPageNtn ? <TblPagination /> : ""}
            </Paper>
        </>
    )
}

export default Repayment