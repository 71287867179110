import { StaticDatePicker } from '@mui/lab';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService';
import { setMessage } from './message';


export const login = createAsyncThunk(
    "users/login/partneradmin",
    async ({ username, password }, thunkAPI) => {
        try {
            const data = await DataService.login(username, password);
            localStorage.setItem("accessToken", data.data.token);
            return data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.toString();
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

export const logout = createAsyncThunk(
    "logout",
    async () => {
        const url = `/users/logout/superadmin`;
        const res = await DataService.logout(url);
        localStorage.removeItem("accessToken");
        return res.data
    });


const initialState = {
    user: [],
    isLoggedIn: [],
    message: '',
    accessToken: '',
    flag: true
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.accessToken = action.payload.data.token;
            state.message = ""
        },
        [login.rejected]: (state, action) => {
            state.message = action.payload;
            state.flag = !state.flag;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        }
    }
})

const { reducer } = authSlice
export default reducer