import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getExpiryList = createAsyncThunk(
    'getExpiryList',
    async (data) => {
        const {month, year, pageNo } = data;
        try {
            const url = `customers/expire-users?month=${month}&year=${year}&page=${pageNo}&limit=10`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })
export const getExpiryListDataExcel = createAsyncThunk(
    "getExpiryListDataExcel",
    async (data, thunkAPI) => {
        const {month, year, pageNo } = data;
        try {
            const url = `customers/expire-users?month=${month}&year=${year}&page=${pageNo}&limit=0`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
const initialState = {
    isLoading: true,
    expiryListData: [],
    totalCount: '',
    expiryListDataExcel:[]
}

const expiryListSlice = createSlice({
    name: 'expirylistslice',
    initialState,
    extraReducers: {
        [getExpiryList.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getExpiryList.fulfilled]: (state, { payload }) => {
            return { ...state, expiryListData: payload?.data?.data,totalCount :payload?.data?.totalItmes, isLoading: false, }
        },
        [getExpiryList.rejected]: (state) => {
            return { ...state, isLoading: false }
        },
        [getExpiryListDataExcel.fulfilled]: (state, {payload}) => {
            return { ...state, expiryListDataExcel:payload?.data }
        },
    }
})
export default expiryListSlice.reducer