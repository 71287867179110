import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getdpd = createAsyncThunk('getdpd', async (value) => {
    try {
        const url = `/console-web/dpd-state-wise`
        const response = await DataService.post(url, value)
        return response
    } catch (err) {
        return err.response
    }
})

const initialState = {
    isLoading: true,
    dpdData: '',
}

const dpdSlice = createSlice({
    name: 'dpdslice',
    initialState,
    extraReducers: {
        [getdpd.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getdpd.fulfilled]: (state, { payload }) => {
            return { ...state, dpdData: payload?.data, isLoading: false }
        },
    }
})

export const getdpdData = (state) => state.dpdapi;
export default dpdSlice.reducer