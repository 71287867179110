import { OneKPlusOutlined } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from '../services/DataService'

export const loanProductsList = createAsyncThunk(
    "loanProductsList",
    async ({ searchTerm, pageNum }, thunkAPI) => {
        try {
            const url = `/loans/loan-list?page=${pageNum}&limit=10&searchqry=${searchTerm}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

//download loanProducts excel
export const getloanProductsExcel = createAsyncThunk(
    "loanProductsExcel",
    async ({ searchTerm, pageNum, totalCount }, thunkAPI) => {
        try {
            const url = searchTerm ? `/loans/loan-list?page=${pageNum}&limit=10&searchqry=${searchTerm}`: `/loans/loan-list?page=1&limit=${totalCount}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

export const loanProductDetails = createAsyncThunk(
    "loanProductDetails",
    async (id, thunkAPI) => {
        try {
            const url = `/loans/productdetails/${id}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

const initialState = {
    loanList: [],
    loanPdtExcel: [],
    totalCount: '',
    isLoading: true,
    loanDetails: '',
}

const loanSlice = createSlice({
    name: 'loanProductsSlice',
    initialState,
    extraReducers: {
        [loanProductsList.pending]: (state) => {
            state.isLoading = true
        },
        [loanProductsList.fulfilled]: (state, action) => {
            state.loanList = action.payload.data;
            state.totalCount = action.payload.totalItmes
            state.isLoading = false
        },
        [loanProductsList.rejected]: (state) => {
            state.isLoading = false
        },
        [getloanProductsExcel.fulfilled]: (state, action) => {
            state.loanPdtExcel = action.payload.data;
        },
        [loanProductDetails.fulfilled]: (state, action) => {
            state.loanDetails = action.payload.data
        }
    }
})

const { reducer } = loanSlice
export default reducer