import { IoIosArrowBack } from 'react-icons/io'

export const BACK_BUTTON = <IoIosArrowBack color='#963460'/>

export const DASH_HEADER = 'Dashboard'
export const DASH_SUB_HEADER = 'Home / Dashboard'

export const REGION_HEADER = 'Regions';
export const REGION_SUB_HEADER = 'Home / Regions'
export const EDIT_REGION_HEADER = 'Edit Region'
export const ADD_REGION_HEADER = 'Add Region'
export const NEW_REGION_SUB_HEADER = 'Home / Regions / New Region'

export const FOS_HEADER = "FOS";
export const FOS_SUB_HEADER = "Home / FOS";
export const ADD_FOS_HEADER = "Add FOS";
export const EDIT_FOS_HEADER = "Edit FOS";
export const ADD_FOS_SUB_HEADER = "Home / FOS / New FOS";

export const CUST_LIST_HEADER = "Customers"
export const CUST_LIST_SUBHEADER = "Home / Customers"
export const CUST_MODAL_HEADER = "Upload your customer data"
export const ADD_CUST_HEADER = "Add New Customer"
export const ADD_CUST_SUBHEADER = "Home / Customers / New Customer"
export const VIEW_CUST_DETAILS_HEADER = "Customer Details"
export const VIEW_CUST_DETAILS_SUBHEADER = "Home / Customers / Customer Details"

export const SALES_DATA_HEADER = "Sales Data"
export const SALES_DATA_SUBHEADER = "Home / Sales Data"
export const SALES_MODAL_HEADER = "Upload your sales data"

export const PRE_APP_HEADER = "Pre Approved Loan Offer"
export const PRE_APP_SUBHEADER = "Home / Pre Approved Loan Offer"
export const PRE_APP_DETAILS_HEADER = "Pre Approved Loan Details"
export const PRE_APP_DETAILS_SUBHEADER = "Home / Pre Approved Loan Offer / Loan Details"

export const LOAN_GEN_HEADER = "Loan Generated"
export const LOAN_GEN_SUBHEADER = "Home / Loan Generated"

export const LOAN_DISP_HEADER = "Loan Disbursed"
export const LOAN_DISP_SUBHEADER = "Home / Loan Disbursed"

export const LOAN_SANCTIONED_HEADER = "Loan Sanctioned";
export const LOAN_SANCTIONED_SUBHEADER = "Home / Loan Sanctioned";
export const LOAN_STATEMENT_HEADER = "Loan Statement";
export const LOAN_STATEMENT_SUBHEADER = "Home / Loan Sanctioned / Loan Account Details / Loan Statement";

export const LOAN_DEFAULTED_HEADER = "Loan Defaulted";
export const LOAN_DEFAULTED_SUBHEADER = "Home / Loan Defaulted";

export const LOAN_CLOSED_HEADER = "Loan Closed";
export const LOAN_CLOSED_SUBHEADER = "Home / Loan Closed";

export const LOAN_PRODUCTS_HEADER = "Loan Products"
export const LOAN_PRODUCTS_SUBHEADER = "Home / Loan Products"
export const LOAN_DETAILS_HEADER = "Loan Details"
export const LOAN_DETAILS_SUBHEADER = "Home / Loan Products / Loan Details"

export const TRANCHE_HEADER = "Tranche Details"
export const TRANCHE_SUB_HEADER = "Home / Loan Sanctioned / Tranche Details"
export const TRANCHE_SANC_SUB_HEADER = "Home / Loan Generated / Loan Sanctioned / Tranche Details"
export const TRANCHE_DISB_SUB_HEADER = "Home / Loan Generated / Loan Disbursed / Tranche Details"

export const REPAYMENTS_HEADER = 'Latest Repayments'
export const REPAYMENTS_SUB_HEADER = 'Home / Latest Repayments'
export const REPAYMENT_DETAILS_HEADER = 'Repayment Details'
export const REPAYMENT_DETAILS_SUB_HEADER = 'Repayment Details'

export const LOAN_ACC_DETAILS_HEADER = 'Loan Account Details'
export const LOAN_ACC_DETAILS_SUB_HEADER = 'Home / Loan Sanctioned / Loan Account Details'

export const LOAN_APPLICATION_HEADER = 'Loan Applications'
export const LOAN_APPLICATION_SUB_HEADER = 'Home / Loan Applications'

export const UPCOMING_REPAYMENTS_HEADER = 'Upcoming Repayments'
export const UPCOMING_REPAYMENTS_SUB_HEADER = 'Home / Upcoming Repayments'
export const IN_PROGRESS_REPAYMENTS_HEADER = 'In Progress Repayments'
export const IN_PROGRESS_REPAYMENTS_SUB_HEADER = 'Home / In Progress Repayments'

export const TRANCHES_LIST_HEADER = 'Tranches Created'
export const TRANCHES_LIST_SUB_HEADER = 'Home / Tranches Created'
export const EXPIRY_LIST_HEADER = 'Expiry List'
export const EXPIRY_LIST_SUB_HEADER = 'Home / Expiry List'
export const CREDITLINE_INUSE_HEADER = 'Creditline In-use'
export const CREDITLINE_INUSE_SUB_HEADER = 'Home / Creditline In-use'

export const MANUAL_REPAYMENT_HEADER = 'Manual Repayment'
export const MANUAL_REPAYMENT_SUB_HEADER = 'Home / Manual Repayment'