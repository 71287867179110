import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

// get all list of pre-Approved Loan Offers
export const getPreApproved = createAsyncThunk(
  "getpreapprovedloanoffer",
  async (data) => {
    const { pageNo, searchTerm, fromDate, toDate } = data
    const url = `loans/pre-approved-loanoffers?page=${pageNo}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

//download preapproved excel
export const getPreApprovedExcel = createAsyncThunk(
  "getpreapprovedexcel",
  async (data) => {
    const { pageNo, searchTerm, fromDate, toDate, totalCount } = data
    const url = searchTerm ? `loans/pre-approved-loanoffers?page=${pageNo}&limit=10&list_type=excel&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`
      : `loans/pre-approved-loanoffers?page=1&limit=${totalCount}&list_type=excel&fromDate=${fromDate}&toDate=${toDate}`
    const res = await DataService.get(url);
    return res.data;
  }
);

// get details of a particular loan
export const getLoanDetails = createAsyncThunk(
  "getLoanDetails",
  async (id, { rejectWithValue }) => {
    try {
      const url = `/loans/pre-approved-loanoffers/${id}`;
      const response = await DataService.get(url);
      return response.data.data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

// assign fos
export const assignFos = createAsyncThunk(
  "Assign FOS",
  async (data, thunkAPI) => {
    try {
      const url = '/loans/assign-fos'
      const res = await DataService.post(url, data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// get fos list
export const getFosList = createAsyncThunk(
  "getFosList",
  async (thunkAPI) => {
    try {
      const url = "/users/getusers";
      const res = await DataService.get(url);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  });

//get algorithm rejected offers
export const getAlgorithmRejected = createAsyncThunk(
  "getAlgorithmRejected",
  async ({ pageNo, searchTerm, fromDate, toDate }, thunkAPI) => {
    try {
      const url = `algorithm/rejected-offers?page=${pageNo}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`;
      const res = await DataService.get(url);
      return res.data;
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

//download algrej excel
export const getAlgorithmRejectedExcel = createAsyncThunk(
  "getalgorithmrejectedexcel",
  async (data) => {
    const { pageNo, searchTerm, totalCount, fromDate, toDate } = data
    const url = searchTerm ? `algorithm/rejected-offers?page=${pageNo}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`
      : `algorithm/rejected-offers?page=1&limit=${totalCount}&fromDate=${fromDate}&toDate=${toDate}`
    const res = await DataService.get(url);
    return res.data;
  }
);

const preapprovedloanSlice = createSlice({
  name: "preApprovedLoanOffers",
  initialState: {
    preApprovedLoanOffer: [],
    preAppExcelData: [],
    fosAssigned: [],
    fosDropdown: [],
    loanDetails: [],
    message: '',
    success: '',
    algRejected: [],
    algRejectedExcel: [],
    analytics: '',
    isLoading: true,
  },
  extraReducers: {
    [getPreApproved.pending]: (state) => {
      state.isLoading = true
    },
    [getPreApproved.fulfilled]: (state, action) => {
      state.analytics = action.payload.analytics
      state.preApprovedLoanOffer = action.payload.data
      state.totalCount = action.payload.totalItmes
      state.isLoading = false
    },
    [getPreApproved.rejected]: (state) => {
      state.isLoading = false
    },
    [getPreApprovedExcel.fulfilled]: (state, action) => {
      state.preAppExcelData = action.payload.data
    },
    [getLoanDetails.pending]: (state, action) => {
      state.isLoading = true
    },
    [getLoanDetails.fulfilled]: (state, action) => {
      state.loanDetails = action.payload
      state.isLoading = false
    },
    [getLoanDetails.rejected]: (state, action) => {
      state.isLoading = false
    },
    [assignFos.fulfilled]: (state, action) => {
      const foslist = state.fosDropdown;
      const fosindex = foslist.findIndex(item =>
        item._id === action.payload.fosId
      )
      const fosname = foslist[fosindex].name

      const list = state.preApprovedLoanOffer;
      const index = list.findIndex(item =>
        item.loanapproval_id === action.payload.loanapproval_id
      )
      state.preApprovedLoanOffer[index].fos = { '_id': action.payload.fosId, 'name': fosname }
    },
    [getFosList.fulfilled]: (state, action) => {
      state.fosDropdown = action.payload
    },
    [getAlgorithmRejected.pending]: (state) => {
      state.isLoading = true
    },
    [getAlgorithmRejected.fulfilled]: (state, action) => {
      state.algRejected = action.payload.data
      state.totalCount = action.payload.totalItmes
      state.isLoading = false
    },
    [getAlgorithmRejected.rejected]: (state) => {
      state.isLoading = false
    },
    [getAlgorithmRejectedExcel.fulfilled]: (state, action) => {
      state.algRejExcelData = action.payload.data
    },
  }
})

const { reducer } = preapprovedloanSlice;
export default reducer;
