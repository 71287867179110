import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";


// get region list
export const getRegionList = createAsyncThunk(
    "getregion",
    async () => {
        const url = '/region/list';
        const res = await DataService.get(url);
        return res.data;
    }
);

// addfos
export const addFos = createAsyncThunk(
    "addfos",
    async (state, thunkAPI) => {
        try {
            const url = '/users/createfos';
            const { ...data } = state;
            const res = await DataService.post(url, data);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    }
)

//editFos
export const editFos = createAsyncThunk(
    "editfos",
    async (values, thunkAPI) => {
        const { id, ...data } = values
        try {
            const url = `/users/update_fos/${id}`;
            // const { ...data } = state;
            const res = await DataService.put(url, data);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    }
)
// get all fos employee
export const getFos = createAsyncThunk(
    "getfos",
    async ({ searchTerm, pageNum, select }, thunkAPI) => {
        try {
            const url = `/users?role=fos&page=${pageNum}&limit=10&searchqry=${searchTerm}&status=${select}`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


//download fos excel
export const getFosExcel = createAsyncThunk(
    "getfosexcel",
    async (data) => {
        const { pageNo, searchTerm, totalCount } = data
        const url = searchTerm ? `/users?role=fos&page=${pageNo}&limit=10&list_type=excel&searchqry=${searchTerm}` : `/users?role=fos&page=1&limit=${totalCount}&list_type=excel`
        const res = await DataService.get(url);
        return res.data;
    }
);

//enable an FOS
export const enableFosRecord = createAsyncThunk(
    "enablefos",
    async (enablingData, thunkAPI) => {
        try {
            const url = '/users/change-status';
            const res = await DataService.post(url, enablingData);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

//disable an Fos
export const disableFosRecord = createAsyncThunk(
    "disablefos",
    async (disablingData, thunkAPI) => {
        try {
            const url = '/users/change-status';
            const { ...data } = disablingData;
            const res = await DataService.post(url, data);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

//To reset state values
export const reset_state = createAsyncThunk(
    "reset_state",
    async () => {
        return;
    }
)

const initialState = {
    regionList: [],
    addFosEmployee: [],
    editFos: {},
    fosDetails: [],
    fosList: [],
    fosExcel: [],
    success: [],
    message: '',
    error: '',
    request_id: '',
    user_id: '',
    email: '',
    mobile: '',
    totalCount: '',
    statusMessage: '',
    isLoading: true,
    error_message: '',
}

const fosSlice = createSlice({
    name: "fos",
    initialState,
    extraReducers: {
        [getRegionList.fulfilled]: (state, action) => {
            state.regionList = action.payload.data
        },
        [addFos.fulfilled]: (state, action) => {
            state.addFosEmployee = action.payload
            state.message = action.payload.message;
            state.success = action.payload.success;
        },
        [addFos.rejected]: (state, action) => {
            state.user_id = action.payload.user_id;
            state.email = action.payload.email;
            state.mobile = action.payload.mobile;
            state.success = action.payload.success;
            state.request_id = action.meta.requestId;
        },
        [getFos.pending]: (state) => {
            state.isLoading = true;
        },
        [getFos.fulfilled]: (state, action) => {
            // console.log('FOS SEARCH', action)
            state.fosList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
            state.isLoading = false;
        },
        [getFos.rejected]: (state) => {
            state.isLoading = false;
        },
        [getFosExcel.fulfilled]: (state, action) => {
            state.fosExcel = action.payload.data;
        },
        [enableFosRecord.fulfilled]: (state, action) => {
            state.message = action.payload.message
            const list = state.fosList
            const index = list.findIndex(item =>
                item._id === action.payload.user_id
            )
            state.fosList[index].active = true
        },
        [enableFosRecord.rejected]: (state, action) => {
            state.error = action.error;
        },
        [disableFosRecord.fulfilled]: (state, action) => {
            state.message = action.payload.message
            const list = state.fosList
            const index = list.findIndex(item =>
                item._id === action.payload.user_id
            )
            state.fosList[index].active = false
        },
        [disableFosRecord.rejected]: (state, action) => {
            state.error = action.error;
        },
        [editFos.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
            state.editFos = action.payload.data;
        },
        [editFos.rejected]: (state, error) => {
            state.error_message = error.payload.message;
            state.success = error.payload.success;
            state.request_id = error.meta.requestId
        },
        [reset_state.fulfilled]: (state) => {
            state.mobile = '';
            state.email = '';
            state.success = '';
            state.fosList = '';
            state.error_message = '';
        },
    }
})

const { reducer } = fosSlice
export default reducer