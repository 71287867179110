import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";
export const manualrepayment = createAsyncThunk(
    "manualrepayment",
    async (values, thunkAPI) => {
        try {
            const url = `support/repayment-ecaps`;
            const res = await DataService.post(url,values);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const initialState = {}

const manualRepaymentSlice = createSlice({
    name: 'manualrepaymentslice',
    initialState,
    extraReducers: {}
})

export default manualRepaymentSlice.reducer