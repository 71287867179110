import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Tooltip,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import PageHeader from "../Common/PageHeader";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import {
  LOAN_PRODUCTS_HEADER,
  LOAN_PRODUCTS_SUBHEADER,
} from "../../utils/pageHeadings";
import { BsSearch } from "react-icons/bs";
import { LOAN_DETAILS } from "../../utils/links";
import useTable from "../Sharedfeatures/useTable";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import { useSelector, useDispatch } from "react-redux";
import { loanProductsList, getloanProductsExcel } from "../../slice/loanproducts";
import { PAGE_SIZE } from "../../utils/variable";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";


const LoanProducts = () => {

  const classes = makemyStyles();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const excelData = useExcelDownload();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [excelSheetData, setExcelSheetData] = useState([]);

  const { loanList, loanPdtExcel, totalCount, isLoading } = useSelector(
    (store) => store.loanProducts
  );
  const { TblContainer, TblPagination, TblHead, pageNo, slNo, handleChange } = useTable(
    loanList,
    HeadCells.loanHead,
    totalCount
  );

  useEffect(() => {
    const data = {
      searchTerm: search,
      pageNum: pageNo,
      totalCount,
    };
    dispatch(loanProductsList(data));
    dispatch(getloanProductsExcel(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, totalCount]);

  let serialNo = PAGE_SIZE * slNo;

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    loanPdtExcel?.map(item => {
      excelsheetdata.push({
        "Loan Product": item.loan_name,
        "Short Name": item.short_name,
        "Max Principal": item.max_principal_amount,
        "Interest Rate": item.interest_rate,
        "Tenure": item.tenure,
        "Currency": item.currency,
        "Installment in multiples of": item.installment_in_multiples_of,
        "Decimal Places": item.decimalplaces,
        "Minimum days between Disbursal and First Repayment Date": item.mingap,
        "Amortization Type": item.amortization_type,
        "Status": item.status,
        "Equal Amortization": item.is_equal_amortization,
        "Principal": item.principal_amount,
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [loanPdtExcel])

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={LOAN_PRODUCTS_HEADER}
          subHeader={LOAN_PRODUCTS_SUBHEADER}
        />


        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }} >
          <TextField
            size="small"
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            id="standard-bare"
            variant="outlined"
            placeholder="Search Loan Product"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />
          <Button
            className={classes.excelUploadButton}
            onClick={() =>
              excelData.generateExcelFile(
                excelSheetData,
                "LoanProductSheet",
                "LoanProducts.xlsx"
              )
            }
            endIcon={<DownloadIcon />}
          >
            Download Report
          </Button>
        </div>
        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    className={classes.tableCell}
                  >
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {loanList.length !== 0 ? (
                  loanList &&
                  loanList.map(({ id, loan_name, short_name, max_principal_amount, interest_rate, tenure }) => {
                    serialNo++;
                    return (
                      <TableRow key={id.toString()}>
                        <TableCell className={classes.tableCell}>
                          {serialNo}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {loan_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {short_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {max_principal_amount}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {interest_rate}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {tenure}
                        </TableCell>
                        <TableCell className={classes.tableCell} align='center'>
                          <Link to={`${LOAN_DETAILS}${id}`}>
                            <Tooltip title="View Product Details">
                              <IconButton>
                                <BsFillEyeFill
                                  className={classes.actionIcon}
                                />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {
          showPageNtn ? <TblPagination /> : ''
        }
      </Paper>
    </>
  );
};

export default LoanProducts;
