import React, { useState, useEffect, createContext } from 'react'
import { Paper, TableBody, TableRow, TableCell, Tooltip, IconButton, TextField, Container, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import HeadCells from "../Common/HeadCells";
import { BsSearch } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import {
    getLoanApplicationDetails,
    getLoanApplications,
    getLoanApplicationsExcel,
    resetStateValues
} from '../../slice/loanapplications';
import { BsFillEyeFill } from "react-icons/bs";
import LoanAppDetailsModal from './LoanAppDetailsModal';
import moment from 'moment';
import useExcelDownload from '../Sharedfeatures/useExcelDownload';
import { DatePicker } from 'antd';
import DownloadIcon from "@mui/icons-material/Download";

const ApplicationContext = createContext()

const LoanApplication = () => {

    //For getting today and yesterday date

    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const classes = makemyStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const {
        applicationList,
        analyticsData,
        loanApplicationData,
        totalCount,
        isLoading,
        applicationDetails
    } = useSelector(store => store.loanApplication);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(applicationList, HeadCells.loanApplicationHead, totalCount);
    const [showPageNtn, setShowPageNtn] = useState(false);

    const excelData = useExcelDownload();

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const [excelSheetData, setExcelSheetData] = useState([]);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    useEffect(() => {
        const data = {
            pageNo: pageNo,
            search: search,
            totalCount,
            fromDate,
            toDate,
        };
        dispatch(getLoanApplications(data))
        dispatch(getLoanApplicationsExcel(data))
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, totalCount, fromDate, toDate,])


    //View Details Modal
    const [viewDetails, setViewDetails] = useState(false)

    const handleDetailsOpen = (loanapproval_id) => {
        dispatch(getLoanApplicationDetails(loanapproval_id))
        setViewDetails(true)
    }

    const handleDetailsClose = () => {
        setViewDetails(false)
        dispatch(resetStateValues())
    }

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    useEffect(() => {
        let excelsheetdata = []
        loanApplicationData?.map(item => {
            excelsheetdata.push({
                "RT/DT Code": item.userid,
                "Name": item.name,
                "Loan Product": item.ShortName,
                "Amount": item.amount,
                "Status": item.statusId,
                "Tenure": item.tenure,
                "Created On": moment(item.created_on).format('DD MMMM YYYY'),
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [loanApplicationData])

    return (
        <div style={{ display: 'flex', gap: '15px', justifyContent: 'space-between', flexDirection: 'column' }} >

            <div className={classes.headContainer} style={{ width: '100%' }}>

                <Container className={classes.innerHeadContainerOne}>
                    <h2 className={classes.containerHeader}>Today Received Loans:</h2>
                    <p className={classes.containerBody}>
                        {analyticsData.today_toatal_application}
                    </p>
                </Container>

                <Container
                    className={classes.innerHeadContainerOne}
                    style={{ border: "2px solid #2d105e", background: "#ffffff", marginLeft: '15px', marginRight: '15px' }}
                >
                    <h2 className={classes.containerHeader} style={{ color: "#2d105e" }}>
                        Approved Loans:
                    </h2>
                    <p className={classes.containerBody} style={{ color: "#2d105e" }}>
                        {analyticsData.approved_application}
                    </p>
                </Container>

                <Container
                    className={classes.innerHeadContainerOne}
                    style={{ border: "2px solid #2d105e", background: "#ffffff" }}
                >
                    <h2 className={classes.containerHeader} style={{ color: "#2d105e" }}>
                        Rejected Loans:
                    </h2>
                    <p className={classes.containerBody} style={{ color: "#2d105e" }}>
                        {analyticsData.rejected_application}
                    </p>
                </Container>
            </div>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                <TextField
                    size="small"
                    className={classes.preAppSearchTextBar}
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Name or RT/DT Code"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        endAdornment: (
                            <IconButton>
                                <BsSearch className={classes.searchIconColour} />
                            </IconButton>
                        ),
                    }}
                />
                <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                    <RangePicker
                        placeholder={["From Date", "To Date"]}
                        format={dateFormat}
                        className={classes.datePickerPre}
                        onChange={onDateChange}
                    />
                    <Button
                        className={classes.preApprovedExcelUploadButton}
                        onClick={() =>
                            excelData.generateExcelFile(
                                excelSheetData,
                                "LoanApplicationSheet",
                                "LoanApplicationList.xlsx"
                            )
                        }
                        endIcon={<DownloadIcon />}
                    >
                        Download Report
                    </Button>
                </div>
            </div>
            <Paper className={classes.tableBoxShadow}>
                <TblContainer>
                    <TblHead />
                    {
                        isLoading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        align="center"
                                        className={classes.tableCell}
                                    >
                                        <ProgressSpinner />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {
                                    applicationList.length !== 0 ? (
                                        applicationList &&
                                        applicationList.map((item, key) => {
                                            serialNo++;
                                            return (
                                                <TableRow key={key.toString()}>
                                                    <TableCell className={classes.tableCell}>
                                                        {serialNo}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.userid}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.ShortName}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.amount}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {(item.statusId == 14) ? 'Pending' : (item.statusId == 15) ? 'Accepted' : (item.statusId == 16) ? 'Rejected' : ''}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.tenure}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {moment(item.created_on).format("DD MMMM YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ paddingLeft: "25px" }}>
                                                        {/* {
                                                                (item.statusId == 16) ? (<Tooltip title="View Details">
                                                                    <IconButton onClick={handleDetailsOpen}>
                                                                        <BsFillEyeFill className={classes.actionIcon} />
                                                                    </IconButton>
                                                                </Tooltip>) : ''
                                                            } */}

                                                        <Tooltip title="View Details">
                                                            <IconButton onClick={() => handleDetailsOpen(item.loanapproval_id)}>
                                                                <BsFillEyeFill className={classes.actionIcon} />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={8} align="center">
                                                No Data to Show
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        )
                    }
                </TblContainer>
            </Paper>
            {showPageNtn ? <TblPagination /> : ""}
            <ApplicationContext.Provider value={applicationDetails}>
                {
                    viewDetails ? <LoanAppDetailsModal open={viewDetails} handleClose={handleDetailsClose} /> : ''
                }
            </ApplicationContext.Provider>

        </div>
    )
}

export default LoanApplication
export { ApplicationContext }