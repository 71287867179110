import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import useStyles from './NotificationModalStyles';


const style = {
    position: 'absolute',
    top: '70px',
    right: '14px',
    width: 350,
    height: '450px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px'
};


const NotificationModal = ({ open, handleClose, notificationList }) => {

    const classes = useStyles()

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box className={classes.headerParent}>
                        <h3 className={classes.header}>Notifications</h3>
                    </Box>

                    <Box className={classes.notifBodyParent}>
                        {
                            notificationList && notificationList.map(item =>
                                <>
                                    <Box className={classes.singleNotifParent}>
                                        <Typography variant="body2" color="#01295F" gutterBottom>
                                            {item.description}
                                        </Typography>
                                        <Typography variant="body2" color="gray" gutterBottom>
                                            {moment(item.createdAt).startOf('day').fromNow()}
                                        </Typography>
                                        {/* {
                                            item.status === 0 ? <div className={classes.newNotifCircle} /> : ''
                                        } */}
                                    </Box>

                                    <hr
                                        style={{
                                            // border: 0,
                                            // clear: 'both',
                                            // display: 'block',
                                            width: '100%',
                                            backgroundColor: '#01295F',
                                            height: '1.3px'
                                        }}
                                    />
                                </>
                            )
                        }
                    </Box>


                    <Box className={classes.notifFooter} />
                </Box>

            </Modal>
        </>
    )
}

export default NotificationModal