import React, {useEffect} from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import './Dashboardstyles.css'
import GraphHeader from './GraphHeader';
import {getPreApprovedLoanOffers} from "../../slice/dashboard"
import { LOAN_OFFER_GEN_HEADER } from '../../utils/graphHeadings';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import {useDispatch, useSelector } from 'react-redux';
import { Card } from '@material-ui/core';

const LoanOffersGenerated = ({year}) => {
  const dispatch = useDispatch();

  useEffect (() => {
    dispatch(getPreApprovedLoanOffers(year));
  },[year, dispatch])

    const { dataFormater } = useYAxisFormatter()
    const { preApprovedLoanOffers, preApprovedLoanOffers_totalVolume } = useSelector((store) => store.dashboard)

    let data = [];
    preApprovedLoanOffers.map((item) => {
        let cdata = {
            name: item.month.substring(0, 3),
            value: Number(item.amount),
        };
        data.push(cdata);
    });


    // const preApprovedLoanOffers_totalVolume = 30226990;
    // const data = [
    //     {
    //         name: "Jan",
    //         value: 0,
    //     },
    //     {
    //         name: "Feb",
    //         value: 0,
    //     },
    //     {
    //         name: "Mar",
    //         value: 0,
    //     },
    //     {
    //         name: "Apr",
    //         value: 0,
    //     },
    //     {
    //         name: "May",
    //         value: 0,
    //     },
    //     {
    //         name: "Jun",
    //         value: 30226990,
    //     },
    //     {
    //         name: "Jul",
    //         value: 0,
    //     },
    //     {
    //         name: "Aug",
    //         value: 0,
    //     },
    //     {
    //         name: "Sep",
    //         value: 0,
    //     },
    //     {
    //         name: "Oct",
    //         value: 0,
    //     },
    //     {
    //         name: "Nov",
    //         value: 0,
    //     },
    //     {
    //         name: "Dec",
    //         value: 0,
    //     },
    // ];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div>
                    <Card style={{ padding: '7px 12px' }}>
                        <p>{`${label} : ₹ ${payload[0].value}`}</p>
                    </Card>
                </div>
            );
        }

        return null;
    };

    return (
        <div className='second-row-card'>

            <GraphHeader header={LOAN_OFFER_GEN_HEADER} subheader={Number(Number(preApprovedLoanOffers_totalVolume).toFixed(2)).toLocaleString()} />

            <ResponsiveContainer width="90%" height="65%">
                <BarChart
                    width={550}
                    height={180}
                    data={data}
                    margin={{
                        left: 20,
                        top: 0,
                    }}
                >
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis axisLine={false} tickFormatter={dataFormater} />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                    <Bar dataKey="value" fill="#FF6978" radius={8} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default LoanOffersGenerated