import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  ListItemText,
  Container,
} from "@material-ui/core";
import PageHeader from "../Common/PageHeader";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'

const SettingsView = () => {
  const classes = makemyStyles();
  const { loanDetails } = useSelector((store) => store.loanProducts);

  return (
    <>
      <Grid >
        <h1 style={{ paddingLeft: "30px" }} className={classes.secHeading}>Settings</h1>
        <hr />
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={
                  <Typography className={classes.employeeItems}>
                    Amortization Type:
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography className={classes.employeeItemsDetails}>
                    {loanDetails.amortization_type || <Skeleton />}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Interest Method</Typography>}
              />
              <ListItemText
                primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.interestType || <Skeleton />}</Typography>}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Equal Amortization:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.is_equal_amortization === true ? "True" : "False" || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Interest Calculation Period:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.interestCalculationPeriodType || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Interest Rate Frequency Type:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.interestRateFrequency || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Interest Rate Per Period:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.interest_rate || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "35px" }}></Grid>
      </Grid>
    </>
  );
};

export default SettingsView;
