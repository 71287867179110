import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanDefaultedList = createAsyncThunk(
    "loandefaultedlisting",
    async ({ pageNo, search, fromDate, toDate }) => {
        const url = `loans/defaulted-list?page=${pageNo}&limit=10&searchqry=${search}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await DataService.get(url);
        return res.data;
    }
);

//download loan defaulted excel
export const getLoanDefaultedExcel = createAsyncThunk(
    "loandefaultedExcel",
    async ({ pageNo, search, fromDate, toDate, totalCount }) => {
        const url = search ? `loans/defaulted-list?page=${pageNo}&limit=10&searchqry=${search}&fromDate=${fromDate}&toDate=${toDate}`: `loans/defaulted-list?page=1&limit=${totalCount}`;
        const res = await DataService.get(url);
        return res.data;
    }
);


// export const generateExcel = createAsyncThunk(
//     "generate excel sheet",
//     async ({ fromDate, toDate }) => {
//         const url = `excel/defaultedlist?fromDate=${fromDate}&toDate=${toDate}`;
//         const res = await DataService.get(url);
//         return res.data;
//     }
// );

const initialState = {
    loanDefaultedList: [],
    loanDefaultedExcel:[],
    totalCount: '',
    isLoading: true,
    loanDefaultedExcelSheet: [],
}

const loanDefaultedSlice = createSlice({
    name: 'loanDefaultedSlice',
    initialState,
    extraReducers: {
        [getLoanDefaultedList.pending]: (state) => {
            state.isLoading = true
        },
        [getLoanDefaultedList.fulfilled]: (state, action) => {
            state.loanDefaultedList = action.payload.data;
            state.totalCount = action.payload.totalItmes
            state.isLoading = false
        },
        [getLoanDefaultedList.rejected]: (state) => {
            state.isLoading = false
        },
        [getLoanDefaultedExcel.fulfilled]: (state, action) => {
            state.loanDefaultedExcel = action.payload.data;
        },
        // [generateExcel.fulfilled]: (state, action) => {
        //     state.loanDefaultedExcelSheet = action.payload.data;
        // },
    }
})

export default loanDefaultedSlice.reducer