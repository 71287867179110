
import React, { useState, useEffect } from 'react';
import { Link, Button, Alert, IconButton, Typography } from '@mui/material';
import logo from '../../assets/images/login-page-logo.svg'
import { useSelector, useDispatch } from 'react-redux'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import { login } from '../../slice/auth'
import './login.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { DASHBOARD } from '../../utils/links'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';


const TextField = styled(TextValidator)(() => ({
    width: '100%',
    margin: '10px 0'
}))

const Login = () => {

    let navigate = useNavigate()
    const dispatch = useDispatch()
    const [state, setState] = useState({ username: '' })
    const [alert, setAlert] = useState();
    const [passType, setPassType] = useState('password');
    const [lockOpen, setLockOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { message, isLoggedIn, flag } = useSelector((state) => state.auth)

    useEffect(() => {
        if (message !== undefined) {
            setLoading(true);
            setAlert(message);
            const timer = setTimeout(() => {
                setLoading(false)
                setAlert(null);
                return () => clearTimeout(timer);
            }, 2000)
        }
    }, [message, flag])

    const accessToken = localStorage.getItem("accessToken");

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const lockopenfn = (val) => {
        setLockOpen(val)
        val === true ? setPassType('text') : setPassType('password')
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(state))
            .unwrap()
            .then((res) => {
                setLoading(false)
                navigate(`${DASHBOARD}`)
            })
            .catch((err) => {
                setState({})
                setLoading(true)
            })
    }

    return (

        <div className="login-page-wrap">
            <div className="login-wrap">
                <div className="img-wrap">
                    <img src={logo} alt="Atlas Logo" />
                </div>
                <div className="login-form-wrap">
                    <div className="title-wrap">

                        <p id="atlas">ATLAS Operations - Log in</p>
                    </div>
                    {alert ? (<Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    <Alert severity="error">{message}</Alert>
                    </Typography>) : <></>}

                    <ValidatorForm onSubmit={handleSubmit} onError={() => null} style={{ width: '100%' }}>
                        <TextField
                            sx={{ input: { color: '#2F2661BF' } }}
                            type="text"
                            id="standard-basic"
                            className='username'
                            // placeholder='Enter Username'
                            label="Enter Username"
                            name="username"
                            InputLabelProps={{
                                style: {
                                    'color': 'rgba(47, 38, 97, 0.75)',
                                    'font-family': 'Poppins',
                                    'font-size': '14px',
                                    'font-style': 'normal',
                                    'font-weight': 500,
                                    'line-height': 'normal',
                                    'width': '100%',
                                    'margin-top': '10px'
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton>
                                        <PersonOutlineOutlinedIcon id="userIcon" />
                                    </IconButton>
                                ),
                            }}
                            value={state.username || ''}
                            autoComplete="off"
                            variant="standard"
                            onChange={handleChange}
                            validators={[
                                'required'
                            ]}
                            errorMessages={['This field is required']}
                        />

                        <TextField
                            // sx={{ input: { color: '#2F2661BF' } }}
                            id="standard-basic"
                            className='password'
                            // placeholder='Enter Password'
                            label="Enter Password"
                            type={passType}
                            name="password"
                            InputLabelProps={{
                                style: {
                                    'color': 'rgba(47, 38, 97, 0.75)',
                                    'font-family': 'Poppins',
                                    'font-size': '14px',
                                    'font-style': 'normal',
                                    'font-weight': 500,
                                    'line-height': 'normal',
                                    'width': '100%',
                                    'margin-top': '10px'
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton>
                                        {
                                            lockOpen ?
                                                <LockOpenOutlinedIcon onClick={() => lockopenfn(false)} />
                                                :
                                                <LockOutlinedIcon onClick={() => lockopenfn(true)} id="lockIcon" />
                                        }
                                    </IconButton>
                                ),
                            }}
                            value={state.password || ''}
                            autoComplete="off"
                            variant="standard"
                            onChange={handleChange}
                            validators={[
                                'required'
                            ]}
                            errorMessages={['This field is required']}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: "#2F2661" }}
                            // disabled={loading}
                            className="login-button"
                        >
                            Log In
                        </Button>

                    </ValidatorForm>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Link href="/forgotpassword" id="forgot">
                            <span>Forgot password? Click Here</span>
                        </Link>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Login
