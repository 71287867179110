import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

// loan disbursed table listing api
export const getLoanDisbursedList = createAsyncThunk(
  "loandisbursedlisting",
  async ({ pageNo, searchTerm,fromDate, toDate }) => {
    const url = `loans/disbursed-list?page=${pageNo}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

//download loan disbursed excel
export const getLoanDisbursedExcel = createAsyncThunk(
  "loandisbursedExcel",
  async ({ pageNo, searchTerm, fromDate, toDate, totalCount }) => {
    const url = `loans/disbursed-list?page=1&limit=${totalCount}&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`
    const res = await DataService.get(url);
    return res.data;
  }
);

// // generate excel sheet
// export const generateExcel = createAsyncThunk(
//   "generate excel sheet",
//   async ({ fromDate, toDate }) => {
//     const url = `excel/disbursed?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data;
//   }
// );

//get repayment details
export const getRepaymentDetails = createAsyncThunk(
  "getrepaymentdetails",
  async (id) => {
    const url = `loans/repayments-by-borrower/${id}`;
    const res = await DataService.get(url);
    return res.data;
  }
)

const loanDisbursedSlice = createSlice({
  name: "loandisbursedlisting",
  initialState: {
    loanDisbursedList: [],
    loanDisbursedExcel: [],
    loanDisbursedExcelSheet: [],
    isLoading: true,
    repayDetails: [],
  },
  extraReducers: {
    [getLoanDisbursedList.pending]: (state) => {
      state.isLoading = true;
    },
    [getLoanDisbursedList.fulfilled]: (state, action) => {
      state.loanDisbursedList = action.payload.data;
      state.totalCount = action.payload.totalItmes;
      state.isLoading = false;
    },
    [getLoanDisbursedList.rejected]: (state) => {
      state.isLoading = false;
    },
    [getLoanDisbursedExcel.fulfilled]: (state, action) => {
      state.loanDisbursedExcel = action.payload.data;
    },
    // [generateExcel.fulfilled]: (state, action) => {
    //   state.loanDisbursedExcelSheet = action.payload.data;
    // },
    [getRepaymentDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getRepaymentDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.repayDetails = action.payload.data;
    },
    [getRepaymentDetails.rejected]: (state, action) => {
      state.isLoading = false;
    }
  },
});

const { reducer } = loanDisbursedSlice;
export default reducer;
