import dayjs from "dayjs";

const disableUpcomingDates = (current)=>{
    return current && current > dayjs().endOf('day');
}
const disableDates = (current)=>{
    return current && current <= dayjs().endOf('day').subtract(1, 'day');
}
const disableSixMonth = (current)=>{
    return current  && (current < dayjs().endOf('day').add(1, 'month')|| current > dayjs().endOf('day').add(6, 'month'));
}

export {
    disableDates,
    disableUpcomingDates,
    disableSixMonth
}