import { Column } from '@ant-design/charts';
import React from 'react'
import { formatRupees } from '../../../../utils/formatRupees';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';


const CreditLineChart = ({ chartData }) => {

    const renderCustomizedLabel = (val) => `${val < 1000 ? '₹ ' + val : formatRupees(val)}`
    const barTopLabel = (val) => `${val}%`
    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        return <span style={{ color: '#242424' }}>{value === 'active_credit_line' ? 'Active Credit Line' : 'Credit Line in use'}</span>;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <div className="tooltip-wrap">
                        <p className="label">{label}</p>
                        <div className="active-credit-wrap">
                            <div className="tooltipcircle"></div>
                            <p className='active-credit'>Active Credit Line : {payload[0]?.value}</p>
                        </div>
                        <div className="creditline-use-wrap">
                            <div className="tooltipcircle"></div>
                            <p className='creditline-use'>Credit Line in use : {payload[1]?.value}</p>
                        </div>
                    </div>
                </>
            );
        }
        else {
            return null;
        }
    };

    return (
        <ResponsiveContainer width={"100%"} height={400}>
            <BarChart data={chartData} margin={{ left: 30, top: 30 }} barGap={0}>
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={renderCustomizedLabel} label={{ value: 'Amount', angle: -90, position: 'insideLeft', offset: -15 }} />
                <Tooltip content={<CustomTooltip />} />
                <Legend formatter={renderColorfulLegendText} />
                <Bar dataKey="active_credit_line" fill="#F69D9D" />
                <Bar dataKey="credit_line_use" fill="#FFC4D0" >
                    <LabelList dataKey="percentage" position="top" formatter={barTopLabel} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default CreditLineChart