import React from 'react'
import { TextField } from '@material-ui/core'
export default function Input(props) {

    const { name, label, type, onChange,error=null,maxLength,value, ...other  } = props
    return (
        <TextField
            variant="outlined"
            name={name}
            label={label}
            onChange={onChange}
            value={value}
            type={type}
            {...other}
            {...(error && { error: true, helperText: error })} />
    )
}
