import React, { useState } from 'react'
import './weeklyloans.css'
import WeeklyLoansDataCard from './WeeklyLoansDataCard'
import WeeklyLoansChart from './WeeklyLoansChart'
import { useDispatch, useSelector } from 'react-redux'
import { getWeeklyLoans, getWeeklyLoansData } from '../../../../slice/DashboardSlice/weeklyLoansSlice'
import { useEffect } from 'react'
import { getcommonapidata } from '../../../../slice/commonslice'
import { Skeleton } from 'antd'
const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const date = new Date()

const WeeklyLoans = () => {
    const [value, setValue] = useState(month[date.getMonth()]);

    const { isLoading, weeklyLoansData } = useSelector(getWeeklyLoansData)
    const { commonFilterStates } = useSelector(getcommonapidata)
    const dispatch = useDispatch();
    const postValue = {
        state: commonFilterStates,
        month: `${value === 'Select Month' ? '' : value}`
    }

    useEffect(() => {
        if (!weeklyLoansData) {
            dispatch(getWeeklyLoans(postValue))
        }
    }, [weeklyLoansData])

    useEffect(() => {
        dispatch(getWeeklyLoans(postValue))
    }, [commonFilterStates, value])

    return (
        <div className="card-wrap displayflexcol weeklyloans-card">
            <h3 className="chart-card-title">Weekly Loans</h3>
            {
                isLoading || !weeklyLoansData ?
                    <Skeleton active />
                    :
                    <WeeklyLoansDataCard cardData={weeklyLoansData?.cardData} value={value} setValue={setValue} />
            }
            {
                isLoading || !weeklyLoansData ?
                    <Skeleton active />
                    :
                    <WeeklyLoansChart chartData={weeklyLoansData?.data} />
            }
        </div>
    )
}

export default React.memo(WeeklyLoans)