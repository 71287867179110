import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./Dashboardstyles.css";
import MonthWise from './MonthWise';
import Today from './Today';
import YearWise from './YearWise';
import { getRegionList } from '../../slice/fos'
import { getRegionData } from '../../slice/dashboard'
import { useForm } from "../Sharedfeatures/useForm";

const RegionGraphs = ({ year }) => {

    const initialValues = {
        region: ''
    }

    const { values, setValues, handleInputChange } = useForm(initialValues, true);

    const { regionList } = useSelector((state) => state.fos)
    console.log("Region", regionList)
    // const [year, setYear] = useState(new Date().getFullYear())
    // const [region, setRegion] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRegionList())
            .then((resp) => {
                let sel_reg_id = resp.payload.data[0]._id
                setValues({ ...values, region: sel_reg_id })
            })
            .then(() => {
                if (values.region && year) {
                    const data = {
                        region: values.region,
                        year
                    }
                    dispatch(getRegionData(data))
                }
            })
    }, [])
    useEffect(() => {
        if (values.region && year) {
            const data = {
                region: values.region,
                year
            }
            dispatch(getRegionData(data))
        }
    }, [dispatch, values.region, year])

    // const handleYearChange = (e) => {
    //     setYear(e.target.value)
    // }

    let curYear = new Date().getFullYear()
    let years = []
    for (let i = curYear; i >= 2022; i--) {
        years.push(i)
    }

    return (
        <div className='region-graphs-card'>

            <div style={{ width: '100%', textAlign: 'center' }} className='g-one-header'>
                <h2>
                    Loan Disbursed List - Region Wise
                </h2>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '33.33%', display: 'flex', justifyContent: 'center' }}>

                    <select id='year' onChange={handleInputChange} name="region" value={values.region}>
                        {
                            regionList && regionList.map((item) => {
                                return <option key={item._id} value={item._id}>{item.name}</option>
                            })
                        }
                    </select>
                    {/* <select id='year' onChange={handleYearChange} value={year} style={{width: '130px'}}>
                        {
                            years.map(item => <option key={item} value={item}>{item}</option>)
                        }
                    </select> */}
                </div>
            </div>


            <Grid container className='grid-gap-two'>
                <Grid item sm={12} md={6} lg={3}>
                    <YearWise />
                </Grid>

                <Grid item sm={12} md={6} lg={6}>
                    <MonthWise />
                </Grid>

                <Grid item sm={12} md={6} lg={3}>
                    <Today />
                </Grid>
            </Grid>

        </div>
    )
}

export default RegionGraphs