import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

// loan sanctioned table listing api
export const getLoanSanctionedList = createAsyncThunk(
  "loansanctionedlisting",
  async ({ pageNo, search, fromDate, toDate }) => {
    const url = `loans/sanctioned-list?page=${pageNo}&limit=10&searchqry=${search}&fromDate=${fromDate}&toDate=${toDate}`;
    const res = await DataService.get(url);
    return res.data;
  }
);


//download loansanctioned excel
export const getLoanSanctionedExcel = createAsyncThunk(
  "loansanctionedExcel",
  async ({ pageNo, search, fromDate, toDate, totalCount }) => {
    const url = search ? `loans/sanctioned-list?page=${pageNo}&limit=10&searchqry=${search}`: `loans/sanctioned-list?page=1&limit=${totalCount}`
    const res = await DataService.get(url);
    return res.data;
  }
);

//tranche details table listing
export const getTrancheDetails = createAsyncThunk(
  "tranchedetails",
  async (id) => {
    const url = `loans/disbursed-tranches/${id}`;
    const res = await DataService.get(url);
    return res.data.data;
  }
);

//loan statement details
export const getLoanStatement = createAsyncThunk(
  "loanstatement",
  async (id) => {
    const url = `loans/single-statement/${id}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

//View of SanctionList
export const getLoanAppStatement = createAsyncThunk(
  "loanAppstatement",
  async (data, thunkAPI) => {
      try {
          const url = `/loans/loan-applicant/${data}`;
          const res = await DataService.get(url);
          return res.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
      }
  }
)

// //generate excel sheet
// export const generateExcel = createAsyncThunk(
//   "generate excel sheet",
//   async ({fromDate, toDate}) => {
//     const url = `excel/sanctioned?fromDate=${fromDate}&toDate=${toDate}`;
//     const res = await DataService.get(url);
//     return res.data;
//   }
// );
export const resetStatus = createAsyncThunk(
  "resetStatus",
  async (state) => {
    return state;
  }
)

export const reset_message = createAsyncThunk(
  "reset_message",
  async () => {
    return;
  }
)

const loanSanctionedSlice = createSlice({
  name: "loansanctionedlisting",
  initialState: {
    loanSanctionedList: [],
    loanSanctionedExcel: [],
    loanSanctionedView:[],
    trancheDetails: [],
    loanStatement: [],
    loanSanctionedExcelSheet: [],
    isLoading: true,
    modalStatus: false,
  },
  extraReducers: {
    [getLoanSanctionedList.pending]: (state) => {
      state.isLoading = true
    },
    [getLoanSanctionedList.fulfilled]: (state, action) => {
      state.loanSanctionedList = action.payload.data;
      state.totalCount = action.payload.totalItmes;
      state.isLoading = false
    },
    [getLoanSanctionedList.rejected]: (state) => {
      state.isLoading = false
    },
    [getLoanSanctionedExcel.fulfilled]: (state, action) => {
      state.loanSanctionedExcel = action.payload.data;
    },
    [getTrancheDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTrancheDetails.fulfilled]: (state, action) => {
      state.trancheDetails = action.payload;
      state.isLoading = false;
    },
    [getLoanStatement.fulfilled]: (state, action) => {
      state.loanStatement = action.payload.data;
    },
    [getLoanAppStatement.fulfilled]: (state, action) => {
      // console.log("SanctionACT", action)
      state.loanSanctionedView = action.payload.data;
    },
    // [generateExcel.fulfilled]: (state, action) => {
    //   state.loanSanctionedExcelSheet = action.payload.data;
    // },
    reducers: {
      resetStateValues: (state) => {
          state.loanSanctionedView = {};
      },
  }
  },
});

export const { resetStateValues } = loanSanctionedSlice.actions
export default loanSanctionedSlice.reducer
