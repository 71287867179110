import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { ApplicationContext } from './LoanSanctioned'

const Personal = () => {

  const loanSanctionedView = useContext(ApplicationContext)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Name:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView.name}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Email:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView.email}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Mobile:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView.mobile}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Date of Birth:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView.dateOfBirth}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Address:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {loanSanctionedView.address}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Personal