import React, { useContext } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { ApplicationContext } from './LoanSanctioned'
import ModalImage from "react-modal-image";
import click_here from './click_here.svg'
import useStyles from '../LoanApplications/LoanAppStyles';

const Property = () => {

    const loanSanctionedView = useContext(ApplicationContext)
    const classes = useStyles()
    const BASE_URL = process.env.REACT_APP_API_BASE_URL

    return (
        <>
            <Paper
                variant="outlined"
                className={classes.documentsTabsHead}
            >
                <Grid container spacing={1}>
                    {
                        loanSanctionedView && loanSanctionedView.documets['4']?.map((item) => (
                            <>
                                <Grid item md={2}>
                                    <ModalImage
                                        smallSrcSet={click_here}
                                        hideDownload={true}
                                        hideZoom={true}
                                        large={item.fileName}
                                        alt="Uploaded doc"
                                    />
                                </Grid>
                                <Grid item md={3} />
                                <Grid item md={6} container justifyContent={"center"} alignItems={"center"}>
                                    {/* <Typography variant="subtitle1" fontWeight="bold">
                                        {item.status}
                                    </Typography> */}
                                </Grid>
                            </>
                        ))
                    }
                </Grid>
            </Paper>
        </>
    )
}

export default Property