import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { useSelector } from 'react-redux';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';

const Today = () => {
    const { todayData } = useSelector((state) => state.dashboard)
    const { dataFormater } = useYAxisFormatter();
    const { count, volume } = todayData

    let data = [];
    let cdata = {
        name: "Today",
        count,
        volume: Number(volume),
    };
    data.push(cdata);

    return (
        <div className='loan-disbursed-region-wise'>
            <ResponsiveContainer width="95%" height="95%">
                <BarChart
                    width={500}
                    height={500}
                    data={data}
                    margin={{
                        top: 15,
                        right: 30,
                        left: 20,
                        bottom: -8,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis axisLine={false} tickFormatter={dataFormater} />
                    <Tooltip cursor={false}
                    />
                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                    <Bar dataKey="count" fill="#7C4DFF" />
                    <Bar dataKey="volume" fill="#7C4DFF" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Today