import React, { useState, useEffect } from "react";
import { Paper, TableBody, TableRow, TableCell, Button, TextField, IconButton } from "@material-ui/core";
import useTable from "../Sharedfeatures/useTable";
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import { getLoanDisbursedList, getLoanDisbursedExcel, generateExcel } from "../../slice/loandisbursed";
import TrancheModal from "../Common/TrancheModal";
import { PAGE_SIZE } from "../../utils/variable";
import { LOAN_DISP_HEADER, LOAN_DISP_SUBHEADER } from "../../utils/pageHeadings";
import DownloadIcon from '@mui/icons-material/Download';
import RepaymentModal from "../Common/RepaymentModal";
import { DatePicker } from 'antd';
import moment from 'moment';


const LoanDisbursed = () => {

  //For getting today and yesterday date
  let today = new Date();
  let yesterday = new Date();
  let today_dd = String(today.getDate()).padStart(2, '0');
  let yest_dd = String(today.getDate() - 1).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + today_dd;
  yesterday = yyyy + '-' + mm + '-' + yest_dd;

  const classes = makemyStyles();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [loanAppId, setLoanAppId] = useState();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState('')
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const { RangePicker } = DatePicker;
  const [excelSheetData, setExcelSheetData] = useState([]);

  const { loanDisbursedList, loanDisbursedExcel, totalCount, isLoading } = useSelector((state) => state.loanDisbursed);

  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(loanDisbursedList, HeadCells.loanDisbursedHead, totalCount);

  const onBtnClick = (loanapproval_id) => {
    setLoanAppId(loanapproval_id);
    setOpen(!open);
  };

  const onDateChange = (dates, dateStrings) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  }

  // useEffect(() => {
  //   const dates = {
  //     fromDate,
  //     toDate
  //   }
  //   dispatch(generateExcel(dates))
  // }, [fromDate, toDate])

  const excelData = useExcelDownload()

  useEffect(() => {
    const data = {
      pageNo: pageNo,
      searchTerm: search,
      fromDate,
      toDate,
      totalCount
    }
    dispatch(getLoanDisbursedList(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

  let serialNo = PAGE_SIZE * slNo;

  //Repayment Modal functions
  const [openRep, setOpenRep] = useState(false)
  const [clientId, setClientId] = useState()

  const repOpen = (id) => {
    setClientId(id)
    setOpenRep(true)
  }

  const repClose = () => {
    setOpenRep(false)
  }

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  const downloadExcelsheet = () => {
    const data = {
      pageNo: pageNo,
      searchTerm: search,
      fromDate,
      toDate,
      totalCount
    }
    dispatch(getLoanDisbursedExcel(data))
      .then(res => {
        if (res.payload.success === true) {
          let excelsheetdata = []
          res.payload.data?.map(item => {
            excelsheetdata.push({
              "RT/DT Code": item.customerid,
              "Loan A/C No": item.loanaccountNumber,
              "Name": item.name,
              "Loan Product": item.loanName,
              "Amount": item.amount_sanctioned,
              "Interest Rate": item.interest_rate,
              "Tenure": item.tenure,
              "Disbursal Date": moment(item.disbursedOn).format('DD MMMM YYYY'),
              "Sanctioned Date": moment(item.sanctionedOn).format('DD MMMM YYYY'),
              "Status": item.status
            })
          })
          excelData.generateExcelFile(excelsheetdata, 'LoanDisbursedSheet', 'LoanDisbursedList.xlsx')
        }
      })
      .catch(err => {
        console.error(err);
      })
  }

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={LOAN_DISP_HEADER}
          subHeader={LOAN_DISP_SUBHEADER}
        />
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
          <TextField
            size="small"
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            id="standard-bare"
            variant="outlined"
            placeholder="Search"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
            <RangePicker
              placeholder={["From Date", "To Date"]}
              format={dateFormat}
              className={classes.datePicker}
              onChange={onDateChange}
            />

            <Button
              className={classes.excelUploadButton}
              variant="outlined"
              color="primary"
              onClick={downloadExcelsheet}
              endIcon={<DownloadIcon />}
            >
              Download Ledger
            </Button>
          </div>
        </div>

        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align='center' className={classes.tableCell}>
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {
                  (loanDisbursedList.length !== 0) ? (
                    loanDisbursedList &&
                    loanDisbursedList.map((item, key) => {
                      key++;
                      serialNo++;
                      return (
                        <TableRow key={key}>
                          <TableCell className={classes.tableCell}>
                            {serialNo}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.customerid}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loanaccountNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loanName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.amount_sanctioned}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.tenure}
                          </TableCell>
                          <TableCell className={classes.tableCell} align='center'>
                            {item.interest_rate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button onClick={() => onBtnClick(item.loanapproval_id)} color="primary" variant="outlined">Show</Button>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ paddingLeft: '25px' }}>
                            <Button onClick={() => repOpen(item.clientId)} color="primary" variant="outlined">Show</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align='center'>No Data to Show</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {open ? (
          <TrancheModal open={open} onClose={onBtnClick} id={loanAppId} />
        ) : null}
        {
          openRep ? <RepaymentModal open={openRep} onClose={repClose} id={clientId} /> : null
        }
        {showPageNtn ? <TblPagination /> : ""}
      </Paper >
    </>
  );
};

export default LoanDisbursed;
