import React from 'react';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core'
import useSharedClasses from '../Sharedfeatures/SharedClasses';

const Selectregion = (props) => {

    const { name, label, onChange, options, error = null, textFieldProps, value, ...other } = props
    const sharedClasses = useSharedClasses();

    return (
        // <Box sx={{ minWidth: 120 }}>
        <FormControl variant='outlined'
            {...(error && { error: true })}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <MuiSelect
                MenuProps={{
                    style: {
                       maxHeight: 400,
                          },
                    }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size='small'
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                option={options}
            >
                {/* <MenuItem value="" sx={{ height: '150px', marginTop: '100px' }}>Select {label}</MenuItem> */}
                {
                    options && options.map((item) =>
                        (<MenuItem value={item._id}>{item.name}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        // </Box>
    );
};

export default Selectregion;
