import React, { useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, Modal, Paper, TextField } from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from '../Common/PageHeader';
import { MANUAL_REPAYMENT_HEADER, MANUAL_REPAYMENT_SUB_HEADER } from '../../utils/pageHeadings';
import { useForm } from '../Sharedfeatures/useForm';
import { LoadingButton } from '@mui/lab';
import Controls from '../Controls/Controls';
import { disableUpcomingDates } from '../../utils/disableDates';
import { Alert, DatePicker } from 'antd';
import moment from "moment";
import CloseIcon from '../UpcomingRepayments/CloseIcon';
import { manualrepayment } from '../../slice/manualRepaymentSlice';
import { useDispatch } from 'react-redux';
const style = {
    position: "absolute",
    top: "50%",
    left: "57%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    maxHeight: "70%",
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    padding:'22px'
};
const ManualRepayment = () => {
    //const dateFormat = 'DD-MM-YYYY';

    const [loading,setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [responceData, setResponceData] = useState();
    const dispatch = useDispatch();

    const classes = makemyStyles();
    const initialFValues = {
        userid:'',
        trancheId: '',
        amount: '',
        //repaymentDate:'',
        bankRefNumber: ''
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("userid" in fieldValues) temp.userid = fieldValues.userid ? "" : "This field is required.";
        if ("trancheId" in fieldValues) temp.trancheId = fieldValues.trancheId ? "" : "This field is required.";
        if ("amount" in fieldValues) temp.amount = fieldValues.amount ? "" : "This field is required.";
        //if ("repaymentDate" in fieldValues) temp.repaymentDate = fieldValues.repaymentDate ? "" : "This field is required.";
        if ("bankRefNumber" in fieldValues) temp.bankRefNumber = fieldValues.bankRefNumber ? "" : "This field is required.";
        setErrors({ ...temp });
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    }
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        //handleDateChange
        resetForm
    } = useForm(initialFValues, true, validate)

    const reverseAmountSubmit = (e)=>{
        e.preventDefault();
        if (validate()) {
            setOpenModal(true)
        }
    }
    const confirmSubmit = ()=>{
        setLoading(true)
        dispatch(manualrepayment({...values}))
        .then(res=>{
            setResponceData(res?.payload)
            if(res?.payload !== undefined && res?.payload?.success === true){
                setOpenModal(false)
                resetForm()
            }
            setLoading(false)
        })
        .catch(err=>{
            console.error(err);
            setLoading(false)
        })
    }
    return (
        <>
            <Paper className={classes.pageContent} >
                <PageHeader
                header={MANUAL_REPAYMENT_HEADER}
                subHeader={MANUAL_REPAYMENT_SUB_HEADER}
                />
                <form onSubmit={(e)=>reverseAmountSubmit(e)} autoComplete="off" className={classes.manualrepaymentform}>
                    {
                        responceData?.success === true &&
                            <Alert message={responceData?.message} type="success" showIcon closable style={{width:'100%'}} onClose={() => setResponceData('')}/>
                    }
                    {
                        responceData?.success === false &&
                            <Alert message={responceData?.message} type="error" showIcon closable style={{width:'100%'}} onClose={() => setResponceData('')}/>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                label="SD/DT/RT Code"
                                name="userid"
                                value={values.userid}
                                onChange={handleInputChange}
                                variant="outlined"
                                style={{ width: '100%' }}
                                {...(errors.userid && { error: true, helperText: errors.userid })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Tranche ID"
                                name="trancheId"
                                value={values.trancheId}
                                onChange={handleInputChange}
                                variant="outlined"
                                style={{ width: '100%' }}
                                {...(errors.trancheId && { error: true, helperText: errors.trancheId })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Amount"
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                                variant="outlined"
                                style={{ width: '100%' }}
                                {...(errors.amount && { error: true, helperText: errors.amount })}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <DatePicker
                                name='repaymentDate'
                                style={{width:'100%', height:'56px', borderRadius:'4px'}}
                                placeholder={'Select Date'}
                                format={dateFormat}
                                className={classes.datePicker}
                                onChange={(dates,dateStrings) => handleDateChange('repaymentDate',dates, dateStrings)}
                                disabledDate={disableUpcomingDates}
                                status = {errors.repaymentDate ? 'error' : ''}
                                //value={repaymentDateValue}
                            />
                            {
                                errors.repaymentDate && <FormHelperText style={{color:'#f44336'}}>{errors.repaymentDate}</FormHelperText>
                            }
                        </Grid> */}
                        <Grid item xs={6}>
                            <TextField
                                label="Bank Ref No"
                                name="bankRefNumber"
                                value={values.bankRefNumber}
                                onChange={handleInputChange}
                                variant="outlined"
                                style={{ width: '100%' }}
                                {...(errors.bankRefNumber && { error: true, helperText: errors.bankRefNumber })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                style={{ height:'56px', width:'50%'}}
                                loadingPosition="center"
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Modal open={openModal} onClose={()=>setOpenModal(false)}>
                <Box sx={style}>
                    <div className={classes.repayModalWrap}>
                        <div className={classes.repayModalTitle}>
                            <span>Manual Repayment</span>
                            <div className={classes.closeIconStyle} onClick={()=>setOpenModal(false)}>
                                <CloseIcon/>                               
                            </div>
                        </div>
                        <div className={classes.manualrepaymentformcontent}>
                            <p>Tranche ID : {values?.trancheId}</p>
                            <p>Amount : {values?.amount}</p>
                            <p>Are you sure your want to continue?</p>
                            <div className={classes.manualRepaymentFormactionBtnsWrap}>
                                <button type='button' onClick={()=>setOpenModal(false)} className={classes.reveseBack} disabled={loading}>NO</button>
                                <LoadingButton
                                    className={classes.reveseYes}
                                    loading={loading}
                                    loadingPosition="center"
                                    type="button"
                                    variant="contained"
                                    onClick={confirmSubmit}
                                >
                                    {
                                        !loading ? 'YES' : ''
                                    }
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ManualRepayment