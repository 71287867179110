import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getActiveCreditLine = createAsyncThunk(
    'getActiveCreditLine',
    async (value) => {
        try {
            const url = `/console-web/credit-line`
            const response = await DataService.post(url, value)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    activeCreditLineData: '',
}

const activeCreditLine = createSlice({
    name: 'activecreditline',
    initialState,
    extraReducers: {
        [getActiveCreditLine.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getActiveCreditLine.fulfilled]: (state, { payload }) => {
            return { ...state, activeCreditLineData: payload?.data, isLoading: false }
        },
    }
})

export const getActiveCreditLineData = (state) => state.activecreditlineapi;
export default activeCreditLine.reducer