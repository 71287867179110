import React, { useState, useEffect } from 'react'
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import { Paper, TableBody, TableRow, TableCell, TextField, IconButton, Button } from "@material-ui/core";
import { LOAN_CLOSED_HEADER, LOAN_CLOSED_SUBHEADER } from '../../utils/pageHeadings';
import { BsSearch } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import { useSelector, useDispatch } from 'react-redux';
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { getLoanClosedList, getLoanClosedExcel, generateExcel } from '../../slice/loanclosed';
import moment from 'moment';
import { DatePicker } from 'antd';
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import DownloadIcon from '@mui/icons-material/Download';

const LoansClosed = () => {

  //For getting today and yesterday date
  let today = new Date();
  let yesterday = new Date();
  let today_dd = String(today.getDate()).padStart(2, '0');
  let yest_dd = String(today.getDate() - 1).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + today_dd;
  yesterday = yyyy + '-' + mm + '-' + yest_dd;

  const classes = makemyStyles();
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const { RangePicker } = DatePicker;
  const excelData = useExcelDownload()
  const [excelSheetData, setExcelSheetData] = useState([]);

  const { loanClosedList, loanClosedExcel, totalCount, isLoading, loanClosedExcelSheet } = useSelector((store) => store.loanClosed);

  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(loanClosedList, HeadCells.loanClosedHead, totalCount);

  const onDateChange = (dates, dateStrings) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  }

  // useEffect(() => {
  //   const dates = {
  //     fromDate,
  //     toDate
  //   }
  //   dispatch(generateExcel(dates))
  // }, [fromDate, toDate])

  useEffect(() => {
    const data = {
      pageNo: pageNo,
      search: search,
      fromDate,
      toDate,
      totalCount,
    };
    dispatch(getLoanClosedList(data));
    dispatch(getLoanClosedExcel(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

  let serialNo = PAGE_SIZE * slNo;

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    loanClosedExcel?.map(item => {
      excelsheetdata.push({
        "RT/DT Code": item.customerid,
        "Loan A/C No": item.loanaccountNumber,
        "Name": item.name,
        "Loan Name": item.loanName,
        "Interest Rate": item.interest_rate,
        "Tenure": item.tenure,
        "Closed Date": moment(item.closed_on).format('DD MMMM YYYY'),
        "Status": item.status
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [loanClosedExcel])

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={LOAN_CLOSED_HEADER}
          subHeader={LOAN_CLOSED_SUBHEADER}
        />
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
          <TextField
            size="small"
            fullWidth
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            variant="outlined"
            placeholder="Search Name or RT/DT Code"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
            <RangePicker
              placeholder={["From Date", "To Date"]}
              format={dateFormat}
              className={classes.datePicker}
              onChange={onDateChange}
            />
            <Button className={classes.excelUploadButton} onClick={() => excelData.generateExcelFile(excelSheetData, 'LoanClosedSheet', 'LoanClosed.xlsx')} endIcon={<DownloadIcon />}>Download Ledger</Button>
          </div>
        </div>

        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {
              isLoading ? (<TableBody><TableRow><TableCell colSpan={9} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
              ) : (
                <TableBody>
                  {
                    (loanClosedList.length !== 0) ? (
                      loanClosedList && loanClosedList
                        .map((item, key) => {
                          key++;
                          serialNo++;
                          return (<TableRow key={key}>
                            <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                            <TableCell className={classes.tableCell}>{item.customerid}</TableCell>
                            <TableCell className={classes.tableCell}>{item.loanaccountNumber}</TableCell>
                            <TableCell className={classes.tableCell}>{item.name}</TableCell>
                            <TableCell className={classes.tableCell}>{item.loanName}</TableCell>
                            <TableCell className={classes.tableCell}>{item.interest_rate}</TableCell>
                            <TableCell className={classes.tableCell}>{item.tenure}</TableCell>
                            <TableCell className={classes.tableCell}>{moment(item.closed_on).format('DD MMMM YY')}</TableCell>
                            <TableCell className={classes.tableCell}>{item.status}</TableCell>
                          </TableRow>)
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align='center'>No Data to Show</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              )
            }
          </TblContainer>
        </Paper>
        {
          showPageNtn ? <TblPagination /> : ''
        }
      </Paper>
    </>
  )
}

export default LoansClosed