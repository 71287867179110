import React,{ Suspense }from 'react'
import { lazy } from 'react';
import { Skeleton } from 'antd';
const RevenueCards = lazy(()=> import('./RevenueCards/RevenueCards'))
const InterestSection = lazy(()=> import('./InterestSection/InterestSection'))
const FeesSection = lazy(()=> import('./FeesSection/FeesSection'))


const TotalRevenue = () => {
    return (
        <div className="section-wrap displayflexcol total-revenue-section">
            <h2 className="section-title">Total Revenue</h2>
            <div className="card-wrap">
                <Suspense fallback={<Skeleton active/>}>
                    <RevenueCards/>
                </Suspense>
            </div>
            <div className="card-wrap interest-card">
                <Suspense fallback={<Skeleton active/>}>
                    <InterestSection/>
                </Suspense>
            </div>
            <div className="card-wrap fees-card">
                <Suspense fallback={<Skeleton active/>}>
                    <FeesSection/>
                </Suspense>
            </div>
        </div>
    )
}

export default TotalRevenue