import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableCell, Paper, TextField, IconButton, Button } from "@material-ui/core";
import useTable from "../Sharedfeatures/useTable";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import HeadCells from "../Common/HeadCells";
import { getAlgorithmRejected, getAlgorithmRejectedExcel } from "../../slice/preapprovedloanoffer";
import { PAGE_SIZE } from '../../utils/variable'
import { BsSearch } from "react-icons/bs";
import ProgressSpinner from "../Common/ProgressSpinner";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker } from 'antd';


const AlgorithmRejectedTab = () => {
  const { algRejected, algRejExcelData, totalCount, isLoading } = useSelector((state) => state.preApprovedLoanOffer);
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState('')
  const classes = makemyStyles();
  const moment = require('moment');
  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(algRejected, HeadCells.algRejected, totalCount);
  const dispatch = useDispatch();
  const excelData = useExcelDownload();
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const { RangePicker } = DatePicker;

  const onDateChange = (dates, dateStrings) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  }

  useEffect(() => {
    const data = {
      searchTerm: search,
      pageNo: pageNo,
      totalCount,
      fromDate,
      toDate,
    }
    dispatch(getAlgorithmRejected(data));
    dispatch(getAlgorithmRejectedExcel(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

  let serialNo = PAGE_SIZE * slNo

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    algRejExcelData?.map(item => {
      excelsheetdata.push({
        "RT/DT Code": item.userId,
        "Name": item.name,
        "Created On": moment(item.createdAt).format('DD MMMM YYYY'),
        "Updated On": moment(item.updatedAt).format('DD MMMM YYYY'),
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [algRejExcelData])

  return (
    <div style={{ display: 'flex', gap: '15px', justifyContent: 'space-between', flexDirection: 'column' }} >
      <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
        <TextField
          size="small"
          className={classes.preAppSearchTextBar}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          id="standard-bare"
          variant="outlined"
          placeholder="Search Name"
          InputLabelProps={{ shrink: false }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <BsSearch className={classes.searchIconColour} />
              </IconButton>
            ),
          }}
        />
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
          <RangePicker
            placeholder={["From Date", "To Date"]}
            format={dateFormat}
            className={classes.datePickerPre}
            onChange={onDateChange}
          />
          <Button
            className={classes.preApprovedExcelUploadButton}
            onClick={() =>
              excelData.generateExcelFile(
                excelSheetData,
                "AlgRejectedSheet",
                "AlgRejected.xlsx"
              )
            }
            endIcon={<DownloadIcon />}
          >
            Download Report
          </Button>
        </div>
      </div>

      <Paper className={classes.tableBoxShadow}>
        <TblContainer>
          <TblHead />
          {
            isLoading ? (
              <TableBody ><TableRow><TableCell colSpan={4} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {
                  (algRejected.length !== 0) ? (
                    algRejected &&
                    algRejected.map((item, key) => {
                      serialNo++;
                      return (
                        <TableRow key={key.toString()}>
                          <TableCell>{serialNo}</TableCell>
                          <TableCell>{item.userId}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          {/* <TableCell>{item.mobile}</TableCell> */}
                          <TableCell>{moment(item.createdAt).format("DD MMMM YYYY")}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align='center'>No Data to Show</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            )
          }

        </TblContainer>
      </Paper>
      {
        showPageNtn ? <TblPagination /> : ""
      }
    </div>
  );
};

export default AlgorithmRejectedTab;
