import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { REPAYMENTS_HEADER } from '../../utils/graphHeadings'
import './Dashboardstyles.css'
import GraphHeader from './GraphHeader'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'
import { getRepaymentsData } from '../../slice/dashboard'
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import { Card } from '@material-ui/core';

const RepaymentsChart = ({ year }) => {

    const { repayments, repayments_totalVolume } = useSelector((store) => store.dashboard)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRepaymentsData(year));
    }, [year, dispatch])

    let data = [];
    repayments.map((obj) => {
        let cdata = {
            name: obj.month.substring(0, 3),
            value: Number(obj.amount),
        };
        data.push(cdata);
    });

    const { dataFormater } = useYAxisFormatter()

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div>
                    <Card style={{ padding: '7px 12px' }}>
                        <p>{`${label} : ₹ ${payload[0].value}`}</p>
                    </Card>
                </div>
            );
        }

        return null;
    }

    return (
        <div className='second-row-card'>
            <GraphHeader header={REPAYMENTS_HEADER} subheader={Number(Number(repayments_totalVolume).toFixed(2)).toLocaleString()} />
            <ResponsiveContainer width="90%" height="65%">
                <LineChart width={680} height={180} data={data}>
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis axisLine={false} tickFormatter={dataFormater} />
                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Line type="monotone" dataKey="value" stroke="#7C4DFF" activeDot={{ r: 8 }} strokeWidth={3} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default RepaymentsChart