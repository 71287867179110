import React, {useEffect} from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import './Dashboardstyles.css'
import {getDisbursedData} from "../../slice/dashboard"
import GraphHeader from './GraphHeader';
import { DISBURSED_HEADER } from '../../utils/graphHeadings';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@material-ui/core';

const DisbursedChart = ({year}) => {
  const dispatch = useDispatch();
  useEffect (() => {
    dispatch(getDisbursedData(year));
  },[year, dispatch])
    const { dataFormater } = useYAxisFormatter()
    const { loanDisbursed, loanDisbursed_totalVolume } = useSelector((store) => store.dashboard)
    const total_Volume =  loanDisbursed_totalVolume;
 
    let data = [];
    loanDisbursed.map((item) => {
        let cdata = {
            name: item.month.substring(0, 3),
            value: Number(item.amount),
        };
        data.push(cdata);
    });

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div>
                    <Card style={{ padding: '7px 12px' }}>
                        <p>{`${label} : ₹ ${payload[0].value}`}</p>
                    </Card>
                </div>
            );
        }

        return null;
    };

    return (
        <div className='second-row-card'>

            <GraphHeader header={DISBURSED_HEADER} subheader={Number(Number(total_Volume).toFixed(2)).toLocaleString()} />

            <ResponsiveContainer width="90%" height="65%">
                <BarChart
                    width={550}
                    height={180}
                    data={data}
                    // margin={{
                    //     left: 20,
                    //     top: 0,
                    // }}
                >
                    <XAxis dataKey="name" axisLine={false} tick={{ fontSize: 12 }}/>
                    <YAxis axisLine={false} tickFormatter={dataFormater} />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                    <Bar dataKey="value" fill="#00A4D8" radius={2} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default DisbursedChart