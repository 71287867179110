import { Select } from 'antd'
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallmonth, getcommonapidata } from '../../../slice/commonslice';
import { useEffect } from 'react';

const MonthSelectBox = ({value,setValue}) => {
    const [options, setOptions] = useState([]);
    const {allMonth} = useSelector(getcommonapidata)
    const dispatch = useDispatch();

    const selectProps = {
        value,
        options,
        onChange: (newValue) => {
          setValue(newValue);

        },
        placeholder : 'Select Month'
    };
    useEffect(()=>{
        if(!allMonth){
            dispatch(getallmonth())
        }else{
            const newoptions = [];
            allMonth.map((item)=>{
              return newoptions.push({
                label:item.monthName,
                value:item.monthValue
              })
            })
            setOptions(newoptions)
        }
    },[allMonth])
    return (
        <div className="filter-select">
            <Select {...selectProps} showArrow className='select-month'/>
        </div>
    )
}

export default React.memo(MonthSelectBox)