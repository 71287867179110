import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    headerParent: {
        backgroundColor: '#01295F',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '15px 15px 0 0'
    },
    header: {
        color: '#fff',
        fontWeight: 'bold'
    },
    singleNotifParent: {
        width: '100%',
        padding: '10px 30px 10px 20px',
        minHeight: '70px',
        backgroundColor: '#E6E6E6',
        position: 'relative'
    },
    notifBodyParent: {
        height: 'calc(450px - 85px)',
        overflowY: 'auto'
    },
    newNotifCircle: {
        backgroundColor: '#01295F',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        position: 'absolute',
        right: '10px',
        top: '50%'
    },
    readNotificationButton: {
        borderRadius: '0 0 15px 15px',
        backgroundColor: '#01295F',
        color: '#fff'
    },
    notifFooter: {
        borderRadius: '0 0 15px 15px',
        backgroundColor: "#01295F",
        height: '25px'
    }
}))



export default useStyles