import React from 'react'
import Box from '@mui/material/Box';
import { MenuItem, FormHelperText } from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useSharedClasses from '../Sharedfeatures/SharedClasses';

const Selectcity = (props) => {
    const { name, label, onChange, options, error = null, value, ...other } = props
    const sharedClasses = useSharedClasses();

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth
                {...(error && { error: true })}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    MenuProps={{
                        style: {
                            maxHeight: 400,
                        },
                    }}
                    id="demo-simple-select"
                    name={name}
                    value={value}
                    label={label}
                    onChange={onChange}
                    option={options}
                    error={error}
                >

                    {
                        options && options.map((item) =>
                            (<MenuItem value={item}>{item}</MenuItem>)
                        )}
                </Select>
                {error && <FormHelperText className={sharedClasses.errorText}>{error}</FormHelperText>}
            </FormControl>
        </Box>
    )
}

export default Selectcity