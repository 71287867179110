import React, { useState } from 'react'
import './Dashboardstyles.css'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';

const DateTimeBar = () => {
    const [time, setTime] = useState()

    const getTime = () => {
        const currTime = new Date().toLocaleTimeString();
        setTime(currTime)
    }
    setInterval(getTime, 1000);

    let today = new Date()
    let currDate = today.getDate() + '/' + parseInt(today.getMonth() + 1) + '/' + today.getFullYear()

    return (
        <div className='time-zone'>
            <div className='date'>
                <h3 className='dticons'><AiOutlineCalendar /></h3>
                <h3 className='dttext'>&nbsp; &nbsp;{currDate}</h3>
            </div>
            <div className='time'>
                <h3 className='dticons'><AiOutlineClockCircle /></h3>
                <h3 className='dttext'>&nbsp; &nbsp;{time}</h3>
            </div>
        </div>
    )
}

export default DateTimeBar