import {
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Container,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useParams } from "react-router-dom";
import PageHeader from "../Common/PageHeader";
import PreapprovedTab from "./PreapprovedTab";
import AlgorithmRejectedTab from "./AlgorithmRejectedTab";
import { PRE_APP_HEADER, PRE_APP_SUBHEADER } from "../../utils/pageHeadings";

const Preapproved = () => {
  const dispatch = useDispatch();
  const classes = makemyStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showPageNtn, setShowPageNtn] = useState(false);
  const { analytics } = useSelector(
    (state) => state.preApprovedLoanOffer
  )
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <>
      <Paper className={classes.pageContent}>
        <div style={{display:'flex', gap:'20px', alignItems:'flex-start', justifyContent:'space-between'}} >
          <PageHeader header={PRE_APP_HEADER} subHeader={PRE_APP_SUBHEADER} />

          <div className={classes.headContainer}>
            <Container className={classes.innerHeadContainer}>
              <h2 className={classes.containerHeader}>Total Loan Offered:</h2>
              <p className={classes.containerBody}>{analytics.total_loan_offered}</p>
            </Container>
            <Container
              className={classes.innerHeadContainer}
              style={{ border: "2px solid #2d105e", background: "#ffffff" }}
            >
              <h2 className={classes.containerHeader} style={{ color: "#2d105e" }}>
                Total Amount Offered:
              </h2>
              <p className={classes.containerBody} style={{ color: "#2d105e" }}>
                {analytics.total_amount_offered}
              </p>
            </Container>
          </div>
        </div>
        {/* <div style={{ height: "50px" }}></div> */}

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Pre Approved Loan Offers"
                  value="1"
                  sx={{padding : '12px 16px !important'}}
                />
                <Tab
                  label="Algorithm Rejected Offers"
                  value="2"
                  sx={{padding : '12px 16px !important'}}
                />
              </TabList>
            </Box>

            <TabPanel value="1" style={{padding:'25px 0'}}>
              <PreapprovedTab />
            </TabPanel>

            <TabPanel value="2" style={{padding:'25px 0'}}>
              <AlgorithmRejectedTab />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
};

export default Preapproved;
