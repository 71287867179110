import dashboardActiveIcon from '../assets/images/menu-icons/dashboard-active.svg'
import dashboardInactiveIcon from '../assets/images/menu-icons/dashboard-inactive.svg'
import loanProductsActiveIcon from '../assets/images/menu-icons/loan-products-active.svg'
import loanProductsInactiveIcon from '../assets/images/menu-icons/loan-products-inactive.svg'

import preApprovedLoanOffersActiveIcon from '../assets/images/menu-icons/pre-approved-loan-offers-active.svg'
import preApprovedLoanOffersInactiveIcon from '../assets/images/menu-icons/pre-approved-loan-offers-inactive.svg'
import loanApplicationsActiveIcon from '../assets/images/menu-icons/loan-applications-active.svg'
import loanApplicationsInactiveIcon from '../assets/images/menu-icons/loan-applications-inactive.svg'

import loansGeneratedActiveIcon from '../assets/images/menu-icons/loans-generated-active.svg'
import loansGeneratedInactiveIcon from '../assets/images/menu-icons/loans-generated-inactive.svg'

import loansSanctionedActiveIcon from '../assets/images/menu-icons/loans-sanctioned-active.svg'
import loansSanctionedInactiveIcon from '../assets/images/menu-icons/loans-sanctioned-inactive.svg'

import loansDisbursedActiveIcon from '../assets/images/menu-icons/loans-disbursed-active.svg'
import loansDisbursedInactiveIcon from '../assets/images/menu-icons/loans-disbursed-inactive.svg'

import loanDefaultedActiveIcon from '../assets/images/menu-icons/loan-defaulted-active.svg'
import loanDefaultedInactiveIcon from '../assets/images/menu-icons/loan-defaulted-inactive.svg'

import loanClosedActiveIcon from '../assets/images/menu-icons/loan-closed-active.svg'
import loanClosedInactiveIcon from '../assets/images/menu-icons/loan-closed-inactive.svg'

import latestRepaymentsActiveIcon from '../assets/images/menu-icons/latest-repayments-active.svg'
import latestRepaymentsInactiveIcon from '../assets/images/menu-icons/latest-repayments-inactive.svg'

import upcomingRepaymentsActiveIcon from '../assets/images/menu-icons/upcoming-repayments-active.svg'
import upcomingRepaymentsInactiveIcon from '../assets/images/menu-icons/upcoming-repayments-inactive.svg'
import inProgressRepaymentsActiveIcon from '../assets/images/menu-icons/in-progress-repayments-active.svg'
import inProgressRepaymentsInactiveIcon from '../assets/images/menu-icons/in-progress-repayments-inactive.svg'

import tranchesCreatedActiveIcon from '../assets/images/menu-icons/tranches-created-active.svg'
import tranchesCreatedInactiveIcon from '../assets/images/menu-icons/tranches-created-inactive.svg'

import regionsActiveIcon from '../assets/images/menu-icons/regions-active.svg'
import regionsInactiveIcon from '../assets/images/menu-icons/regions-inactive.svg'
import helpDeskActiveIcon from '../assets/images/menu-icons/help-desk-active.svg'
import helpDeskInactiveIcon from '../assets/images/menu-icons/help-desk-inactive.svg'
import expiryActiveIcon from '../assets/images/menu-icons/expired-active.svg'
import expiryInactiveIcon from '../assets/images/menu-icons/expired-inactive.svg'
import creditLineInuseActiveIcon from '../assets/images/menu-icons/creditlin-inuse-active.svg'
import creditLineInuseInactiveIcon from '../assets/images/menu-icons/creditlin-inuse-inactive.svg'


import logoutActiveIcon from '../assets/images/menu-icons/logout-active.svg'
import logoutInactiveIcon from '../assets/images/menu-icons/logout-inactive.svg'
import fosActiveIcon from '../assets/images/menu-icons/fos-active.svg'
import fosInactiveIcon from '../assets/images/menu-icons/fos-inactive.svg'
import customersActiveIcon from '../assets/images/menu-icons/customers-active.svg'
import customersInactiveIcon from '../assets/images/menu-icons/customers-inactive.svg'
import salesDataActiveIcon from '../assets/images/menu-icons/sales-data-active.svg'
import salesDataInactiveIcon from '../assets/images/menu-icons/sales-data-inactive.svg'

import Dashboardthree from '../components/DashThree/Dashboardthree'
import Fos from '../components/Fos/Fos'
import CustomerList from '../components/Customers/CustomerList'
import SalesData from '../components/Sales/SalesData'
import LoanProducts from '../components/LoanProducts/LoanProducts'
import LoanAppParentTab from '../components/LoanApplications/LoanAppParentTab'
import Preapproved from '../components/Preapprovedloanoffer/Preapproved'
import RegionList from '../components/RegionManagement/RegionList'
import LoanSanctioned from '../components/LoanSanctioned/LoanSanctioned'
import LoanDisbursed from '../components/LoanDisbursed/LoanDisbursed'
import LoansDefaulted from '../components/LoansDefaulted/LoansDefaulted'
import LoansClosed from '../components/LoansClosed/LoansClosed'
import Repayment from '../components/Repayment/Repayment'
import UpcomingRepayments from '../components/UpcomingRepayments/UpcomingRepayments'
import TranchesList from '../components/TranchesList/TranchesList'
import Logout from '../components/Login/Logout'
import NewRegion from '../components/RegionManagement/NewRegion'
import FosForm from '../components/Fos/FosForm'
import CustomerForm from '../components/Customers/CustomerForm'
import Dashboard from '../components/Dashboard/Dashboard'
import Loandetails from '../components/Preapprovedloanoffer/Loandetails'
import LoanViewDetails from '../components/LoanProducts/LoanViewDetails'
import LoanAccountDetails from '../components/LoanSanctioned/LoanAccountDetails'
import LoanAccountStatement from '../components/LoanSanctioned/LoanAccountStatement'
import CustomerDetails from '../components/Customers/customerDetails.jsx'
import ExpiryList from '../components/ExpiryList/ExpiryList.jsx'
import CreditLineInUse from '../components/CreditLineInUse/CreditLineInUse.jsx'
import InProgressRepayments from '../components/InProgressRepayments/InProgressRepayments.jsx'
import ManualRepayment from '../components/ManualRepayment/ManualRepayment.jsx'

const routs = [
    {
        collapse: false,
        path: "dashboard",
        layout: "/admin",
        name: 'Dashboard',
        menuicon: dashboardInactiveIcon,
        menuiconActive: dashboardActiveIcon,
        show: true,
        component : Dashboard
    },
    {
        collapse: false,
        path: "regionlist",
        layout: "/admin",
        name: 'Regions',
        menuicon: regionsInactiveIcon,
        menuiconActive: regionsActiveIcon,
        show: true,
        component : RegionList
    },
    {
        collapse: false,
        path: "addregion",
        layout: "/admin",
        name: "Add Region",
        state: "add Region",
        key: 'addregion',
        show: false,
        component: NewRegion,
      },
      {
        collapse: false,
        path: "/editRegion/:id",
        layout: "/admin",
        name: "Edit Region",
        state: "edit region",
        key: 'editregion',
        show: false,
        component: NewRegion,
      },
    {
        collapse: false,
        path: "fos",
        layout: "/admin",
        name: 'FOS',
        menuicon: fosInactiveIcon,
        menuiconActive: fosActiveIcon,
        show: true,
        component : Fos
    },
    {
        collapse: false,
        path: "addfos",
        layout: "/admin",
        name: "Add Fos",
        state: "add fos",
        key: 'addfos',
        show: false,
        component: FosForm,
    },
    {
        collapse: false,
        path: "editfos/:id",
        layout: "/admin",
        name: "Edit Fos",
        state: "edit fos",
        key: 'editfos',
        show: false,
        component: FosForm,
    },
    {
        collapse: false,
        path: "/customerdetails/:_id",
        layout: "/admin",
        name: "Customer Details",
        state: "Customer Details",
        key: 'customerdetails',
        show: false,
        component: CustomerDetails,
      },
    {
        collapse: false,
        path: "customerlist",
        layout: "/admin",
        name: 'Customers',
        menuicon: customersInactiveIcon,
        menuiconActive: customersActiveIcon,
        show: true,
        component : CustomerList
    },
    {
        collapse: false,
        path: "addcustomer",
        layout: "/admin",
        name: "Add Customer",
        state: "Add Customer",
        key: 'addcustomer',
        show: false,
        component: CustomerForm,
    },
    {
        collapse: false,
        path: "/editcustomer/:id",
        layout: "/admin",
        name: "Edit customer",
        state: "Edit customer",
        key: 'editcustomer',
        show: false,
        component: CustomerForm,
      },
    {
        collapse: false,
        path: "salesdata",
        layout: "/admin",
        name: 'Sales Data',
        menuicon: salesDataInactiveIcon,
        menuiconActive: salesDataActiveIcon,
        show: true,
        component : SalesData
    },
    {
        collapse: false,
        path: "loanproducts",
        layout: "/admin",
        name: 'Loan Products',
        menuicon: loanProductsInactiveIcon,
        menuiconActive: loanProductsActiveIcon,
        show: true,
        component : LoanProducts
    },
    {
        collapse: false,
        path: "/loandetails/:_id",
        layout: "/admin",
        name: "Loan Details",
        state: "Loan Details",
        key: 'loandetails',
        show: false,
        component: LoanViewDetails,
      },
    {
        collapse: false,
        path: "loanapplication",
        layout: "/admin",
        name: 'Loan Applications',
        menuicon: loanApplicationsInactiveIcon,
        menuiconActive: loanApplicationsActiveIcon,
        show: true,
        component : LoanAppParentTab
    },
    {
        collapse: false,
        path: "preapprovedloanoffer",
        layout: "/admin",
        name: 'Pre Approved Loan Offer',
        menuicon: preApprovedLoanOffersInactiveIcon,
        menuiconActive: preApprovedLoanOffersActiveIcon,
        show: true,
        component : Preapproved
    },
    {
        collapse: false,
        path: "/preapprovedloan/loandetails/:id",
        layout: "/admin",
        name: "Loan Details",
        state: "loandetails",
        key: 'loan details',
        show: false,
        component: Loandetails,
      },
      {
        collapse: false,
        path: "/loansanctioned/loanaccountdetails/:clientId",
        layout: "/admin",
        name: "Loan Account Details",
        state: "loanaccountdetails",
        key: 'loanaccountdetails',
        show: false,
        component: LoanAccountDetails,
      },
      {
        collapse: false,
        path: "/loansanctioned/loanaccountdetails/loanaccountstatement/:id/:clientId",
        layout: "/admin",
        name: "Loan Statement",
        state: "loanstatement",
        key: 'loanstatement',
        show: false,
        component: LoanAccountStatement,
      },
    {
        collapse: true,
        path: "",
        layout: "/admin",
        name: 'Loan Generated',
        menuicon: loansGeneratedInactiveIcon,
        menuiconActive: loansGeneratedActiveIcon,
        show: true,
        children : [
            {
                collapse: false,
                path: "loansanctioned",
                layout: "/admin",
                name: 'Loans Sanctioned',
                menuicon: loansSanctionedInactiveIcon,
                menuiconActive: loansSanctionedActiveIcon,
                show: true,
                component : LoanSanctioned
            },
            {
                collapse: false,
                path: "loandisbursed",
                layout: "/admin",
                name: 'Loans Disbursed',
                menuicon: loansDisbursedInactiveIcon,
                menuiconActive: loansDisbursedActiveIcon,
                show: true,
                component : LoanDisbursed
            },
            {
                collapse: false,
                path: "loandefaulted",
                layout: "/admin",
                name: 'Loan Defaulted',
                menuicon: loanDefaultedInactiveIcon,
                menuiconActive: loanDefaultedActiveIcon,
                show: true,
                component : LoansDefaulted
            },
            {
                collapse: false,
                path: "loanclosed",
                layout: "/admin",
                name: 'Loan Closed',
                menuicon: loanClosedInactiveIcon,
                menuiconActive: loanClosedActiveIcon,
                show: true,
                component : LoansClosed
            },
        ]
    },
    {
        collapse: false,
        path: "repayment",
        layout: "/admin",
        name: 'Latest Repayments',
        menuicon: latestRepaymentsInactiveIcon,
        menuiconActive: latestRepaymentsActiveIcon,
        show: true,
        component : Repayment
    },
    {
        collapse: false,
        path: "upcomingrepayments",
        layout: "/admin",
        name: 'Upcoming Repayments',
        menuicon: upcomingRepaymentsInactiveIcon,
        menuiconActive: upcomingRepaymentsActiveIcon,
        show: true,
        component : UpcomingRepayments
    },
    {
        collapse: false,
        path: "inprogressrepayments",
        layout: "/admin",
        name: 'In Progress Repayments',
        menuicon: inProgressRepaymentsInactiveIcon,
        menuiconActive: inProgressRepaymentsActiveIcon,
        show: true,
        component : InProgressRepayments
    },
    {
        collapse: false,
        path: "tranchescreated",
        layout: "/admin",
        name: 'Tranches Created',
        menuicon: tranchesCreatedInactiveIcon,
        menuiconActive: tranchesCreatedActiveIcon,
        show: true,
        component : TranchesList
    },
    {
        collapse: true,
        path: "",
        layout: "/admin",
        name: "Help and Support",
        show: true,
        menuicon: helpDeskInactiveIcon,
        menuiconActive: helpDeskActiveIcon,
        children: [
            {
                collapse: false,
                path: "expirylist",
                layout: "/admin",
                name: 'Expiry List',
                menuicon: expiryInactiveIcon,
                menuiconActive: expiryActiveIcon,
                show: true,
                component : ExpiryList
            },
            {
                collapse: false,
                path: "creditlineinuse",
                layout: "/admin",
                name: 'Creditline In-use',
                menuicon: creditLineInuseInactiveIcon,
                menuiconActive: creditLineInuseActiveIcon,
                show: true,
                component : CreditLineInUse
            },
            {
                collapse: false,
                path: "manual-repayment",
                layout: "/admin",
                name: 'Manual Repayment',
                menuicon: creditLineInuseInactiveIcon,
                menuiconActive: creditLineInuseActiveIcon,
                show: true,
                component : ManualRepayment
            },
        ]
    },
    {
        collapse: false,
        path: "logout",
        layout: "/admin",
        name: 'Logout',
        menuicon: logoutInactiveIcon,
        menuiconActive: logoutActiveIcon,
        show: true,
        component : Logout
    },
    
]

export default routs