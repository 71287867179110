import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getRepaymentsList = createAsyncThunk(
    'Repayments List',
    async ({ pageNum, searchTerm, fromDate, toDate }) => {
        const url = `/loans/repayment-list?page=${pageNum}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await DataService.get(url);
        return res.data;
    }
);

//download Repayment excel
export const getRepaymentsDataExcel = createAsyncThunk(
    'RepaymentsDataExcel',
    async (data, thunkAPI) => {
        const { pageNum, searchTerm, fromDate, toDate, totalCount } = data;
        try {
            const url = searchTerm ?
                `/loans/repayment-list?page=1&limit=${totalCount}&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}` :
                `/loans/repayment-list?page=1&limit=${totalCount}&fromDate=${fromDate}&toDate=${toDate}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

//generate excel sheet
// export const generateExcel = createAsyncThunk(
//     "generate excel sheet",
//     async ({ fromDate, toDate }) => {
//         const url = `excel/repaymentList?fromDate=${fromDate}&toDate=${toDate}`;
//         const res = await DataService.get(url);
//         return res.data;
//     }
// );

const initialState = {
    repaymentsList: [],
    totalCount: '',
    RepaymentListExcelSheet: [],
    repaymentDataExcel: [],
    isLoading: true,
}

const repaymentsSlice = createSlice({
    name: 'repayments slice',
    initialState,
    extraReducers: {
        [getRepaymentsList.pending]: (state) => {
            state.isLoading = true;
        },
        [getRepaymentsList.fulfilled]: (state, action) => {
            state.repaymentsList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
            state.isLoading = false;
        },
        [getRepaymentsList.rejected]: (state) => {
            state.isLoading = false;
        },
        [getRepaymentsDataExcel.fulfilled]: (state, action) => {
            state.repaymentDataExcel = action.payload.data;
        },
        // [generateExcel.fulfilled]: (state, action) => {
        //     state.RepaymentListExcelSheet = action.payload.data;
        // },
    }

})

export default repaymentsSlice.reducer