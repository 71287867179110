import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slice/auth'
import messageReducer from './slice/auth'
import regionReducer from './slice/region'
import fosReducer from './slice/fos'
import employeeReducer from './slice/employee'
import preReducer from './slice/preapprovedloanoffer'
import salesdataReducer from './slice/salesdata'
import loanSanctionedReducer from './slice/loansanctioned'
import loanDisbursedReducer from './slice/loandisbursed'
import dashboardReducer from './slice/dashboard';
import loanProductsReducer from './slice/loanproducts';
import repaymentsReducer from './slice/repayments'
import loanaccdetailsReducer from './slice/loanaccountdetails'
import forgotpasswordReducer from './slice/forgotpassword'
import loanDefaultedReducer from './slice/loandefaulted'
import loanClosedReducer from './slice/loanclosed'
import loanApplicationReducer from './slice/loanapplications'
import upcomingRepaymentsReducer from './slice/upcomingrepayments'
import tranchesListReducer from './slice/trancheslist'
import notificationsReducer from './slice/header'
import commonslice from './slice/commonslice'
import welcomeSlice from './slice/DashboardSlice/welcomeSlice';
import loanStatusSlice from './slice/DashboardSlice/loanStatusSlice';
import totalRevenueSlice from './slice/DashboardSlice/totalRevenueSlice'
import feesSlice from './slice/DashboardSlice/feesSlice';
import applicationStatusSlice from './slice/DashboardSlice/applicationStatusSlice';
import repaymentsSlice from './slice/DashboardSlice/repaymentsSlice';
import weeklyLoansSlice from './slice/DashboardSlice/weeklyLoansSlice';
import loanOffersSlice from './slice/DashboardSlice/loanOffersSlice';
import loanClosuresSlice from './slice/DashboardSlice/loanClosuresSlice';
import activeCreditLineSlice from './slice/DashboardSlice/activeCreditLineSlice';
import dpdSlice from './slice/DashboardSlice/dpdSlice';
import geographicalDistributionSlice from './slice/DashboardSlice/geographicalDistributionSlice';
import expiryListSlice from './slice/expiryListSlice'
import creditLineInuseSlice from './slice/creditLineInuseSlice'
import inProgressRepaymentSlice from './slice/inProgressRepaymentSlice'
import manualRepaymentSlice from './slice/manualRepaymentSlice'

const reducer = {
    auth: authReducer,
    message: messageReducer,
    region: regionReducer,
    fos: fosReducer,
    employee: employeeReducer,
    preApprovedLoanOffer: preReducer,
    salesDataUpload: salesdataReducer,
    loanSanctioned: loanSanctionedReducer,
    loanDisbursed: loanDisbursedReducer,
    dashboard: dashboardReducer,
    loanProducts: loanProductsReducer,
    repayments: repaymentsReducer,
    loanaccdetails: loanaccdetailsReducer,
    forgotPassword: forgotpasswordReducer,
    loanDefaulted: loanDefaultedReducer,
    loanClosed: loanClosedReducer,
    loanApplication: loanApplicationReducer,
    upcomingRepayments: upcomingRepaymentsReducer,
    tranchesList: tranchesListReducer,
    notifications: notificationsReducer,
    commonapi: commonslice,
    dashboardwelcomedata: welcomeSlice,
    totalrevenueapi: totalRevenueSlice,
    loanstatusapi: loanStatusSlice,
    feesapi: feesSlice,
    applicationstatusapi: applicationStatusSlice,
    repaymentsapi : repaymentsSlice,
    weeklloansapi : weeklyLoansSlice,
    loanoffersapi: loanOffersSlice,
    loanclosuresapi: loanClosuresSlice,
    activecreditlineapi : activeCreditLineSlice,
    dpdapi: dpdSlice,
    geographicalapi : geographicalDistributionSlice,
    expirylistapi : expiryListSlice,
    creditlineinuseapi : creditLineInuseSlice,
    inprogressrepaymentsapi : inProgressRepaymentSlice,
    manualrepayemntapi : manualRepaymentSlice,
}


const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})


export default store