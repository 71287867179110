import React from 'react'

const VectorImage = () => {
    return (
        <div>
            <svg width="341" height="95" viewBox="0 0 341 95" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute'}}>
                <path opacity="0.5" d="M0 0L341 95H139.348L0 0Z" fill="#BFB9FF" />
            </svg>

            <svg width="341" height="95" viewBox="0 0 341 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M334 0L0 93H197.513L334 0Z" fill="#BFB9FF" />
            </svg>
        </div>
    )
}

export default VectorImage