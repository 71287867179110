import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getRepayments = createAsyncThunk(
    'getRepayments',
    async (value) => {
        try {
            const url = `/console-web/repayments`
            const response = await DataService.post(url, value)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    repaymentsData: '',
}

const repaymentsSlice = createSlice({
    name: 'repaymentsslice',
    initialState,
    extraReducers: {
        [getRepayments.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getRepayments.fulfilled]: (state, { payload }) => {
            // console.log('repaymentsData => ', payload)
            return { ...state, repaymentsData: payload?.data, isLoading: false }
        },
    }
})
export const getRepaymentsData = (state) => state.repaymentsapi;
export default repaymentsSlice.reducer