import React from 'react'
import { Grid } from '@material-ui/core'

const LoanDetailsRow = ({ item_one, item_two, item_three }) => {
    return (
        <>
            <Grid container style={{ marginTop: '30px' }}>
                {item_one}
                {item_two}
                {item_three}
            </Grid>
        </>
    )
}

export default LoanDetailsRow