import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getWeeklyLoans = createAsyncThunk(
    'getWeeklyLoans',
    async (value) => {
        try {
            const url = `/console-web/weekly_loans_monthwise`
            const response = await DataService.post(url, value)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    weeklyLoansData: '',
}

const weeklyLoansSlice = createSlice({
    name: 'weeklyLoansslice',
    initialState,
    extraReducers: {
        [getWeeklyLoans.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getWeeklyLoans.fulfilled]: (state, { payload }) => {
            return { ...state, weeklyLoansData: payload?.data, isLoading: false }
        },
    }
})
export const getWeeklyLoansData = (state) => state.weeklloansapi;
export default weeklyLoansSlice.reducer