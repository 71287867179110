import React, {useState, useEffect} from 'react'
import './Dashboardstyles.css'
import VectorImage from './VectorImage'

const DashboardGreeting = () => {

    const [greetings, setGreetings] = useState('')

    useEffect(() => {
        var today = new Date()
        var curHr = today.getHours()
    
        if (curHr < 12) {
            setGreetings('Good Morning')
        } 
        else if ( curHr >= 12 && curHr <= 17) {
            setGreetings('Good Afternoon')
        } 
        else {
            setGreetings('Good Evening')
        }
    
    }, [])
    
    
    return (
        <div>
            <section className='dash-greeting'>
                <div className='dash-greeting-inline'>
                    <h3 className='greet-header'>{greetings}! Have a great Day!</h3>
                    <h5 className='greet-body'>Here is what's happening in your business today.</h5>
                </div>
                <div className='vector-image'>
                    <VectorImage />
                </div>
            </section>
        </div>
    )
}

export default DashboardGreeting