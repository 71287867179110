import React, { useState, useEffect } from 'react'
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import { Paper, TableBody, TableRow, TableCell, TextField, IconButton, Button } from "@material-ui/core";
import { LOAN_DEFAULTED_HEADER, LOAN_DEFAULTED_SUBHEADER } from '../../utils/pageHeadings';
import { BsSearch } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import { useSelector, useDispatch } from 'react-redux';
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { getLoanDefaultedList, getLoanDefaultedExcel, generateExcel } from '../../slice/loandefaulted';
import { DatePicker } from 'antd';
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import DownloadIcon from '@mui/icons-material/Download';
// import { GrLinkNext } from "react-icons/gr"
import moment from 'moment';


const LoansDefaulted = () => {

    //For getting today and yesterday date
    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const classes = makemyStyles();
    const [search, setSearch] = useState("")
    const dispatch = useDispatch()
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const excelData = useExcelDownload()
    const [excelSheetData, setExcelSheetData] = useState([]);

    const { loanDefaultedList, loanDefaultedExcel, totalCount, isLoading, loanDefaultedExcelSheet } = useSelector((store) => store.loanDefaulted);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(loanDefaultedList, HeadCells.loanDefaultedHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    // useEffect(() => {
    //     const dates = {
    //         fromDate,
    //         toDate
    //     }
    //     dispatch(generateExcel(dates))
    // }, [fromDate, toDate])

    useEffect(() => {
        const data = {
            pageNo: pageNo,
            search: search,
            fromDate,
            toDate,
            totalCount,
        };
        dispatch(getLoanDefaultedList(data));
        dispatch(getLoanDefaultedExcel(data));
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    useEffect(() => {
        let excelsheetdata = []
        loanDefaultedExcel?.map(item => {
            excelsheetdata.push({
                "RT/DT Code": item.customerid,
                "Loan A/C No": item.loanaccountNumber,
                "Name": item.name,
                "Loan Name": item.loanName,
                "Amount": item.amount_sanctioned,
                "Interest Rate": item.interest_rate,
                "Tenure": item.tenure,
                "Dpd": item.dpd,
                "Defaulted Date": moment(item.defaultDate).format('DD MMMM YYYY'),
                "Status": item.status,
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [loanDefaultedExcel])

    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader
                    header={LOAN_DEFAULTED_HEADER}
                    subHeader={LOAN_DEFAULTED_SUBHEADER}
                />
                <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                    <TextField
                        size="small"
                        fullWidth
                        className={classes.searchTextBar}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    />
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            format={dateFormat}
                            className={classes.datePicker}
                            onChange={onDateChange}
                        // separator={<GrLinkNext />}
                        />
                        <Button className={classes.excelUploadButton} onClick={() => excelData.generateExcelFile(excelSheetData, 'LoanDefaultedSheet', 'LoanDefaulted.xlsx')} endIcon={<DownloadIcon />}>Download Report</Button>
                    </div>
                </div>
                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (<TableBody><TableRow><TableCell colSpan={10} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (loanDefaultedList.length !== 0) ? (
                                            loanDefaultedList && loanDefaultedList
                                                .map((item, key) => {
                                                    key++;
                                                    serialNo++;
                                                    return (<TableRow key={key} >
                                                        <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.customerid}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.trancheId}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.loanaccountNumber}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.loanName}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.amount_sanctioned}</TableCell>
                                                        {/* <TableCell className={classes.tableCell} align='center'>{item.interest_rate}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.tenure}</TableCell> */}
                                                        <TableCell className={classes.tableCell}>{item.dpd}</TableCell>
                                                        <TableCell className={classes.tableCell}>{moment(item.defaultDate).format("DD MMMM YYYY")}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.status}</TableCell>
                                                    </TableRow>)
                                                })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={10} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {
                    showPageNtn ? <TblPagination /> : ''
                }
            </Paper>
        </>
    )
}

export default LoansDefaulted