import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getgeographicalDistribution = createAsyncThunk(
    'getgeographicalDistribution',
    async () => {
        try {
            const url = `/console-web/geographical-distribution`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    geographicalDistributionData: '',
}

const geographicalDistributionSlice = createSlice({
    name: 'geographicaldistributionslice',
    initialState,
    extraReducers: {
        [getgeographicalDistribution.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getgeographicalDistribution.fulfilled]: (state, { payload }) => {
            return { ...state, geographicalDistributionData: payload?.data?.geographicalData, isLoading: false }
        },
    }
})
export const getgeographicalDistributionData = (state) => state.geographicalapi;
export default geographicalDistributionSlice.reducer