import React, { useEffect, useState } from "react";
import { getStateList, postRegion, getRegionDataByID, updateRegion, reset_state, getCityList } from "../../slice/region";
import { Paper, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Controls from "../Controls/Controls";
import { useForm, Form } from "../Sharedfeatures/useForm";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import { REGION_LIST } from "../../utils/links";
import { ADD_REGION_HEADER, EDIT_REGION_HEADER, NEW_REGION_SUB_HEADER, BACK_BUTTON } from "../../utils/pageHeadings";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from '../Sharedfeatures/useSnackbar'


const initialValues = {
  state: "",
  name: "",
};

export default function NewRegion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = makemyStyles();
  const snackBar = useSnackbar();
  const { id } = useParams();

  const header = id ? EDIT_REGION_HEADER : ADD_REGION_HEADER;

  const { stateList, cityList, regionData, message, status, loading, requestId, errorMessage } = useSelector((state) => state.region);
  const list_of_states = stateList && stateList
  const list_of_cites = cityList && cityList

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    temp.name = ("name" in fieldValues) ? fieldValues.name ? "" : "This field is required" : "";
    temp.state = ("state" in fieldValues) ? fieldValues.state ? "" : "This field is required" : "";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  useEffect(() => {
    dispatch(getStateList());
    if (values.state) {
      dispatch(getCityList(values.state));
    }
  }, [dispatch, values.state]);


  useEffect(() => {
    if (id) {
      dispatch(getRegionDataByID(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setValues({
      ...values,
      id: id,
      state: regionData && regionData.state && regionData.state._id,
      name: regionData && regionData.name,
      selectedstate: values.state,
    });
  }, [regionData]);

  useEffect(() => {
    resetForm();
    dispatch(reset_state())
  }, [id])

  useEffect(() => {
    dispatch(reset_state())
  }, [errorMessage, id, message])

  useEffect(() => {

    if (id && status === "fulfilled" && loading) {
      //EDIT REGION
      snackBar.openSnackbar(message);
      resetForm();
      setTimeout(() => {
        navigate(`${REGION_LIST}`);
      }, 800);
    }

    if (!id && status === "fulfilled" && loading) {
      //ADD REGION
      snackBar.openSnackbar(message);
      resetForm();
      setTimeout(() => {
        navigate(`${REGION_LIST}`);
      }, 800);
    }

  }, [message, status, id, requestId]);



  useEffect(() => {
    //Region field validator block
    if (errorMessage)
      setErrors({ ...errors, name: errorMessage })
  }, [errorMessage])


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (id) {
        dispatch(updateRegion(values));
      } else {
        dispatch(postRegion(values));
      }
    }
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={header}
          subHeader={NEW_REGION_SUB_HEADER}
          icon={BACK_BUTTON}
          link={REGION_LIST}
        />

        <Form onSubmit={handleSubmit}>
          <Paper className={classes.addNewPageContent}>
            <Grid container>
              <Grid item xs={6}>
                <Controls.Selectstate
                  label="State"
                  name="state"
                  value={values.state || ""}
                  onChange={handleInputChange}
                  options={list_of_states}
                  error={errors.state}
                />
                <Controls.Selectcity
                  label="City Name"
                  name="name"
                  value={values.name || ""}
                  onChange={handleInputChange}
                  options={list_of_cites}
                  error={errors.name}
                />
                {/* <Controls.Input
                  label="Name"
                  name="name"
                  id="myInput"
                  type="text"
                  value={values.name || ""}
                  onChange={handleInputChange}
                  error={errors.name}
                  onKeyDown={(event) => {
                    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}
                <div className="ml-2">
                  <Controls.Button
                    type="submit"
                    text="Submit"
                    className={classes.submitButton}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Form>
        <div>
          <Snackbar {...snackBar} />
        </div>
      </Paper>
    </>
  );
}
