import React, { useState, useEffect } from "react";
import { TableBody, TableRow, TableCell, Button, TextField, IconButton, Tooltip, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import {
  getFosList,
  getPreApproved,
  assignFos,
  getPreApprovedExcel,
} from "../../slice/preapprovedloanoffer";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { Link } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import FosAssigned from "./FosAssigned";
import { PAGE_SIZE } from "../../utils/variable";
import { BsSearch } from "react-icons/bs";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker } from 'antd';


const PreapprovedTab = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;


    const { preApprovedLoanOffer, totalCount, isLoading } = useSelector((state) => state.preApprovedLoanOffer);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(preApprovedLoanOffer, HeadCells.preApprovedHead, totalCount);
    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [open, setOpen] = useState(false);
    const classes = makemyStyles();
    const moment = require("moment");
    const [search, setSearch] = useState('')
    const [loanDetails, setLoanDetails] = useState()
    const excelData = useExcelDownload();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    const onBtnClick = (item) => {
        setOpen(!open);
        setLoanDetails(item)
    };

    const submitFosAssigned = (fosid, loanid) => {
        const data = { loanapproval_id: loanid, fosId: fosid };
        if (loanid && fosid) {
        dispatch(assignFos(data));
        onBtnClick();
        }
    };

    useEffect(() => {
        const data = {
        searchTerm: search,
        pageNo: pageNo,
        fromDate,
        toDate,
        }
        dispatch(getPreApproved(data));
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, fromDate, toDate]);

    useEffect(() => {
        dispatch(getFosList());
    }, [dispatch]);

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    const downloadExcelsheet = ()=>{
        const data = {
            searchTerm: search,
            pageNo: pageNo,
            fromDate,
            toDate,
            totalCount
        }
        dispatch(getPreApprovedExcel(data))
        .then(res=>{
          if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "RT/DT Code": item.UserId,
                    "Name": item.Name,
                    "Email": item.Email,
                    "Mobile": item.Mobile,
                    "Loan Product": item.LoanName,
                    "Amount": item.Amount,
                    "Region": item.RegionName,
                    "Created On": moment(item.created_on).format('DD MMMM YYYY'),
                    "FOS Assigned": item.FosName,
                    "Tenure": item.Tenure,
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'PreApprovedLoanOfferSheet', 'preapprovedloanoffer.xlsx')
          }
        })
        .catch(err=>{
          console.error(err);
        })
    }
    return (

        <div style={{ display: 'flex', gap: '15px', justifyContent: 'space-between', flexDirection: 'column' }} >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                <TextField
                size="small"
                className={classes.preAppSearchTextBar}
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                placeholder="Search Name or RT/DT Code"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                    endAdornment: (
                    <IconButton>
                        <BsSearch className={classes.searchIconColour} />
                    </IconButton>
                    ),
                }}
                />
                <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                <RangePicker
                    placeholder={["From Date", "To Date"]}
                    format={dateFormat}
                    className={classes.datePickerPre}
                    onChange={onDateChange}
                />
                <Button
                    className={classes.preApprovedExcelUploadButton}
                    onClick={downloadExcelsheet}
                    endIcon={<DownloadIcon />}
                >
                    Download Report
                </Button>
                </div>
            </div>
            <Paper className={classes.tableBoxShadow}>
                <TblContainer>
                <TblHead />
                {isLoading ? (
                    <TableBody ><TableRow><TableCell colSpan={9} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
                ) : (
                    <TableBody>
                    {
                        (preApprovedLoanOffer.length !== 0) ? (
                        preApprovedLoanOffer &&
                        preApprovedLoanOffer.map((item, key) => {
                            key++;
                            serialNo++;
                            return (
                            <TableRow key={key.toString()}>
                                <TableCell className={classes.tableCell}>
                                {serialNo}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {item.userid}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {item.name}
                                </TableCell>
                                {/* <TableCell className={classes.tableCell}>
                                {item.mobile}
                                </TableCell> */}
                                <TableCell className={classes.tableCell}>
                                {item.amount}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {item.loanName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {item.region_name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {moment(item.created_on).format("DD MMMM YYYY")}
                                </TableCell>
                                <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: "center" }}
                                >
                                {item.fos.name ? (
                                    <Button onClick={() => onBtnClick(item)} color="primary" variant="outlined" style={{ fontSize: '12px', width: '150px' }}>
                                    {item.fos.name}
                                    </Button>
                                ) : (
                                    <Button onClick={() => onBtnClick(item)} color="primary" variant="outlined" style={{ fontSize: '12px', width: '50px' }}>
                                    Assign FOS
                                    </Button>
                                )}
                                </TableCell>
                                <TableCell
                                className={classes.tableCell}
                                style={{ paddingLeft: "25px" }}
                                >
                                <Link
                                    to={`/admin/preapprovedloan/loandetails/${item.loanapproval_id}`}
                                >
                                    <Tooltip title='View Loan Details'>
                                    <IconButton>
                                        <BsFillEyeFill className={classes.actionIcon} />
                                    </IconButton>
                                    </Tooltip>

                                </Link>
                                </TableCell>
                            </TableRow>
                            );
                        })
                        ) : (
                        <TableRow>
                            <TableCell colSpan={9} align='center'>No Data to Show</TableCell>
                        </TableRow>
                        )
                    }
                    </TableBody>
                )}
                </TblContainer>
            </Paper>
            {open ? (
                <FosAssigned open={open} onClose={onBtnClick} onSubmit={submitFosAssigned} loanInfo={loanDetails} />
            ) : null}
            {showPageNtn ? <TblPagination /> : ""}
        </div>
    );
};

export default PreapprovedTab
