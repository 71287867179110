import React, { useEffect } from 'react'
import { Paper, Typography, Grid, ListItemText } from '@material-ui/core'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { getLoanDetails } from '../../slice/preapprovedloanoffer'
import PageHeader from '../Common/PageHeader'
import { PRE_APP_LIST } from '../../utils/links'
import { PRE_APP_DETAILS_HEADER, PRE_APP_DETAILS_SUBHEADER, BACK_BUTTON, PRE_APP_HEADER, PRE_APP_SUBHEADER } from '../../utils/pageHeadings'
import LoandetailsHeader from './LoandetailsHeader'
import LoanDetailsBodyCells from './LoanDetailsBodyCells'
import LoanDetailsRow from './LoanDetailsRow'

const Loandetails = () => {
    const classes = makemyStyles();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getLoanDetails(id))
    }, [])

    const { loanDetails } = useSelector((state) => state.preApprovedLoanOffer)

    return (
        <div>
            <Paper className={classes.pageContent}>
                <PageHeader header={PRE_APP_DETAILS_HEADER} subHeader={PRE_APP_DETAILS_SUBHEADER} icon={BACK_BUTTON} link={PRE_APP_LIST} />

                <Paper className={classes.detailsCard}>
                    <LoandetailsHeader />

                    <LoanDetailsRow
                        item_one={<LoanDetailsBodyCells title='Aadhar:' details={loanDetails.aadhar} />}
                        item_two={<LoanDetailsBodyCells title='PAN:' details={loanDetails.pan} />}
                        item_three={<LoanDetailsBodyCells title='Region:' details={loanDetails && loanDetails.region && loanDetails.region.name} />}
                    />

                    <LoanDetailsRow
                        item_one={<LoanDetailsBodyCells title='Pin Code:' details={loanDetails.pincode} />}
                        item_two={<LoanDetailsBodyCells title='Loan Type:' details={loanDetails && loanDetails.loanapproval && loanDetails.loanapproval.loanType} />}
                        item_three={<LoanDetailsBodyCells title='Role:' details={loanDetails.role} />}
                    />

                    <LoanDetailsRow
                        item_one={<LoanDetailsBodyCells title='Amount:' details={loanDetails && loanDetails.loanapproval && loanDetails.loanapproval.amount} />}
                        item_two={<LoanDetailsBodyCells title='Designation:' details={loanDetails.designation} />}
                    />
                </Paper>
            </Paper>
        </div>
    )
}

export default Loandetails