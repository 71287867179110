import Button from './Button';
import Input from './Input';
import Selectstate from './Selectstate';
import Selectregion from './Selectregion';
import Selectcustomer from './Selectcustomer'
import Selectsalesdata from './Selectsalesdata';
import Selectcity from './Selectcity';

const Controls = {
    Input,
    Button,
    Selectstate,
    Selectcity,
    Selectregion,
    Selectcustomer,
    Selectsalesdata,
}

export default Controls;