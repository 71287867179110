import React, { useState, useEffect } from 'react'
import { Paper, TableBody, TableRow, TableCell, Button, TextField, IconButton, } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import moment from 'moment';
import { DatePicker } from 'antd';
import { UPCOMING_REPAYMENTS_HEADER, UPCOMING_REPAYMENTS_SUB_HEADER } from '../../utils/pageHeadings';
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { getupcomingRepayments, getupcomingRepaymentsDataExcel } from '../../slice/upcomingrepayments';
import { BsSearch } from "react-icons/bs";
import DownloadIcon from '@mui/icons-material/Download';
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import { disableDates } from '../../utils/disableDates';
import RepayModal from './RepayModal';
import debounce from "lodash/debounce";
import { useMemo } from 'react';


const UpcomingRepayments = () => {

    //For getting today and yesterday date
    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const [excelSheetData, setExcelSheetData] = useState([]);
    const classes = makemyStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const [trancheId, setTrancheId] = useState("")
    const [loanApprovalId, setLoanApprovalId] = useState("")
    const [repayAmt, setRepayAmt] = useState("")
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;

    const { upcRepList, totalCount, isLoading, uprepaymentDataExcel } = useSelector((store) => store.upcomingRepayments);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(upcRepList, HeadCells.upcomingRepaymentsHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    useEffect(() => {
        const dates = {
            searchTerm: search,
            pageNo,
            fromDate,
            toDate
        }
        if(trancheId === ''){
            dispatch(getupcomingRepayments(dates))
            dispatch(getupcomingRepaymentsDataExcel(dates));
        }

        setShowPageNtn(true);
    }, [fromDate, toDate, pageNo, search, dispatch, trancheId])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        handleChange(null, 1)
    }
    const excelData = useExcelDownload()
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    useEffect(() => {
        let excelsheetdata = []
        uprepaymentDataExcel?.map(item => {
            excelsheetdata.push({                
                "RT/DT Code" : item.userid,
                "Tranche Id" : item.trancheid,
                "Name" : item.name,
                "Tranche Amount" : item.tranche_amount,
                "Loan Amount" : item.loan_amount,
                "Payable" : item.payableAmount,
                "Payback Date" : moment(item.payback_date).format('DD MMMM YYYY'),
                "Status" : item.status_text,
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [uprepaymentDataExcel])

    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader
                    header={UPCOMING_REPAYMENTS_HEADER}
                    subHeader={UPCOMING_REPAYMENTS_SUB_HEADER}
                />

                {/* <Box sx={{ height: '105px' }} /> */}
                <div style={{display:'flex', gap:'20px', alignItems:'flex-start', justifyContent:'space-between', flexWrap:'wrap'}} >
                    <TextField
                        size="small"
                        className={classes.searchTextBar}
                        //value={search}
                        onChange={debouncedResults}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    />
                    <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            format={dateFormat}
                            defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                            className={classes.noExcelDatePicker}
                            onChange={onDateChange}
                            disabledDate={disableDates}
                            allowClear={false}
                        />
                        <Button className={classes.excelUploadButton}
                            onClick={() => excelData.generateExcelFile(excelSheetData, 'UpcomingRepaymentListSheet', 'UpcomingRepaymentList.xlsx')}
                            endIcon={<DownloadIcon />}>Download Ledger
                        </Button>
                    </div>
                </div>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            align="center"
                                            className={classes.tableCell}
                                        >
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (upcRepList.length !== 0) ? (
                                            upcRepList && upcRepList
                                                .map((item, key) => {
                                                    key++;
                                                    serialNo++;
                                                    let paybackDate = moment(item.payback_date).format('DD MMMM YYYY')
                                                    let today = moment(new Date()).format('DD MMMM YYYY')
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.userid}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.trancheid}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.tranche_amount}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.loan_amount}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.payableAmount}</TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                            {moment(item.payback_date).format('DD MMMM YYYY')}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.status_text}</TableCell>
                                                            <TableCell className={classes.tableCell} > 
                                                                <div className={classes.reverseButtonWrap} >
                                                                    <Button 
                                                                        disabled={moment(paybackDate).isSame(today) ? false : true} 
                                                                        onClick={()=>{
                                                                            setTrancheId(item.trancheid); 
                                                                            setRepayAmt(item.payableAmount);
                                                                            setLoanApprovalId(item.loanapproval_id)
                                                                        }} 
                                                                        type="primary" className={classes.repayButton}>
                                                                            Repay 
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={10} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {
                    showPageNtn ? <TblPagination /> : ''
                }
            </Paper>
            {
                trancheId && loanApprovalId &&
                <RepayModal loanApprovalId={loanApprovalId} trancheId={trancheId} setTrancheId={setTrancheId} repayAmt={repayAmt}/>                
            }
        </>
    )
}

export default UpcomingRepayments