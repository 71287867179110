import { http , instance, filehttp } from "../http-common";

import axios from 'axios'


const get = (url) => {
  return http.get(url);
} 

const post = (url, data) => {
    return http.post(url, data);
}

const filepost = (url, data) => {
  return filehttp.post(url, data);
}

const put = (url, data) => {
  return instance.put(url, data);
}
const login = (username, password) => {
  let url = `${process.env.REACT_APP_API_URL}/users/login/superadmin`;
  return axios
      .post(url, {
          userid: username,
          password: password,
      })
      .then((response) => {
          if (response.data.data.token) {
              localStorage.setItem("accessToken", response.data.data.token);
          }
          return response.data;
      })
}

const logout = (url) => {
  return http.post(url);
}

const DataService = {
  get,
  post,
  put,
  logout,
  login,
  filepost,
};
export default DataService;