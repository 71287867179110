import React, { useState, useEffect } from 'react'
import { Paper, TableBody, TableRow, TableCell, Box, Button, TextField, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import moment from 'moment';
import { DatePicker } from 'antd';
import { TRANCHES_LIST_HEADER, TRANCHES_LIST_SUB_HEADER } from '../../utils/pageHeadings';
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { getTranchesList, getTranchesDataExcel } from '../../slice/trancheslist';
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";
import { BsSearch } from "react-icons/bs";


const TranchesList = () => {

    //For getting today and yesterday date
    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const classes = makemyStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const [excelSheetData, setExcelSheetData] = useState([]);

    const { tranchesList, tranchDataExcel, totalCount, isLoading } = useSelector((store) => store.tranchesList);
    // console.log("tranchDataExcel=>", tranchDataExcel)
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(tranchesList, HeadCells.tranchesListHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }
    const excelData = useExcelDownload();

    useEffect(() => {
        const data = {
            searchTerm: search,
            pageNo,
            fromDate,
            toDate,
            totalCount
        }
        dispatch(getTranchesList(data))
        setShowPageNtn(true);
    }, [fromDate, toDate, pageNo, search, totalCount, dispatch])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    const downloadExcelsheet = () => {
        const data = {
            searchTerm: search,
            pageNo,
            fromDate,
            toDate,
            totalCount
        }
        dispatch(getTranchesDataExcel(data))
            .then(res => {
                if (res.payload.success === true) {
                    let excelsheetdata = []
                    res.payload.data?.map(item => {
                        excelsheetdata.push({
                            "RT/DT Code": item.userid,
                            "Tranche Id": item.trancheid,
                            "Name": item.name,
                            "Tranche Amount": item.tranche_amount,
                            "Bank Reference Num": item.bank_reference_number,
                            "Loan Amount": item.loan_amount,
                            "Payback Date": moment(item.payback_date).format('DD MMMM YYYY'),
                            "Created Date": moment(item.created_on).format('DD MMMM YYYY'),
                            "Status": item.status_text,
                            "Failed Reason": item.failed_reason,
                        })
                    })
                    excelData.generateExcelFile(excelsheetdata, "TrancheSheet", "TranchesExcelSheet.xlsx")
                }
            })
            .catch(err => {
                console.error(err);
            })
    }

    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader
                    header={TRANCHES_LIST_HEADER}
                    subHeader={TRANCHES_LIST_SUB_HEADER}
                />

                {/* <Box sx={{ height: '105px' }} /> */}
                <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                    <TextField
                        size="small"
                        className={classes.searchTextBar}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    />
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            format={dateFormat}
                            defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                            className={classes.datePicker}
                            onChange={onDateChange}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.excelUploadButton}
                            onClick={downloadExcelsheet}
                            endIcon={<DownloadIcon />}
                        >
                            Download Ledger
                        </Button>
                    </div>
                </div>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={11}
                                            align="center"
                                            className={classes.tableCell}
                                        >
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (tranchesList.length !== 0) ? (
                                            tranchesList && tranchesList
                                                .map((item, key) => {
                                                    key++;
                                                    serialNo++;
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.userid}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.trancheid}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.tranche_amount}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.bank_reference_number}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.loan_amount}</TableCell>
                                                            <TableCell className={classes.tableCell}>{moment(item.payback_date).format('DD MMMM YY')}</TableCell>
                                                            <TableCell className={classes.tableCell}>{moment(item.created_on).format('DD MMMM YY')}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.status_text}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.failed_reason}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={11} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {
                    showPageNtn ? <TblPagination /> : ''
                }
            </Paper>
        </>
    )
}

export default TranchesList