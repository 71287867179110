import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import ModalImage from "react-modal-image";
import useStyles from './LoanAppStyles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 565,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  p: 4,
  overflowY: 'auto',
};

const RejectedDocsModal = ({ open, handleClose, data }) => {
  // console.log("DATA", data)
  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
      >
        <Box sx={style}>
          <Box className={classes.loanAppDetailParent}>
            <IconButton onClick={handleClose}>
              <AiOutlineClose />
            </IconButton>
          </Box>

          <Typography
            align="center"
            color={'#963460'}
            fontWeight={'bold'}
            variant="h6"
            component="h2"
          >
            Rejected Documents
          </Typography>

          <Box sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              {
                data && data.map((item, key) => (
                  <Grid item md={6} key={key.toString()}>

                    <div style={{
                      border: '2px solid black',
                      padding: '20px',
                      // borderRadius: '2px'
                    }}>
                      <ModalImage
                        small={`${process.env.REACT_APP_API_BASE_URL}/${item.fileName}`}
                        large={`${process.env.REACT_APP_API_BASE_URL}/${item.fileName}`}
                        alt="Rejected document"
                        hideDownload={true}
                        className={classes.customModalImage}
                      />

                      <p>File Name :
                        {
                          (item.fileType == 0) ? "Security Deposit Cheques" :
                            (item.fileType == 1) ? "Property Onsite" :
                              (item.fileType == 2) ? "Promoter Kyc" :
                                (item.fileType == 3) ? "Company Financials" : "Borrower Image"
                        }
                      </p>
                      <p>Reason: {item.reason_type}</p>
                      <p>Remarks: {item.remarks}</p>
                    </div>

                  </Grid>
                ))
              }
            </Grid>
          </Box>

        </Box>
      </Modal>
    </>
  )
}

export default RejectedDocsModal