import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TextField
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { BsSearch } from "react-icons/bs";
import Button from "../Controls/Button";
import PageHeader from "../Common/PageHeader";
import useTable from "../Sharedfeatures/useTable";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { ADD_REGION } from "../../utils/links";
import { REGION_HEADER, REGION_SUB_HEADER } from "../../utils/pageHeadings";
import { getRegionList, reset_state } from "../../slice/region";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const RegionList = () => {
  const classes = makemyStyles();
  const dispatch = useDispatch();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState('')

  const { regionList, totalCount, isLoading } = useSelector((state) => state.region);
  // console.log("REGIONS", regionList)

  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(regionList, HeadCells.regionHead, totalCount);

  useEffect(() => {
    dispatch(reset_state())
    const data = {
      'searchTerm': search,
      'pageNum': pageNo,
    }
    dispatch(getRegionList(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search]);

  let serialNo = PAGE_SIZE * slNo;

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  return (
    <>

      <Paper className={classes.pageContent}>

        <PageHeader header={`${REGION_HEADER}`} subHeader={`${REGION_SUB_HEADER}`} />
        <div style={{display:'flex', gap:'20px', alignItems:'center', justifyContent:'space-between'}}>
          <TextField
            size="small"
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            id="standard-bare"
            variant="outlined"
            placeholder="Search City Name"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />
          <Link to={`${ADD_REGION}`}>
            <Button
              text="Add New"
              variant="outlined"
              startIcon={<AddBoxOutlinedIcon />}
              className={classes.newButton}
            />
          </Link>
        </div>

        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align='center' className={classes.tableCell}>
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {
                  (regionList.length !== 0) ?
                    (
                      regionList &&
                      regionList.map((item, key) => {
                        serialNo++;
                        return (
                          <TableRow key={key.toString()}>
                            <TableCell className={classes.tableCell}>
                              {serialNo}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item && item.state && item.state.stateName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item && item.state && item.name}
                            </TableCell>
                            {/* <TableCell className={classes.tableCell}>
                              {item.name}
                            </TableCell> */}
                            <TableCell
                              className={classes.tableCell}
                              style={{ paddingLeft: "30px" }}
                            >
                              <Link to={`/admin/editRegion/${item._id}`}>
                                <Tooltip title='Edit Region'>
                                  <IconButton>
                                    <EditIcon className={classes.actionIcon} />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align='center'>No Data to Show</TableCell>
                      </TableRow>
                    )
                }
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {showPageNtn ? <TblPagination /> : ""}
      </Paper>
    </>
  );
};

export default RegionList;
