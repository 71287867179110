import React, { Fragment } from 'react'
import { Grid, Typography, ListItemText } from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'

const ChargesView = () => {
    const classes = makemyStyles();
    const { loanDetails } = useSelector((store) => store.loanProducts)
    if(loanDetails?.charges?.length < 0) return
    return (
        <>
        {
            loanDetails?.charges?.map((charge, index) =>{
                return (
                    <Fragment key={index}>
                        <Grid>
                            <h1 style={{ paddingLeft: "30px" }} className={classes.secHeading}>{`${charge?.penalty ? 'Overdue Charges' : 'Charges'}`}</h1>
                            <hr />
                        </Grid>

                        <Grid container style={{ marginTop: "25px" }}>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Name:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.name) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Status:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.active === true ? "Active" : "Inactive") || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Penalty:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.penalty === true ? "True" : "False") || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Amount:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.amount) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Charge Time Type:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.chargeTimeType && loanDetails.charges[0].chargeTimeType.value) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Charge Applies To:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.chargeAppliesTo && loanDetails.charges[0].chargeAppliesTo.value) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Charge Calculation Type:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.chargeCalculationType && loanDetails.charges[0].chargeCalculationType.value) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                                    <Grid item md={12}>
                                        <ListItemText primary={<Typography className={classes.employeeItems}>Charge Payment Mode:</Typography>} />
                                        <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{(charge?.chargePaymentMode && loanDetails.charges[0].chargePaymentMode.value) || <Skeleton />}</Typography>} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: "35px" }}></Grid>
                        </Grid>
                    </Fragment>
                )
            })
        }
        </>
    )
}

export default ChargesView