import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getbusinesstrend = createAsyncThunk(
    'getbusinesstrend',
    async (data) => {
        try {
            const url = `/console-web/business-trend/${data}`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    businessTrendData: ''
}

const welcomeSlice = createSlice({
    name: 'welcomeslice',
    initialState,
    reducers: {
        updateUserHeaderTitle: (state, { payload }) => {
            state.updateUserHeaderTitle = payload
        },
    },
    extraReducers: {
        [getbusinesstrend.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getbusinesstrend.fulfilled]: (state, { payload }) => {
            return { ...state, businessTrendData: payload?.data?.data, isLoading: false }
        },
    }
})
export const getAllDashboardData = (state) => state.dashboardwelcomedata;
export default welcomeSlice.reducer