import React from 'react'
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { Paper } from "@material-ui/core";
import PageHeader from '../Common/PageHeader';
import { LOAN_APPLICATION_HEADER, LOAN_APPLICATION_SUB_HEADER } from '../../utils/pageHeadings';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RejectedApplications from './RejectedApplications';
import LoanApplication from './LoanApplication';
import IncompleteApplications from './IncompleteApplications';

const LoanAppParentTab = () => {

    const classes = makemyStyles();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Paper className={classes.pageContent}>

                <PageHeader header={LOAN_APPLICATION_HEADER} subHeader={LOAN_APPLICATION_SUB_HEADER} />

                {/* <div style={{ height: "80px" }} /> */}

                <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleChange}>
                                <Tab label="Loan Applications" value="1" />
                                <Tab label="Documents Rejected" value="2" style={{ marginLeft: "40px" }} />
                                <Tab label="Incomplete Applications" value="3" style={{ marginLeft: "40px" }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <LoanApplication />
                        </TabPanel>
                        <TabPanel value="2">
                            <RejectedApplications />
                        </TabPanel>
                        <TabPanel value="3">
                            <IncompleteApplications />
                        </TabPanel>
                    </TabContext>
                </Box>

            </Paper>
        </>
    )
}

export default LoanAppParentTab