import React from 'react'
import useSharedClasses from './style'
import { Grid, ListItemText, Typography } from '@mui/material'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

const GridView = ({ leftHeader, leftDetail, rightHeader, rightDetail }) => {
    const styles = useSharedClasses();
    const classes = makemyStyles();

    const { cust_details_loading } = useSelector((store) => store.employee)

    return (
        <>
            <Grid container spacing={2} className={styles.gridContainerParent}>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={classes.listHeading}>{leftHeader}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={classes.listValues}>{cust_details_loading ? <Skeleton /> : leftDetail}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={classes.listHeading}>{rightHeader}</Typography>} />
                </Grid>
                <Grid item md={3} >
                    <ListItemText className={styles.employeeItems} primary={<Typography className={classes.listValues}>{cust_details_loading ? <Skeleton /> : rightDetail}</Typography>} />
                </Grid>
            </Grid>
        </>
    )
}

export default GridView