import React from 'react'
import './preapprovedloan.css'
import PreapprovedDataCard from './PreapprovedDataCard'
import PreapprovedChart from './PreapprovedChart'
import { getloanOffers, getloanOffersData } from '../../../../slice/DashboardSlice/loanOffersSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getcommonapidata } from '../../../../slice/commonslice'
import { useEffect } from 'react'
import { Skeleton } from 'antd'
import { useState } from 'react'
const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const date = new Date()

const PreapprovedLoan = () => {
    const [value, setValue] = useState(month[date.getMonth()]);
    const { isLoading, loanOfferssData } = useSelector(getloanOffersData)
    const { commonFilterStates } = useSelector(getcommonapidata)
    const dispatch = useDispatch();
    const postValue = {
        state: commonFilterStates,
        month: `${value === 'Select Month' ? '' : value}`
    }

    useEffect(() => {
        if (!loanOfferssData) {
            dispatch(getloanOffers(postValue))
        }
    }, [loanOfferssData])

    useEffect(() => {
        dispatch(getloanOffers(postValue))
    }, [commonFilterStates, value])

    return (
        <div className="card-wrap displayflexcol preapproved-card">
            <h3 className="chart-card-title">Pre-approved Loan Offers</h3>
            {
                isLoading || !loanOfferssData ?
                    <Skeleton active />
                    :
                    <PreapprovedDataCard cardData={loanOfferssData?.cardData} value={value} setValue={setValue} />

            }
            {
                isLoading || !loanOfferssData ?
                    <Skeleton active />
                    :
                    <PreapprovedChart chartData={loanOfferssData?.data} />
            }
        </div>
    )
}

export default React.memo(PreapprovedLoan)