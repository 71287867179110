import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { ApplicationContext } from './LoanApplication'

const Personal = () => {

  const applicationDetails = useContext(ApplicationContext)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Name:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {applicationDetails.name}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Email:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {applicationDetails.email}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Mobile:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {applicationDetails.mobile}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Date of Birth:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {applicationDetails.dateOfBirth}
          </Typography>
        </Grid>

        <Grid item md={5}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            Address:
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Typography fontSize={'16px'} variant="h6" gutterBottom>
            {applicationDetails.address}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Personal