
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getFees = createAsyncThunk('getFees', async () => {
    try {
        const url = `/console-web/total-fees`
        const response = await DataService.get(url)
        return response
    } catch (err) {
        return err.response
    }
})

export const getFeesByMonth = createAsyncThunk('getFeesByMonth', async (monthVal) => {
    try {
        const url = `/console-web/total-fees-month?month=${monthVal}`
        const response = await DataService.get(url)
        return response
    } catch (err) {
        return err.response
    }
})

const initialState = {
    isLoading: true,
    feesData: '',
    feesDataByMonth: '',
}

const feesSlice = createSlice({
    name: 'feesslice',
    initialState,
    extraReducers: {
        [getFees.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getFees.fulfilled]: (state, { payload }) => {
            return { ...state, feesData: payload?.data, isLoading: false }
        },
        [getFeesByMonth.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getFeesByMonth.fulfilled]: (state, { payload }) => {
            return { ...state, feesDataByMonth: payload?.data, isLoading: false }
        },
    }
})
export const getFeesData = (state) => state.feesapi;
export default feesSlice.reducer