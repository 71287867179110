const regionHead = [
    { id: 'sl_no', label: '#' },
    { id: 'state', label: 'State' },
    { id: 'city', label: 'City' },
    // { id: 'name', label: 'Name' },
    { id: 'action', label: "Action" }
]

const fosHead = [
    { id: 'sl_no', label: '#' },
    { id: 'fosid', label: 'FOS Id' },
    { id: 'name', label: 'Name' },
    // { id: 'email', label: 'Email' },
    { id: 'region', label: 'Region' },
    { id: 'mobile', label: 'Mobile' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
]

const customerHead = [
    { id: 'srno', label: '#' },
    { id: 'code', label: 'RT/DT Code' },
    { id: 'name', label: 'Name' },
    { id: 'mobile', label: 'Mobile' },
    { id: 'email', label: 'Email' },
    { id: 'region', label: 'Region' },
    { id: 'view_details', label: 'View Details' },
]

const salesHead = [
    { id: 'sr_no', label: '#' },
    { id: 'file_name', label: 'Filename' },
    { id: 'created_on', label: 'Created On' },
    { id: 'status', label: 'Status' },
]

const preApprovedHead = [
    { id: 'srno', label: '#' },
    { id: 'code', label: 'RT/DT Code' },
    { id: 'name', label: 'Name' },
    // { id: 'mobile', label: 'Mobile' },
    { id: 'amount', label: 'Amount' },
    { id: 'loanname', label: 'Loan Product' },
    { id: 'region', label: 'Region' },
    { id: 'created_on', label: 'Created On' },
    { id: 'fosassigned', label: 'FOS Assigned' },
    { id: 'viewdetails', label: 'View Details' },
]

const loanDisbursedHead = [
    { id: "slno", label: "#" },
    { id: 'code', label: 'RT/DT Code' },
    { id: "Acno", label: "Loan A/C No" },
    { id: "name", label: "Name" },
    { id: "loanamt", label: "Loan Product" },
    { id: "amountSanctioned", label: "Amount" },
    { id: "tenure", label: "Tenure" },
    { id: "interestRate", label: "Interest Rate" },
    { id: "trancheDetails", label: "Tranche Details" },
    { id: "repayments", label: "Repayments" },
]

const loanSanctionedHead = [
    { id: 'slno', label: '#' },
    { id: 'code', label: 'RT/DT Code' },
    { id: 'ac_no', label: 'Loan A/C No' },
    { id: 'emp_name', label: 'Name' },
    { id: 'loan_name', label: 'Loan Product' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'pcpl', label: 'Principal' },
    { id: 'int_rate', label: 'Interest Rate' },
    { id: 'tranc_details', label: 'Tranche Details' },
    { id: 'loan_details', label: 'Loan Details' },
    { id: 'view_details', label: 'Documents' }
]

const loanHead = [
    { id: 'slno', label: '#' },
    { id: 'loan_name', label: 'Loan Product' },
    { id: 'loan_short_name', label: 'Short Name' },
    { id: 'max_principal', label: 'Max Principal' },
    { id: 'int_rate', label: 'Int Rate' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'view_details', label: 'View Details' }
]

const trancheModal = [
    { id: "slno", label: "#" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "trancheamt", label: "Tranche Amount" },
    { id: "loanamt", label: "Amount" },
    { id: "disbursalDate", label: "Disbursal Date" },
    { id: "paybackDate", label: "Payback Date" },
    { id: 'status', label: "Status" },
]

const repaymentModal = [
    { id: "srno", label: "#" },
    { id: "trnid", label: "Tranche Id" },
    { id: "amt", label: "Amount" },
    { id: "pcpl", label: "Principal" },
    { id: "int", label: "Interest" },
    { id: "penalty", label: "Penalty" },
    { id: "trn_date", label: "Transaction Date" },
]

const algRejected = [
    { id: "slno", label: "#" },
    { id: "code", label: "RT/DT Code" },
    { id: "name", label: "Name" },
    // { id: "mobile", label: "Mobile" },
    { id: "createdOn", label: "Created On" },
]

const repaymentHead = [
    { id: "slno", label: "#" },
    { id: "trn_id", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "amount", label: "Amount" },
    { id: "bank_ref_num", label: "Bank Ref Num" },
    { id: "principal_portion", label: "Principal" },
    { id: "interest_portion", label: "Interest" },
    { id: "penalty", label: "Penalty" },
    { id: "transaction_date", label: "Transaction Date" },
]

const loanAccDetailsHead = [
    { id: 'slno', label: '#' },
    { id: 'acno', label: 'A/C No' },
    { id: 'extid', label: 'External Id' },
    { id: 'loan_pdt', label: 'Loan Product' },
    { id: 'loan_amt', label: 'Loan Amount' },
    { id: 'amt_paid', label: 'Amount Paid' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
]

const loanStatementHead = [
    { id: 'disb_p', label: 'Disb. Princi.' },
    { id: 'paid_p', label: 'Paid Princi.' },
    { id: 'outst_p', label: 'Outst. Princi.' },
    { id: 'overd_p', label: 'Overdue Princi.' },
    { id: 'int_c', label: 'Int. Charged' },
    { id: 'int_p', label: 'Int. Paid' },
    { id: 'int_waived', label: 'Int. Waived' },
    { id: 'int_outst', label: 'Int. Outstanding' },
    { id: 'int_overd', label: 'Int. Overdue' },
    { id: 'charges', label: 'Charges Added (Int. + Charge)' },
    { id: 'exp_rept', label: 'Total Expected Repayment' },
]

const loanDefaultedHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheId", label: "Tranche ID" },
    { id: "ac_no", label: "Loan A/C No" },
    { id: "cstmrname", label: "Name" },
    { id: "loanname", label: "Loan Name" },
    { id: "amt", label: "Amount" },
    // { id: "intrate", label: "Interest Rate" },
    // { id: "tenure", label: "Tenure" },
    { id: "dpd", label: "DPD" },
    { id: "default_dt", label: "Defaulted Date" },
    { id: "status", label: "Status" },
]

const loanClosedHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: 'ac_no', label: 'Loan A/C No' },
    { id: "cstmrname", label: "Name" },
    { id: "loanname", label: "Loan Name" },
    { id: "intrate", label: "Interest Rate" },
    { id: "tenure", label: "Tenure" },
    { id: "closed_dt", label: "Closed Date" },
    { id: "status", label: "Status" },
]

const loanApplicationHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "name", label: "Name" },
    { id: "loanproduct", label: "Loan Product" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: "tenure", label: "Tenure" },
    { id: "created_on", label: "Created On" },
    { id: "details", label: "Details" }
]

const upcomingRepaymentsHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "tranamt", label: "Tranche Amount" },
    { id: "loanamt", label: "Loan Amount" },
    // { id: "disbdate", label: "Disbursal Date" },
    { id: "payable", label: "Payable" },
    { id: "paybdate", label: "Payback Date" },
    { id: "status", label: "Status" },
    { id: "actions", label: "Actions" }
]

const tranchesListHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "tranamt", label: "Tranche Amount" },
    { id: "bank_ref_num", label: "Bank Ref Num" },
    { id: "loanamt", label: "Loan Amount" },
    { id: "paybdate", label: "Payback Date" },
    { id: "created_date", label: "Created Date" },
    { id: "status", label: "Status" },
    { id: "reasons", label: "Failed Reason" }
]

const rejectedApplicationHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "name", label: "Name" },
    { id: 'fosname', label: 'Fos Name' },
    { id: "loanproduct", label: "Loan Product" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: "tenure", label: "Tenure" },
    { id: "created_on", label: "Created On" },
    { id: "rej_docs", label: "Rejected Docs" }
]

const IncompleteApplicationsHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'customername', label: 'Name' },
    { id: 'fosname', label: 'Fos Name' },
    { id: 'phone', label: 'Phone' },
    // { id: 'loansn', label: 'Email' },
    { id: 'loanproduct', label: 'Loan Product' },
    { id: 'amtoffer', label: 'Amount' },
    { id: 'tenure', label: 'Tenure' },
    { id: "created_on", label: "Created On" },
    { id: 'status', label: 'Status' },
]

const expiryList = [
    { id: "slno", label: "#" },
    { id: "code", label: "RT/DT Code" },
    { id: "name", label: "Name" },
    { id: "approvedamount", label: "Approved Amount" },
    { id: "expirydate", label: "Date of Expiry" },
]
const creditLineInuse = [
    { id: "slno", label: "#" },
    { id: "code", label: "RT/DT Code" },
    { id: "name", label: "Name" },
    { id: "sactionedamount", label: "Sanctioned Amount" },
    { id: "creditlineinuse", label: "Creditline In-use" },
    { id: "activetranches", label: "Active No.Of Tranches" },
]
const inProgressRepayments = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "DT/RT Code" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "trancheamt", label: "Tranche Amount" },
    { id: 'loan_amt', label: 'Loan Amount' },
    { id: "payable", label: "Payable" },    
    { id: "paybackon", label: "Payback On" },
]

const HeadCells = {
    regionHead,
    fosHead,
    customerHead,
    salesHead,
    preApprovedHead,
    loanDisbursedHead,
    loanSanctionedHead,
    loanHead,
    trancheModal,
    repaymentModal,
    algRejected,
    repaymentHead,
    loanAccDetailsHead,
    loanStatementHead,
    loanDefaultedHead,
    loanClosedHead,
    loanApplicationHead,
    upcomingRepaymentsHead,
    tranchesListHead,
    rejectedApplicationHead,
    IncompleteApplicationsHead,
    expiryList,
    creditLineInuse,
    inProgressRepayments
}

export default HeadCells;