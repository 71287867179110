import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import DataService from "../services/DataService"


export const getallstates = createAsyncThunk(
    'getallstates',
    async () => {
        try {
            const url = `/console-web/states`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })

export const getallmonth = createAsyncThunk(
    'getallmonth',
    async () => {
        try {
            const url = `/console-web/months`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    updateUserHeaderTitle: '',
    allStates: '',
    allMonth: '',
    commonFilterStates: [],

}

const commonSlice = createSlice({
    name: 'commonslice',
    initialState,
    reducers: {
        updateUserHeaderTitle: (state, { payload }) => {
            state.updateUserHeaderTitle = payload
        },
        updatecommonFilterStates: (state, { payload }) => {
            state.commonFilterStates = payload
        },
    },
    extraReducers: {
        [getallstates.fulfilled]: (state, { payload }) => {
            return { ...state, allStates: payload?.data?.data }
        },
        [getallmonth.fulfilled]: (state, { payload }) => {
            return { ...state, allMonth: payload?.data?.monthList }
        },
    }
})

export const { updateUserHeaderTitle } = commonSlice.actions
export const { updatecommonFilterStates } = commonSlice.actions
export const getupdateuserheadertitle = (state) => state.commonapi.updateUserHeaderTitle;
export const getcommonapidata = (state) => state.commonapi;
export default commonSlice.reducer
