import React, { useState, useEffect } from 'react'
import { Paper, TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import moment from 'moment';
import { DatePicker } from 'antd';
import { EXPIRY_LIST_HEADER, EXPIRY_LIST_SUB_HEADER } from '../../utils/pageHeadings';
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import DownloadIcon from '@mui/icons-material/Download';
import useExcelDownload from '../Sharedfeatures/useExcelDownload'
import { disableSixMonth } from '../../utils/disableDates';
import { getExpiryList, getExpiryListDataExcel } from '../../slice/expiryListSlice';
import dayjs from 'dayjs';

const ExpiryList = () => {
    const monthFormat = 'YYYY-MM';
    const nxtMonth = dayjs().add(1,'month').format(monthFormat)

    const [excelSheetData, setExcelSheetData] = useState([]);
    const classes = makemyStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [filterMonth, setFilterMonth] = useState(nxtMonth)

    const {expiryListData, totalCount, isLoading, expiryListDataExcel } = useSelector((store) => store.expirylistapi);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(expiryListData, HeadCells.expiryList, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFilterMonth(dateStrings)
    }

    useEffect(() => {
        const year = filterMonth.split('-')[0]
        const month = filterMonth.split('-')[1]
        const dates = {
            searchTerm: search,
            pageNo,
            month,
            year
        }
        dispatch(getExpiryList(dates))
        dispatch(getExpiryListDataExcel(dates));
        setShowPageNtn(true);
    }, [filterMonth, pageNo, search, dispatch])

    let serialNo = PAGE_SIZE * slNo;

    // const handleSearch = (value) => {
    //     setSearch(value)
    //     handleChange(null, 1)
    // }
    const excelData = useExcelDownload()

    useEffect(() => {
        let excelsheetdata = []
        expiryListDataExcel?.map(item => {
            excelsheetdata.push({                
                "RT/DT Code" : item.userid,
                "Name" : item.name,
                "Approved Amount" : item.amount,
                "Date of Expiry" : moment(item.agreement_end).format('DD MMMM YYYY'),
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [expiryListDataExcel])
    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader
                    header={EXPIRY_LIST_HEADER}
                    subHeader={EXPIRY_LIST_SUB_HEADER}
                />

                {/* <Box sx={{ height: '105px' }} /> */}
                <div style={{display:'flex', gap:'20px', alignItems:'flex-start', justifyContent:'end', flexWrap:'wrap'}} >
                    {/* <TextField
                        size="small"
                        className={classes.searchTextBar}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    /> */}
                    <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                        
                        <DatePicker defaultValue={dayjs(filterMonth, monthFormat)} onChange={onDateChange} format={monthFormat} className={classes.noExcelDatePicker} picker="month" disabledDate={disableSixMonth} allowClear={false} />
                        <Button className={classes.excelUploadButton}
                            onClick={() => excelData.generateExcelFile(excelSheetData, 'ExpiryList', 'expirylist.xlsx')}
                            endIcon={<DownloadIcon />}>Download Ledger
                        </Button>
                    </div>
                </div>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            align="center"
                                            className={classes.tableCell}
                                        >
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (expiryListData.length !== 0) ? (
                                            expiryListData && expiryListData
                                                .map((item, key) => {
                                                    key++;
                                                    serialNo++;
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.userid}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                            <TableCell className={classes.tableCell}>{item.amount}</TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                            {moment(item.agreement_end).format('DD MMMM YYYY')}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={8} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {
                    showPageNtn ? <TblPagination /> : ''
                }
            </Paper>
        </>
    )
}

export default ExpiryList