import * as XLSX from 'xlsx'

const useExcelDownload = () => {
    const generateExcelFile = (excelsheet, sheetname, filename) => {
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(excelsheet);

        XLSX.utils.book_append_sheet(wb, ws, sheetname);
        XLSX.writeFile(wb, filename);
    }
    return { generateExcelFile }
}

export default useExcelDownload


// const useExcelDownload = () => {

//     const generateExcelFile = (excelsheet, sheetname, filename) => {

//         console.log("EXCEL DATA", excelsheet);

//         const modifiedHeading = excelsheet[0].map(cell => cell.replace(/\b\w/g, l => l.toUpperCase()));
//         const modifiedData = [modifiedHeading, ...excelsheet.slice(1)];
//         const wb = XLSX.utils.book_new();
//         const ws = XLSX.utils.aoa_to_sheet(modifiedData);

//         XLSX.utils.book_append_sheet(wb, ws, sheetname);
//         XLSX.writeFile(wb, filename);

//     }

//     return { generateExcelFile }
// }

// export default useExcelDownload