import React from 'react'
import { Grid } from '@material-ui/core'
import './Dashboardstyles.css'

const GridView = (props) => {

    const {greeting, selectYear, datetime, intCard, sancChart, disbChart, defaultChart, loanOffersGen, repayments, trancheReq, disbRegionWise, regionGraphs } = props
    
    return (
        <>
            {/* Row 1 */}
            <Grid container className='grid-gap'>
                <Grid item sm={12} md={12} lg={12}>
                    {greeting}
                </Grid>
            </Grid>

            {/* Row 2 */}
            <Grid container className='grid-gap'>
                <Grid item sm={0} md={0} lg={4}>
                </Grid>

                <Grid item sm={0} md={6} lg={4}>
                    {selectYear}
                </Grid>

                <Grid item sm={12} md={6} lg={4}>
                    {datetime}
                </Grid>
            </Grid>

            {/* Row 3 */}
            <Grid container className='grid-gap'>
                <Grid item sm={12} md={12} lg={12}>
                    {intCard}
                </Grid>
            </Grid>

            {/* Row 4 */}
            <Grid container className='grid-gap-two'>
                <Grid item sm={12} md={6} lg={4}>
                    {sancChart}
                </Grid>

                <Grid item sm={12} md={6} lg={4}>
                    {disbChart}
                </Grid>

                <Grid item sm={12} md={6} lg={4}>
                    {defaultChart}
                </Grid>
            </Grid>

            {/* Row 5 */}
            <Grid container className='grid-gap-two'>
                <Grid item sm={12} md={6} lg={4}>
                    {loanOffersGen}
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    {repayments}
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    {trancheReq}
                </Grid>
            </Grid>

            {/* Row 6 */}
            <Grid container className='grid-gap'>
                <Grid item sm={12} md={12} lg={12}>
                    {disbRegionWise}
                </Grid>
            </Grid>

            {/* Row 7 */}
            <Grid container className='grid-gap'>
                <Grid item sm={12} md={12} lg={12}>
                    {regionGraphs}
                </Grid>
            </Grid>

            {/* Blank Space */}
            <Grid container className='grid-gap'>
                {/* For bottom spacing purpose. Don't remove this block */}
            </Grid>
        </>
    )
}

export default GridView