import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const uploadSalesData = createAsyncThunk(
    "uploadSalesData",
    async (formData, thunkAPI) => {
        try {
            const url = '/algorithm/sales-data-upload';
            const res = await DataService.filepost(url, formData);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

export const getSalesDataList = createAsyncThunk(
    "getSalesDataList",
    async ({ searchTerm, pageNumb, fromDate, toDate }, thunkAPI) => {
        try {
            const url = `/algorithm/sales-data-list?page=${pageNumb}&limit=10&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`;
            const res = await DataService.get(url)
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

//download SalesData excel
export const getSalesDataExcel = createAsyncThunk(
    "getSalesDataExcel",
    async (data, thunkAPI) => {
        const { searchTerm, pageNumb, fromDate, toDate, totalCount } = data;
        try {
            const url = searchTerm ? 
            `/algorithm/sales-data-list?page=1&limit=${totalCount}&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}` : 
            `/algorithm/sales-data-list?page=1&limit=${totalCount}&fromDate=${fromDate}&toDate=${toDate}`
            const res = await DataService.get(url)
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)

const salesdataSlice = createSlice({
    name: "salesdata",
    initialState: {
        salesData: {},
        requestId: '',
        salesDataList: [],
        salesDataExcel: [],
        isLoading: true,
    },
    extraReducers: {
        [getSalesDataList.pending]: (state) => {
            state.isLoading = true;
        },
        [getSalesDataList.fulfilled]: (state, action) => {
            state.salesDataList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
            state.isLoading = false;
        },
        [getSalesDataList.rejected]: (state) => {
            state.isLoading = false;
        },
        [getSalesDataExcel.fulfilled]: (state, action) => {
            state.salesDataExcel = action.payload.data;
        },
        [uploadSalesData.fulfilled]: (state, action) => {
            state.salesData = action.payload.data;
            const addeddata = state.salesData
            state.message = action.payload.message;
            state.success = action.payload.success;
            state.requestId = action.meta.requestId;
            let salesdatalist = state.salesDataList;
            salesdatalist.unshift(addeddata)
            state.salesDataList = salesdatalist;
        },
        [uploadSalesData.rejected]: (state, action) => {
        },
    },
});

const { reducer } = salesdataSlice;

export default reducer;