import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import './modalStyles.css'
import { BiCloudUpload } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import makemyStyles from '../Sharedfeatures/SharedStyles'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 450,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
};

const ImportModal = ({ open, onClose, title, readExcel, fileType, message, requestId, fileUploaded, fileUploadError }) => {

    const [files, setFiles] = useState()
    const [loader, setLoader] = useState(false)

    const handleUpload = (e) => {
        setFiles(e);
        setLoader(true);
    }
    const classes = makemyStyles()

    useEffect(() => {
        if (fileUploadError) {
            setLoader(false)
        }
    }, [fileUploadError])

    useEffect(() => {
        if (!fileType) {
            setLoader(false)
        }
    }, [fileType])



    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='modalHeader'>
                        {title}
                    </Typography>
                    <IconButton style={{ position: 'absolute', right: '10px', top: '0' }} onClick={onClose}>
                        <AiOutlineClose />
                    </IconButton>
                    <Typography id="modal-modal-description" className='modalSubHeader' style={{ color: 'red' }}>
                        Only .xls and .xlsx files are allowed
                    </Typography>
                    <div className='file-upload-wrap'>
                        <input id="file-upload" type="file" onChange={handleUpload} />
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <div className={`in-box ${fileUploaded ? 'in-box-success' : (!fileType || fileUploadError ) ? 'in-box-error' :''}`}>
                                {
                                    fileUploaded ?
                                    <Typography id="modal-modal-description" className='modalSubHeader'>{message}</Typography>
                                    :
                                    !fileType ?
                                    <Typography id="modal-modal-description" className='modalSubHeader'>File does not support. You must use .xls or .xlsx files only</Typography>
                                    :
                                    fileUploadError ?
                                    <Typography id="modal-modal-description" className='modalSubHeader'>{message}</Typography>
                                    :
                                    loader ? 
                                        <>
                                            <CircularProgress />
                                            <Typography id="modal-modal-description" className='modalSubHeader'>Progressing, Click on the button to upload </Typography>
                                        </>
                                    :
                                        <>
                                            <BiCloudUpload className='uploadIcon' />
                                            <Typography id="modal-modal-description" className='modalSubHeader'>Browse your excel files here </Typography>
                                        </>
                                }
                            </div>
                        </label>
                    </div>
                    <div className={classes.btnwrap}>
                        <button type="submit"
                            variant="outlined"
                            className={classes.newButton}
                            // style={{ position: 'relative', left: '250px', top: '385px' }}
                            onClick={() => readExcel(files)}
                            hidden={
                                fileUploaded ? true : false
                            }
                        >
                            Upload Data
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ImportModal