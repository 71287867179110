import React, { useEffect } from "react";
import GraphHeader from "./GraphHeader";
import { PieChart, Pie, Cell, LabelList, ResponsiveContainer } from "recharts";
import {
  TRANCHE_REQ_HEADER,
  TRANCHE_REQ_SUBHEADER,
} from "../../utils/graphHeadings";
import { useSelector, useDispatch } from "react-redux";
import "./Dashboardstyles.css";
import { getTrancheRequestData } from "../../slice/dashboard";

const TrancheRequestChart = () => {
  const { tranche_request_count, tranche_request_volume } = useSelector(
    (store) => store.dashboard
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrancheRequestData());
  }, []);

  return (
    <div className="second-row-card">
      <GraphHeader
        header={TRANCHE_REQ_HEADER}
        subheader={tranche_request_volume}
      />
      <article className="tranche-requests-day">
        <h3>Today</h3>
      </article>

      <ResponsiveContainer width="90%" height="65%">
        <div className="circle">
          <div className="circle-text">{tranche_request_count} Request</div>
        </div>
      </ResponsiveContainer>
    </div>
  );
};

export default TrancheRequestChart;
