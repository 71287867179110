import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
  loginalert: {
    width: '478px',
    height: '50px',
  },
  toolBar: {
    top: 0,
  },
  pageContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    marginLeft: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuPaper: {
    maxHeight: 100
  },
  errorText: {
    marginLeft: '15px',
    color: "red"
  },
  regionselect: {
    height: '250px'
  },
  note: {
    color: "red"
  }
}));