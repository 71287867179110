import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getRegionList = createAsyncThunk(
  "getregion",
  async ({ searchTerm, pageNum }) => {
    const url = `/region?page=${pageNum}&limit=10&searchqry=${searchTerm}`;
    const res = await DataService.get(url);
    return res.data;
  }
);

export const getRegionDataByID = createAsyncThunk(
  "getRegionByID",
  async (id) => {
    const url = `/region/${id}`;
    const res = await DataService.get(url);
    return res.data.data;
  }
);

export const getStateList = createAsyncThunk(
  "getState",
  async () => {
    const url = `/region/states`
    const res = await DataService.get(url);
    return res.data.data;
  }
);

export const getCityList = createAsyncThunk(
  "getCities",
  async (state_id) => {
    // console.log("PASSEDCITYID", state_id)
    const url = `/region/cities/${state_id}`
    const res = await DataService.get(url);
    // console.log("City act", res)
    return res.data.data;
  }
);

export const postRegion = createAsyncThunk(
  "postregion",
  async (value, thunkAPI) => {
    try {
      const url = '/region';
      const { ...data } = value;
      const res = await DataService.post(url, data);
      // console.log("RESPONSE", res)
      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
)

export const updateRegion = createAsyncThunk(
  "updateRegion",
  async (value, thunkAPI) => {
    try {
      const { id, ...data } = value;
      const url = `/region/${id}`;
      const res = await DataService.put(url, data);
      // console.log("RESPONSE", res)
      return res.data
    } catch (error) {
      // const message = (error.response && error.response.data && error.response.data.message) || error.toString();
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
)

export const reset_state = createAsyncThunk(
  "reset_state",
  async () => {
    return;
  }
)

const initialState = {
  regionList: [],
  stateList: [],
  cityList: [],
  regionData: [],
  errorName: [],
  errorMessage: '',
  status: '',
  loading: false,
  message: '',
  isLoading: true
};

const regionSlice = createSlice({
  name: "region",
  initialState,
  extraReducers: {
    [getRegionList.pending]: (state) => {
      state.isLoading = true;
    },
    [getRegionList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.totalItmes
      state.regionList = action.payload.data;
      state.isLoading = false;
    },
    [getRegionList.rejected]: (state) => {
      state.isLoading = false;
    },
    [getRegionDataByID.fulfilled]: (state, action) => {
      console.log("abc =>", action)
      state.regionData = action.payload
    },
    [getStateList.fulfilled]: (state, action) => {
      // console.log("States list reducer", action)
      state.loading = false;
      state.stateList = action.payload;
    },
    [getCityList.fulfilled]: (state, action) => {
      // console.log("City list reducer", action)
      state.loading = false;
      state.cityList = action.payload[0].cityName;
    },
    [postRegion.fulfilled]: (state, action) => {
      state.loading = true;
      state.status = action.meta.requestStatus
      state.regionData = action.payload.data
      state.requestId = action.meta.requestId
      state.message = action.payload.message
    },
    [postRegion.rejected]: (state, error) => {
      state.loading = false;
      state.errorName = error.error.name
      state.errorMessage = error.payload
      state.status = error.meta.requestStatus
      state.requestId = error.meta.requestId
    },
    [updateRegion.fulfilled]: (state, action) => {
      state.loading = true;
      state.status = action.meta.requestStatus
      state.regionData = action.payload.data
      state.requestId = action.meta.requestId
      state.message = action.payload.message
    },
    [updateRegion.rejected]: (state, error) => {
      state.loading = false;
      state.errorMessage = error.payload
      state.status = error.meta.requestStatus
      state.requestId = error.meta.requestId
    },
    [reset_state.fulfilled]: (state, action) => {
      state.errorMessage = '';
      state.status = '';
      state.message = '';
      state.regionList = '';
    },
  },
});

const { reducer } = regionSlice;

export default reducer;