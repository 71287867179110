import { makeStyles, createStyles } from '@material-ui/core'

export default makeStyles((theme) => createStyles({
    container: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginTop: '70px',
    },
    typography: {
        color: '#963460', 
        fontWeight: 'bold', 
        marginTop: '50px',
    },
    tableLeft: {
        maxWidth: '30%',
    },
    tableRight: {
        maxWidth: '30%',
        border: '2px solid #1A0045',
    },
    table:{
        border: '2px solid #1A0045'
    }

}))