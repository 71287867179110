import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanClosedList = createAsyncThunk(
    "loanclosedlisting",
    async ({ pageNo, search, fromDate, toDate }) => {
        const url = `loans/closed-loans-list?page=${pageNo}&limit=10&searchqry=${search}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await DataService.get(url);
        return res.data;
    }
);


//download loan closed excel
export const getLoanClosedExcel = createAsyncThunk(
    "loanclosedExcel",
    async ({ pageNo, search, fromDate, toDate, totalCount }) => {
        const url = search ? `loans/closed-loans-list?page=${pageNo}&limit=10&searchqry=${search}&fromDate=${fromDate}&toDate=${toDate}`: `loans/closed-loans-list?page=1&limit=${totalCount}`;
        const res = await DataService.get(url);
        return res.data;
    }
);
// export const generateExcel = createAsyncThunk(
//     "generate excel sheet",
//     async ({ fromDate, toDate }) => {
//         const url = `excel/closedloanslist?fromDate=${fromDate}&toDate=${toDate}`;
//         const res = await DataService.get(url);
//         return res.data;
//     }
// );

const initialState = {
    loanClosedList: [],
    loanClosedExcel: [],
    totalCount: '',
    isLoading: true,
    loanClosedExcelSheet: []
}

const loanClosedSlice = createSlice({
    name: 'loanClosedSlice',
    initialState,
    extraReducers: {
        [getLoanClosedList.pending]: (state) => {
            state.isLoading = true
        },
        [getLoanClosedList.fulfilled]: (state, action) => {
            state.loanClosedList = action.payload.data;
            state.totalCount = action.payload.totalItmes
            state.isLoading = false
        },
        [getLoanClosedList.rejected]: (state) => {
            state.isLoading = false
        },
        [getLoanClosedExcel.fulfilled]: (state, action) => {
            state.loanClosedExcel = action.payload.data;
        },
        // [generateExcel.fulfilled]: (state, action) => {
        //     state.loanClosedExcelSheet = action.payload.data;
        // },
    }
})

export default loanClosedSlice.reducer