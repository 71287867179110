import React, { useState, useEffect } from 'react'
import { Paper, TableBody, TableRow, TableCell, Tooltip, IconButton, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { BsSearch } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import HeadCells from "../Common/HeadCells";
import { getIncompleteApplicationList } from '../../slice/loanapplications';
import moment from 'moment';

const IncompleteApplications = () => {

    const classes = makemyStyles();
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);

    const { incompApplications, totalCount, isLoading } = useSelector((store) => store.loanApplication);

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(incompApplications, HeadCells.IncompleteApplicationsHead, totalCount);

    useEffect(() => {
        const data = {
            pageNum: pageNo,
            searchQry: search,
        };
        dispatch(getIncompleteApplicationList(data))
        setShowPageNtn(true)
    }, [dispatch, pageNo, search, totalCount])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    return (
        <div style={{display:'flex', gap:'15px', justifyContent:'space-between', flexDirection:'column'}} >
            <TextField
                size="small"
                className={classes.preAppSearchTextBar}
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                id="standard-bare"
                autoComplete="off"
                variant="outlined"
                placeholder="Search Name or RT/DT Code"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <BsSearch className={classes.searchIconColour} />
                        </IconButton>
                    ),
                }}
            />

            <Paper className={classes.tableBoxShadow}>
                <TblContainer>
                    <TblHead />
                    {
                        isLoading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        align="center"
                                        className={classes.tableCell}
                                    >
                                        <ProgressSpinner />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {
                                    incompApplications.length !== 0 ? (
                                        incompApplications.map((item, key) => {
                                            key++;
                                            serialNo++;
                                            return (
                                                <TableRow key={item.id.toString()}>
                                                    <TableCell className={classes.tableCell}>
                                                        {serialNo}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.userid}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.fos.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.mobile}
                                                    </TableCell>
                                                    {/* <TableCell className={classes.tableCell}>
                                                        {item.email}
                                                    </TableCell> */}
                                                    <TableCell className={classes.tableCell}>
                                                        {item.ShortName}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.amount}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.tenure}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {moment(item.created_on).format("DD MMMM YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.loanapproval.currentStatus}
                                                        {/* (item.loanapproval.currentStatus == "Edited-Offer-Accepted") ? "Edited Offer Accepted" :
                                                                (item.loanapproval.currentStatus == "Documentation-Step3-Completed") ? "Step 3 Completed" :
                                                                    (item.loanapproval.currentStatus == "Documentation-Step2-Completed") ? "Step 2 Completed" :
                                                                        (item.loanapproval.currentStatus == "Documentation-Step1-Completed") ? "Step 1 Completed" :
                                                                            (item.loanapproval.currentStatus == "Offer-edited") ? "Offer Edited" :
                                                                            (item.loanapproval.currentStatus == "Offer-Accepted") ? "Offer Accepted" : 
                                                                                "" */}

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={8}
                                                align="center"
                                            >
                                                No Data to Show
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        )
                    }
                </TblContainer>
            </Paper>
            {showPageNtn ? <TblPagination /> : ""}
        </div>
    )
}

export default IncompleteApplications