import React, {useEffect} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import './Dashboardstyles.css'
import GraphHeader from './GraphHeader';
import {getSanctionedData} from "../../slice/dashboard"
import { LOAN_SANCTIONED_HEADER, LOAN_SANCTIONED_SUBHEADER } from '../../utils/graphHeadings';
import { useDispatch, useSelector } from 'react-redux';
import useYAxisFormatter from '../Sharedfeatures/useYAxisFormatter';
import { Card } from '@material-ui/core';

const SanctionedChart = ({year}) => {
  const dispatch = useDispatch();
  useEffect (() => {
    dispatch(getSanctionedData(year));
  },[year, dispatch])
  const { loanSanctioned, loanSanctioned_totalVolume } = useSelector((store) => store.dashboard)
  const total_Volume =  loanSanctioned_totalVolume;
  
  let data = [];
  loanSanctioned.map((item) => {
    let cdata = {
      name: item.month.substring(0, 3),
      value: Number(item.amount),
    };
    data.push(cdata);
  });
  const { dataFormater } = useYAxisFormatter()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <Card style={{ padding: '7px 12px' }}>
            <p>{`${label} : ₹ ${payload[0].value}`}</p>
          </Card>
        </div>
      );
    }

    return null;
  }

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
          fontSize="12"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className='second-row-card'>

      <GraphHeader header={LOAN_SANCTIONED_HEADER} subheader={Number(Number(total_Volume).toFixed(2)).toLocaleString()} />

      <ResponsiveContainer width="90%" height="65%">
        <BarChart
          width={580}
          height={180}
          data={data}
        >
          <XAxis dataKey="name" axisLine={false}
          />
          <YAxis axisLine={false} tickFormatter={dataFormater} />
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="value" fill="#9E00FF" radius={8} barSize={10} barCategoryGap={10} background={{ fill: "#eee" }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SanctionedChart