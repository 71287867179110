import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getCreditLine = createAsyncThunk(
    'getCreditLine',
    async (data) => {
        const {searchTerm, pageNo } = data;
        try {
            const url = `customers/credit-in-use?searchqry=${searchTerm}&page=${pageNo}&limit=10`
            const response = await DataService.get(url)
            return response
        } catch (err) {
            return err.response
        }
    })
export const getCreditLineDataExcel = createAsyncThunk(
    "getCreditLineDataExcel",
    async (data, thunkAPI) => {
        const {searchTerm,pageNo } = data;
        try {
            const url = `customers/credit-in-use?searchqry=${searchTerm}&page=${pageNo}&limit=0`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
const initialState = {
    isLoading: true,
    creditLineInuseData: [],
    totalCount: '',
    creditLineInuseDataExcel:[]
}

const creditLineInuseSlice = createSlice({
    name: 'creditlineinuseslice',
    initialState,
    extraReducers: {
        [getCreditLine.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getCreditLine.fulfilled]: (state, { payload }) => {
            return { ...state, creditLineInuseData: payload?.data?.data,totalCount :payload?.data?.totalItmes, isLoading: false, }
        },
        [getCreditLine.rejected]: (state) => {
            return { ...state, isLoading: false }
        },
        [getCreditLineDataExcel.fulfilled]: (state, {payload}) => {
            return { ...state, creditLineInuseDataExcel:payload?.data }
        },
    }
})
export default creditLineInuseSlice.reducer