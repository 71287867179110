import React, { useState } from 'react'
import useStyles from './PasswordStyleSheet'
import { Button, Typography } from '@mui/material'
import { useForm, Form } from '../Sharedfeatures/useForm'
import Controls from "../Controls/Controls";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../slice/forgotpassword';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const initialValues = {
    email: '',
    role: 'superadmin'
}

const ForgotPassword = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const [alert, setAlert] = useState(false)

    const { message, success } = useSelector((store) => store.forgotPassword)

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("email" in fieldValues) {
            (temp.email = /$^|.+@.+..+/.test(fieldValues.email) ? "" : "Email is not valid.") || (temp.email = fieldValues.email ? "" : "This field is required.");
        }

        setErrors({
            ...temp,
        });

        if (fieldValues == values)
            return Object.values(temp).every((x) => x == "");
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate())
            dispatch(forgotPassword(values))
            .then((res) => {
                setAlert(true)
                resetForm()
                const timer = setTimeout(() => {
                    setAlert(false);
                    return () => clearTimeout(timer);
                }, 3000)
            })
            .catch((err) => {

            })
    }

    return (
        <section className={styles.parentContainer}>
            <div className={styles.passwordContainer}>
                <div className={styles.innerPasswordContainer}>

                    <div className={styles.headerParent}>
                        <h2 className={styles.header}>Forgot Password</h2>
                    </div>

                    <div className={styles.formContainer}>
                        <div className={styles.formInfoParent}>
                            <p>No worries! Just enter your email and we'll send you a password reset link.</p>
                        </div>

                        {
                            alert && (
                                <Alert severity="info" sx={{ mt: 1 }}>
                                    {message}
                                </Alert>
                            )
                        }

                        <Form onSubmit={handleSubmit}>
                            <Controls.Input
                                name="email"
                                label="Enter Your Email"
                                className={styles.textField}
                                onChange={handleInputChange}
                                value={values.email}
                                error={errors.email}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                className={styles.submitButton}
                                type="submit"
                            >
                                Send Recovery Email
                            </Button>
                        </Form>
                        <Typography variant="body2" align="center">
                            <Link to='/'>
                                Just remembered your password? Go Back
                            </Link>
                        </Typography>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default ForgotPassword