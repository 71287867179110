import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatRupees } from '../../../../utils/formatRupees';
import MonthSelectBox from '../../Common/MonthSelectBox';


const CreditLineDataCard = ({ cardData, value, setValue }) => {
    return (
        <div className="data-card-wrap">
            <MonthSelectBox value={value} setValue={setValue} />
            <div className="card sanctioned">
                <div className="displayflex">
                    <h4 className="card-title">Active Credit Line</h4>
                    <h3 className="card-title"><CurrencyRupeeIcon className='rupee' />{formatRupees(cardData?.active_credit_line)}</h3>
                </div>
            </div>

            <div className="card active-credit">
                <div className="displayflex">
                    <h4 className="card-title">Credit Line in use</h4>
                    <h3 className="card-title"><CurrencyRupeeIcon className='rupee' />{formatRupees(cardData?.credit_line_use)}</h3>
                </div>
                <p className="card-sub-title">{cardData?.percentage}% of Active Credit Line</p>
            </div>
        </div>
    )
}

export default CreditLineDataCard