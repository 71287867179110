import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getloanOffers = createAsyncThunk(
    'getloanOffers',
     async (value)=>{
    try{
        const url = `/console-web/loanoffer-generated-monthwise`
        const response = await DataService.post(url,value)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    isLoading : true,
    loanOfferssData : '',
}

const loanOffersSlice = createSlice({
    name : 'loanOffersslice',
    initialState,
    extraReducers:{
        [getloanOffers.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getloanOffers.fulfilled]:(state,{payload})=>{
            return {...state, loanOfferssData:payload?.data, isLoading:false}
        },
    }
})

export const getloanOffersData = (state) => state.loanoffersapi;
export default loanOffersSlice.reducer