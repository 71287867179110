import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import useYAxisFormatter from "../Sharedfeatures/useYAxisFormatter";

const MonthWise = () => {
  const { monthlyData } = useSelector((state) => state.dashboard);
  const { dataFormater } = useYAxisFormatter();

  let data = [];
  monthlyData.map((item) => {
    let cdata = {
      name: item.month.substring(0, 3),
      amount: Number(item.amount),
      count: item.count,
    };
    data.push(cdata);
  });
  // const data = [
  //     {
  //         name: "Jan",
  //         value: 4000,
  //         count: 2400,
  //         amt: 2400
  //     },
  //     {
  //         name: "Feb",
  //         value: 3000,
  //         count: 1398,
  //         amt: 2210
  //     },
  //     {
  //         name: "Mar",
  //         value: 2000,
  //         count: 9800,
  //         amt: 2290
  //     },
  //     {
  //         name: "Apr",
  //         value: 2780,
  //         count: 3908,
  //         amt: 2000
  //     },
  //     {
  //         name: "May",
  //         value: 1890,
  //         count: 4800,
  //         amt: 2181
  //     },
  //     {
  //         name: "Jun",
  //         value: 2390,
  //         count: 3800,
  //         amt: 2500
  //     },
  //     {
  //         name: "Jul",
  //         value: 3490,
  //         count: 4300,
  //         amt: 2100
  //     },
  //     {
  //         name: "Aug",
  //         value: 3490,
  //         count: 4300,
  //         amt: 2100
  //     },
  //     {
  //         name: "Sep",
  //         value: 3490,
  //         count: 4300,
  //         amt: 2100
  //     },
  //     {
  //         name: "Oct",
  //         value: 4490,
  //         count: 4300,
  //         amt: 2100
  //     },
  //     {
  //         name: "Nov",
  //         value: 3490,
  //         count: 4300,
  //         amt: 2100
  //     },
  //     {
  //         name: "Dec",
  //         value: 2000,
  //         count: 4300,
  //         amt: 2100
  //     }
  // ];
  return (
    <div className="loan-disbursed-region-wise">
      <ResponsiveContainer width="95%" height="95%">
        <BarChart
          width={500}
          height={500}
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: -8,
          }}
          // barSize={60}
        >
          <XAxis dataKey="name" axisLine={false} />
          <YAxis axisLine={false} tickFormatter={dataFormater} />
          <Tooltip
            cursor={false}
            // content={<CustomTooltip />}
          />
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <Bar dataKey="count" fill="#7C4DFF" />
          <Bar dataKey="amount" fill="#7C4DFF" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthWise;
