import React from 'react'
import { Grid } from '@material-ui/core'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import { useSelector } from 'react-redux';
import LoanDetailsHeaderCell from './LoanDetailsHeaderCell';

const LoandetailsHeader = () => {
    
    const { loanDetails } = useSelector((state) => state.preApprovedLoanOffer)

    return (
        <>
            <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>

                <LoanDetailsHeaderCell title="Name:" details={loanDetails.name} />

                <LoanDetailsHeaderCell title="Email:" details={loanDetails.email} />

                <LoanDetailsHeaderCell title="Mobile:" details={loanDetails.mobile} />
                
            </Grid>

            <hr />
        </>
    )
}

export default LoandetailsHeader