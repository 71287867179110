export const DASHBOARD = '/admin/dashboard'
export const REGION_LIST = '/admin/regionlist';
export const ADD_REGION = '/admin/addregion'
export const LOAN_DETAILS ='/admin/loandetails/';
export const FOS_LIST = '/admin/fos';
export const ADD_FOS = '/admin/addfos';
export const EDIT_FOS = '/admin/editfos';
export const CUST_LIST = '/admin/customerlist'
export const ADD_CUST = '/admin/addcustomer'
export const CUST_DETAILS ='/admin/customerdetails/';
export const PRE_APP_LIST = '/admin/preapprovedloanoffer'
export const LOAN_SANCTIONED_LIST = '/admin/loansanctioned'
export const LOAN_GEN_LIST = '/admin/loangenerated'
export const SALES_DATA_URL = '/public/docs/salesdata_sample.xlsx'
export const CUSTOMER_DATA_URL = '/public/docs/samplefile.xlsx'
export const LOAN_SANCT_URL = '/admin/loansanctioned'
export const LOAN_PRODUCT_URL = '/admin/loanproducts'