import React from 'react'

const SuccessIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
            <circle cx="69.4399" cy="68.9189" r="67.9561" fill="white" stroke="#00C47D"/>
            <path d="M61.5035 94.715C60.3129 94.715 59.5192 94.3182 58.7254 93.5244L30.944 65.7431C29.3565 64.1556 29.3565 61.7742 30.944 60.1867C32.5316 58.5992 34.9128 58.5992 36.5003 60.1867L64.2816 87.9681C65.8692 89.5556 65.8692 91.9369 64.2816 93.5244C63.4879 94.3182 62.6942 94.715 61.5035 94.715Z" fill="#00C47D"/>
            <path d="M61.5034 94.717C60.3128 94.717 59.5191 94.3202 58.7253 93.5265C57.1378 91.9389 57.1378 89.5576 58.7253 87.9701L102.382 44.3137C103.969 42.7262 106.35 42.7262 107.938 44.3137C109.525 45.9012 109.525 48.2825 107.938 49.87L64.2815 93.5265C63.4878 94.3202 62.694 94.717 61.5034 94.717Z" fill="#00C47D"/>
        </svg>
    )
}

export default SuccessIcon