import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useForm, Form } from "../Sharedfeatures/useForm";
import Controls from "../Controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addEmployee,
  getCustomerTypes,
  resetstate,
  getRegionList,
} from "../../slice/employee";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import { CUST_LIST } from "../../utils/links";
import {
  ADD_CUST_HEADER,
  ADD_CUST_SUBHEADER,
  BACK_BUTTON,
} from "../../utils/pageHeadings";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from "../Sharedfeatures/useSnackbar";

const initialValues = {
  name: "",
  email: "",
  designation: "Customer",
  pincode: "",
  address: "",
  aadhar: "",
  userid: "",
  user_type: "",
  mobile: "",
  region: "",
  pan: "",
};

const CustomerForm = () => {
  const classes = makemyStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackBar = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues) {
      (temp.email = fieldValues.email ? "" : "This field is required.") ||
        (temp.email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.");
    }
    if ("mobile" in fieldValues)
      temp.mobile =
        (fieldValues.mobile ? ((fieldValues.mobile == "0000000000") ? "Invalid Phone Number" : "") : "This field is required.") ||
        (fieldValues.mobile.length == 10 ? "" : (fieldValues.mobile.length > 0 && fieldValues.mobile.length < 10) ? "Minimum 10 numbers required" : "Maximum 10 numbers allowed");
    if ("region" in fieldValues)
      temp.region = fieldValues.region ? "" : "This field is required.";
    if ("userid" in fieldValues)
      temp.userid = fieldValues.userid ? "" : "This field is required.";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";
    if ("pincode" in fieldValues)
      temp.pincode = (fieldValues.pincode ? "" : "This field is required.") || ((fieldValues.pincode.length > 6) ? "Allowed only 6 characters" : (fieldValues.pincode.length < 6) ? "Enter atleast 6 characters" : "");
    if ("aadhar" in fieldValues)
      temp.aadhar = (fieldValues.aadhar ? "" : "This field is required.") || ((fieldValues.aadhar.length > 12) ? "Allowed only 12 characters" : (fieldValues.aadhar.length < 12) ? "Enter atleast 12 characters" : "");
    if ("pan" in fieldValues)
      temp.pan = (fieldValues.pan ? "" : "This field is required.") || ((fieldValues.pan.length > 10) ? "Allowed only 10 characters" : (fieldValues.pan.length < 10) ? "Enter atleast 10 characters" : "");;
    if ("user_type" in fieldValues)
      temp.user_type =
        fieldValues.user_type.length !== 0 ? "" : "This field is required.";
    if ("designation" in fieldValues)
      temp.designation =
        (fieldValues.designation ? "" : "This field is required.")
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const {
    userid,
    name,
    email,
    mobile,
    aadhar,
    pan,
    address,
    message,
    success,
    customerList,
    regionList,
    requestId,
  } = useSelector((state) => state.employee);

  useEffect(() => {
    if (userid) setErrors({ ...errors, userid: userid });
    if (name) setErrors({ ...errors, name });
    if (email) setErrors({ ...errors, email: email });
    if (mobile) setErrors({ ...errors, mobile: mobile });
    if (aadhar) setErrors({ ...errors, aadhar });
    if (pan) setErrors({ ...errors, pan });
    if (address) setErrors({ ...errors, address })
    if (success && message) {
      snackBar.openSnackbar(message);
      setTimeout(() => {
        navigate(`${CUST_LIST}`);
      }, 800);
    }
  }, [userid, email, mobile, aadhar, pan, address, message, success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    return validate() ? dispatch(addEmployee(values)) : ""
  };

  useEffect(() => {
    dispatch(resetstate());
  }, [requestId]);

  useEffect(() => {
    dispatch(getRegionList());
    dispatch(getCustomerTypes());
  }, []);

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;

  return (
    <>
      <Paper
        className={classes.pageContent}
      >
        <PageHeader
          header={ADD_CUST_HEADER}
          subHeader={ADD_CUST_SUBHEADER}
          icon={BACK_BUTTON}
          link={CUST_LIST}
        />

        <Form onSubmit={handleSubmit}>
          <Paper className={classes.addNewPageContent}>
            <Grid container>
              <Grid item xs={6}>
                <Controls.Input
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  // onKeyDown={(event) => {
                  //   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                />
                <Controls.Input
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                />
                <Controls.Input
                  label="Pin Code"
                  name="pincode"
                  type="number"
                  value={values.pincode}
                  onChange={handleInputChange}
                  error={errors.pincode}
                />
                <Controls.Input
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                />
                <Controls.Input
                  label="Aadhar Card Number"
                  name="aadhar"
                  type="number"
                  value={values.aadhar}
                  onChange={handleInputChange}
                  error={errors.aadhar}
                />
                <Controls.Input
                  label="Pan Card Number"
                  name="pan"
                  value={values.pan}
                  onChange={handleInputChange}
                  error={errors.pan}
                />
              </Grid>
              <Grid item xs={6}>
                <Controls.Input
                  label="Mobile"
                  name="mobile"
                  type="number"
                  value={values.mobile}
                  maxLength={10}
                  onChange={handleInputChange}
                  error={errors.mobile}
                />
                <Controls.Input
                  label="Role"
                  name="designation"
                  value={values.designation}
                  onChange={handleInputChange}
                  error={errors.designation}
                  disabled
                />
                {/* <Controls.Input
                  label="Grade"
                  name="grade"
                  value={values.grade}
                  onChange={handleInputChange}
                  error={errors.grade}
                /> */}
                <Controls.Input
                  label="User Id"
                  name="userid"
                  value={values.userid}
                  onChange={handleInputChange}
                  error={errors.userid}
                />
                <Controls.Selectcustomer
                  label="Customer Type"
                  name="user_type"
                  value={values.user_type || ""}
                  onChange={handleInputChange}
                  options={customerList}
                  error={errors.user_type}
                />
                <Controls.Selectregion
                  label="Region"
                  name="region"
                  value={values.region || ""}
                  onChange={handleInputChange}
                  options={regionList}
                  error={errors.region}
                />
                <div className="ml-2">
                  <Controls.Button
                    type="submit"
                    text="Submit"
                    className={classes.submitButton}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Form>
        <div>
          <Snackbar {...snackBar} />
        </div>
      </Paper>
    </>
  );
};

export default CustomerForm;
