import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanAccountDetails = createAsyncThunk(
    "loanaccountdetails",
    async(clientId) => {
        const url = `loans/statement/${clientId}`
        const res = await DataService.get(url);
        return res.data;
    }
)

export const reset_message = createAsyncThunk(
    "reset_message",
    async () => {
      return;
    }
  )

const initialState = {
    loanaccountDetails: '',
    isLoading: true
}

const loanAccountDetailsSlice = createSlice({
    name: 'loanaccountdetails',
    initialState,
    extraReducers: {
        [getLoanAccountDetails.pending]: (state, action) => {
            state.isLoading = true;
        },
        [getLoanAccountDetails.fulfilled]: (state, action) => {
            state.loanBasicDetails = action.payload.basic;
            state.loanaccountDetails = action.payload.data;
            state.isLoading = false;
        },
        [getLoanAccountDetails.rejected]: (state) => {
            state.isLoading = false;
        },
        [reset_message.fulfilled]: (state, action) => {
            state.loanaccountDetails = "";
          },
    }
})

export default loanAccountDetailsSlice.reducer;