import React, { useState, useEffect } from 'react'
import { Paper, TableBody, TableRow, TableCell, Tooltip, IconButton, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { BsSearch } from "react-icons/bs";
import useTable from "../Sharedfeatures/useTable";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { getRejectedApplicatons, getRejectedDocs } from '../../slice/loanapplications';
import HeadCells from "../Common/HeadCells";
import { BsFillEyeFill } from "react-icons/bs";
import RejectedDocsModal from './RejectedDocsModal';
import moment from 'moment';

const RejectedApplications = () => {

    const classes = makemyStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const { rejectedList, rejectedDocs, totalCount, isLoading } = useSelector(store => store.loanApplication);
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(rejectedList, HeadCells.rejectedApplicationHead, totalCount);
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [openRejModal, setOpenRejModal] = useState(false)

    const handleRejectDocsOpen = (loanapproval_id) => {
        setOpenRejModal(true)
        dispatch(getRejectedDocs(loanapproval_id))
    }

    const handleRejectDocsClose = () => {
        setOpenRejModal(false)
    }

    useEffect(() => {
        const data = {
            pageNo: pageNo,
            search: search,
        };
        dispatch(getRejectedApplicatons(data))
        setShowPageNtn(true);
    }, [dispatch, pageNo, search])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    return (
        <div style={{display:'flex', gap:'15px', justifyContent:'space-between', flexDirection:'column'}} >
            <TextField
                size="small"
                className={classes.preAppSearchTextBar}
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                placeholder="Search Name or RT/DT Code"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <BsSearch className={classes.searchIconColour} />
                        </IconButton>
                    ),
                }}
            />

            <Paper className={classes.tableBoxShadow}>
                <TblContainer>
                    <TblHead />
                    {
                        isLoading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        align="center"
                                        className={classes.tableCell}
                                    >
                                        <ProgressSpinner />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {
                                    rejectedList.length !== 0 ? (
                                        rejectedList &&
                                        rejectedList.map((item, key) => {
                                            serialNo++;
                                            return (
                                                <TableRow key={key.toString()}>
                                                    <TableCell className={classes.tableCell}>
                                                        {serialNo}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.userid}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.fos.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.ShortName}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.amount}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {(item.statusId == 18) ? 'Rejected' : ''}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {item.tenure}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {moment(item.created_on).format("DD MMMM YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ paddingLeft: "25px" }} align='center'>
                                                        <Tooltip title="View Details">
                                                            <IconButton onClick={() => handleRejectDocsOpen(item.loanapproval_id)}>
                                                                <BsFillEyeFill className={classes.actionIcon} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={8} align="center">
                                                No Data to Show
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        )
                    }
                </TblContainer>
                {
                    openRejModal ? <RejectedDocsModal open={openRejModal} handleClose={handleRejectDocsClose} data={rejectedDocs} /> : ''
                }
            </Paper>
            {
                showPageNtn ? <TblPagination /> : ''
            }
        </div>
    )
}

export default RejectedApplications