import React, { useEffect } from 'react'
import {
    Paper,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Button,
    TextField,
    Grid,
    Typography
} from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { LOAN_ACC_DETAILS_HEADER, LOAN_ACC_DETAILS_SUB_HEADER, BACK_BUTTON } from '../../utils/pageHeadings';
import PageHeader from "../Common/PageHeader"
import useTable from "../Sharedfeatures/useTable";
import { useSelector, useDispatch } from 'react-redux';
import HeadCells from '../Common/HeadCells';
import ProgressSpinner from '../Common/ProgressSpinner'
import { getLoanAccountDetails, reset_message } from '../../slice/loanaccountdetails';
import { useParams, useNavigate } from 'react-router-dom';
import { LOAN_SANCT_URL } from '../../utils/links';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const LoanAccountDetails = () => {

    const classes = makemyStyles();
    const { loanaccountDetails, loanBasicDetails, isLoading } = useSelector((store) => store.loanaccdetails)

    const { TblContainer, TblHead } = useTable(loanaccountDetails, HeadCells.loanAccDetailsHead);
    const { clientId } = useParams();
    const dispatch = useDispatch()
    const moment = require("moment")
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(reset_message())
        dispatch(getLoanAccountDetails(clientId))
    }, [clientId])

    const goToLoanStatement = (clientId, id) => {
        navigate(`/admin/loansanctioned/loanaccountdetails/loanaccountstatement/${id}/${clientId}`)
    }

    return (
        <>
            <Paper className={classes.pageContent}>

                <PageHeader header={`${LOAN_ACC_DETAILS_HEADER}`} subHeader={`${LOAN_ACC_DETAILS_SUB_HEADER}`} icon={BACK_BUTTON} link={LOAN_SANCT_URL} />

                <Paper className={classes.loanDetailsHeadSection}>
                    <Grid container>
                        <Grid item md={5}>
                            <Grid container>
                                <Grid item md={5}>
                                    <h3 className={classes.loanDetailsHeadContent}>Name:</h3>
                                    <h3 className={classes.loanDetailsHeadContent}>Email:</h3>
                                    <h3 className={classes.loanDetailsHeadContent}>Company Name:</h3>
                                </Grid>
                                <Grid item md={7}>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : loanBasicDetails && loanBasicDetails.customer && loanBasicDetails.customer.name}</h3>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : loanBasicDetails && loanBasicDetails.customer && loanBasicDetails.customer.email}</h3>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : loanBasicDetails && loanBasicDetails.companyName}</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={5}>
                            <Grid container>
                                <Grid item md={5}>
                                    <h3 className={classes.loanDetailsHeadContent}>Loan Account No:</h3>
                                    <h3 className={classes.loanDetailsHeadContent}>Agreement Start Date:</h3>
                                    <h3 className={classes.loanDetailsHeadContent}>Agreement End Date:</h3>
                                </Grid>
                                <Grid item md={7}>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : loanBasicDetails && loanBasicDetails.loanaccountNumber}</h3>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : moment(loanBasicDetails && loanBasicDetails.agreement_start).format("DD MMMM YYYY")}</h3>
                                    <h3 className={classes.loanDetailsHeadBodyContent}>{isLoading ? <Skeleton /> : moment(loanBasicDetails && loanBasicDetails.agreement_end).format("DD MMMM YYYY")}</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Typography variant="h5" className={classes.loanDetailsTableOneCaption}>Tranche Details</Typography>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody><TableRow>
                                <TableCell colSpan={8} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
                            ) :
                                (
                                    <TableBody>
                                        {
                                            (loanaccountDetails.length !== 0) ? (
                                                loanaccountDetails && loanaccountDetails.map((item, key) => {
                                                    key++;
                                                    return (
                                                        <TableRow key={item.id.toString()}>
                                                            <TableCell align='center'>
                                                                {key}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.accountNo}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.externalId}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.productName}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.originalLoan}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.amountPaid || '--'}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item && item.status && item.status.value}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Button variant="outlined" color="primary" onClick={() => goToLoanStatement(clientId, item.id)}>
                                                                    View
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={8} align='center'>No Data to Show</TableCell>
                                                </TableRow>
                                            )

                                        }
                                    </TableBody>
                                )
                        }
                    </TblContainer>
                </Paper>
            </Paper>
        </>
    )
}

export default LoanAccountDetails