import React, { useEffect, useState } from "react";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Button as MuiButton } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";
import { BsFillEyeFill } from "react-icons/bs";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { BsSearch } from "react-icons/bs";
import Button from "../Controls/Button";
import useTable from "../Sharedfeatures/useTable";
import ImportModal from "../Common/ImportModal";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { ADD_CUST } from "../../utils/links";
import {
  CUST_LIST_HEADER,
  CUST_LIST_SUBHEADER,
  CUST_MODAL_HEADER,
} from "../../utils/pageHeadings";
import { CUST_DETAILS } from "../../utils/links";
import { getEmployeeList, getEmployeeExcel, uploadExcel, resetstate } from "../../slice/employee";
import { CUSTOMER_DATA_URL } from "../../utils/links";
import { Link } from "react-router-dom";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";


function CustomerList() {
  const [fileType, setFileType] = useState(true);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = makemyStyles();
  const dispatch = useDispatch();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState("");
  const excelData = useExcelDownload();
  const [excelSheetData, setExcelSheetData] = useState([]);

  const URL = process.env.REACT_APP_API_BASE_URL;

  const { employeeList, employeeListExcel, totalCount, message, requestId, isLoading, addEmployeeList, success } = useSelector((state) => state.employee);

  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(employeeList, HeadCells.customerHead, totalCount);


  useEffect(() => {
    dispatch(resetstate());
    const data = {
      searchTerm: search,
      pageNum: pageNo,
      totalCount,
    };
    dispatch(getEmployeeList(data));
    dispatch(getEmployeeExcel(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, totalCount]);


  useEffect(() => {
    if (message && success) {
      setFileUploaded(true);
      setTimeout(() => {
        setFileUploaded(false);
        onBtnClick();
      }, 2000);
    }

    else if (message && success === false) {
      setFileUploadError(true);
      setTimeout(() => {
        setFileUploadError(false);
        dispatch(resetstate());
        // onBtnClick();
      }, 2000);
    }

  }, [message, success]);

  const onBtnClick = () => {
    setOpen(false);
  };

  const readExcel = (e) => {
    const data = e.target.files[0];

    if (
      data.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileType(false);
      setTimeout(() => {
        setFileType(true);
      }, 3000);
    } else {
      const formData = new FormData();
      formData.append("customers", data, data.name);
      dispatch(uploadExcel(formData))
    }
  };

  let serialNo = PAGE_SIZE * slNo;

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    employeeListExcel?.map(item => {
      excelsheetdata.push({
        "RT/DT Code": item.userid,
        "Name": item.name,
        "Mobile": item.mobile,
        "Email": item.email,
        "Region": item.region_name,
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [employeeListExcel])

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader header={CUST_LIST_HEADER} subHeader={CUST_LIST_SUBHEADER} />


        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
          <TextField
            size="small"
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            id="standard-bare"
            variant="outlined"
            placeholder="Search"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />

          <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'column' }} >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
              <Link to={`${ADD_CUST}`}>
                <Button
                  text="Add Customer"
                  variant="outlined"
                  startIcon={<AddBoxOutlinedIcon />}
                  className={classes.addCustomer}
                />
              </Link>
              <button className={classes.newButton} onClick={() => {
                dispatch(resetstate())
                setOpen(true)
              }}>
                IMPORT DATA
              </button>
            </div>

            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
              <MuiButton
                className={classes.excelUploadButtonOne}
                onClick={() =>
                  excelData.generateExcelFile(
                    excelSheetData,
                    "CustomerDataSheet",
                    "CustomerData.xlsx"
                  )
                }
                endIcon={<DownloadIcon />}
              >
                Download Report
              </MuiButton>

              <div>
                <a href={`${URL}${CUSTOMER_DATA_URL}`} target="blank">
                  Click here to download Sample Format
                </a>
              </div>
            </div>
          </div>
        </div>

        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    className={classes.tableCell}
                  >
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {employeeList.length !== 0 ? (
                  employeeList &&
                  employeeList.map((item, key) => {
                    serialNo++;
                    return (
                      <TableRow key={key.toString()}>
                        <TableCell className={classes.tableCell}>
                          {serialNo}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.userid}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.mobile}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.email}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.region_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ paddingLeft: "25px" }}
                        >
                          <Link to={`${CUST_DETAILS}${item.id}`}>
                            <Tooltip title="View Customer Details">
                              <IconButton>
                                <BsFillEyeFill className={classes.actionIcon} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {open ? (
          <ImportModal
            open={open}
            onClose={onBtnClick}
            title={CUST_MODAL_HEADER}
            readExcel={readExcel}
            fileType={fileType}
            message={message}
            requestId={requestId}
            fileUploaded={fileUploaded}
            fileUploadError={fileUploadError}
          />
        ) : null}
        {showPageNtn ? <TblPagination /> : ""}
      </Paper>
    </>
  );
}

export default CustomerList;
