import React, { useEffect, useState } from 'react'
import { Box, Button, Modal, InputLabel, MenuItem, FormControl, Select, Typography, IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux"
import { getFosList } from '../../slice/preapprovedloanoffer'
import './FosAssigned.css'
import { AiOutlineClose } from 'react-icons/ai'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    minHeight: 320,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px'
};

const FosAssigned = ({ open, onClose, onSubmit, loanInfo }) => {

    const [fosid, setFosid] = useState('')
    const [loanid, setLoanid] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch();


    const handleChange = (event) => {
        setFosid(event.target.value);
        setLoanid(loanInfo.loanapproval._id)
        setError(false)
    };



    // const submitFosAssigned = () => {
    //     if (loanid && fosid) {

    //         dispatch(assignFos(data))
    //             .then((res) => {
    //                 // alert(`${res.payload.message}`)
    //                 // navigate("/admin/preapprovedloan")
    //                 // document.location.reload(true)
    //                 onClose()
    //             })
    //             .catch((e) => {
    //             });

    //     }
    //     else {
    //         setError(true)
    //     }

    // }

    useEffect(() => {
        dispatch(getFosList())
    }, [dispatch])

    const { fosDropdown } = useSelector((state) => state.preApprovedLoanOffer)

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
            >
                <Box sx={style}>
                    <header className='header'>
                        <Typography className='modalTitle' sx={{ margin: '10px 0' }}>
                            Assign FOS
                        </Typography>
                        <IconButton className='closeIcon' onClick={onClose}>
                            <AiOutlineClose />
                        </IconButton>
                    </header>
                    <hr />
                    <Typography className='customerName'>
                        Customer Name:<span className='name'> {loanInfo.name}</span><br />
                        <h4 style={{ fontSize: '17px', paddingTop: '9px' }}>FOS Assigned: {loanInfo.fos ? loanInfo && loanInfo.fos && loanInfo.fos.name : 'Not Assigned'}</h4>
                    </Typography>


                    <Typography>
                        <Typography className='fosNames'>FOS:
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl className='fosDropdown'>
                                    <InputLabel>FOS Names</InputLabel>
                                    <Select
                                        value={fosid}
                                        label="FOS Names"
                                        onChange={handleChange}
                                        sx={{ marginTop: '10px' }}
                                        MenuProps={{
                                            style: {
                                                maxHeight: 400,
                                                position: 'absolute',
                                                top: '50px'
                                            },
                                        }}
                                    >

                                        {
                                            fosDropdown && fosDropdown.map((item, key) => {
                                                return <MenuItem key={key} value={item._id}>{item.name} ({item && item.region && item.region.name})</MenuItem>
                                            })
                                        }

                                    </Select>
                                    {
                                        error ? <Typography className='errorMessage'>*Please Select FOS</Typography> : ''
                                    }

                                </FormControl>
                            </Box>
                        </Typography>
                    </Typography>
                    <hr />
                    <footer className='footer'>
                        <Button variant="contained" color="primary" onClick={() => onSubmit(fosid, loanid)}>Assign FOS</Button>
                        <Button variant="contained" style={{ marginLeft: '20px' }} onClick={onClose}>Cancel</Button>
                    </footer>
                </Box>
            </Modal>
        </>
    )
}

export default FosAssigned