import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    pageContent: {
      position: "relative",
      left: "0px",
      padding: '10px 40px',
      overflowY: "hidden",
      overflowX: "hidden",
      // height: '100%',
      //minHeight: "calc(100vh - 70px)",
      display:'flex',
      flexDirection:'column',
      gap:'30px',
      justifyContent:'space-between'
    },
    tableBoxShadow: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      overflowX:'scroll'
    },
    addNewPageContent: {
      position: "relative",
      //top: "50px",
      left: "0px",
      padding: theme.spacing(7),
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      borderRadius: "16px",
      overflowY: "hidden",
      overflowX: "hidden",
    },
    detailsCard: {
      position: "relative",
      left: "0px",
      padding: theme.spacing(7),
      overflowY: "hidden",
      overflowX: "hidden",
      // height: '100%',
      background: "#FEFEFE",
      marginTop: "40px",
      borderRadius: "16px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
    },
    btnwrap:{
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    },
    newButton: {
      //position: "absolute !important",
      fontFamily: "Poppins !important",
      fontWeight: "600 !important",
      //right: "55px !important",
      //top: "115px",
      height: "40px !important",
      background: "#5A5DF6 !important",
      fontSize: "15px",
      color: "#FFFFFF !important",
      width: "150px",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      "&:hover": {
        background: "#7f81e6",
        border: "1px solid #7f81e6",
      },
    },
    submitButton: {
      height: "40px !important",
      background: "#5A5DF6 !important",
      fontSize: "15px !important",
      color: "#FFFFFF",
      width: "150px !important",
      margin: "15px 0",
      padding: "25px !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      "&:hover": {
        background: "#7f81e6 !important",
      },
    },
    excelUploadButton: {
      //position: "absolute !important",
      fontFamily: "Poppins !important",
      fontWeight: "600 !important",
      //right: "55px !important",
      //top: "167px !important",
      background: "#963460 !important",
      padding: "9px 20px !important",
      // fontSize: '0.75rem',
      color: "#FFFFFF !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "2px solid #963460",
      "&:hover": {
        background: "#fff !important",
        color: "#963460 !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    excelUploadButtonOne: {
      //position: "absolute !important",
      fontFamily: "Poppins !important",
      fontWeight: "600 !important",
      //right: "55px !important",
      //top: "167px !important",
      background: "#963460 !important",
      padding: "12px 20px !important",
      // fontSize: '0.75rem',
      color: "#FFFFFF !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "1px solid #963460",
      //marginRight: "260px !important",

      "&:hover": {
        background: "#fff !important",
        color: "#963460 !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    preApprovedExcelUploadButton: {
      //position: "absolute !important",
      fontFamily: "Poppins !important",
      fontWeight: "600 !important",
      //right: "55px !important",
      //top: "167px !important",
      background: "#963460 !important",
      padding: "12px 20px !important",
      // fontSize: '0.75rem',
      color: "#FFFFFF !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      //marginTop: "63px !important",
      //marginRight: "30px !important",
      border: "2px solid #963460",
      "&:hover": {
        background: "#fff !important",
        color: "#963460 !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
 lpselectDropdownBar: {
      position: "relative",
      width: "360px",
      borderRadius: "8px",
      height: "50px",
      // backgroundColor: "#ffffff",
      boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
      padding: '0 10px',
    },
    datePicker: {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: "8px",
      //position: "absolute",
      //right: "300px !important",
      //top: "167px !important",
      height: "50px"
    },
    datePickerPre: {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: "8px",
      //position: "absolute",
      //right: "300px !important",
      //top: "167px !important",
      height: "50px",
      //marginTop: '65px',
      //marginRight: '40px',
    },
    datePickerOne: {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: "8px",
      //position: "absolute",
      //right: "300px !important",
      //top: "167px !important",
      height: "50px",
      marginRight: "5px"
    },
    noExcelDatePicker: {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: "8px",
      //position: "absolute",
      //right: "60px !important",
      //top: "167px !important",
      height: "50px"
    },
    toolBar: {
      position: "relative",
      color: "#963460",
      width: "100%",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      fontWeight: "600 !important",
      fontSize: "30px !important",
      lineHeight: "45px !important",
    },
    headerParentNoBack: {
      // position: "absolute",
      // top: "56px",
      // left: "60px",
    },
    headerParentBack: {
      // position: "absolute",
      // top: "58px",
      // left: "90px",
    },
    pageHeader: {
      position: "relative",
      color: "#963460",
      width: "100%",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      fontWeight: "600 !important",
      fontSize: "30px !important",
      lineHeight: "45px !important",
    },
    subHeader: {
      position: "relative",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "10px !important",
      lineHeight: "15px !important",
      margin:0
    },
    headerBackButton: {
      position: "relative",
      //left: "-20px",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      fontWeight: "600 !important",
      //fontSize: "30px !important",
    },

    formBackButton: {
      position: "absolute",
      left: "10px",
      top: "15px",
      color: "#963460",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      fontWeight: "600 !important",
      fontSize: "30px !important",
    },

    formSubHeader: {
      position: "relative !important",
      top: "40px",
      left: "-574px !important",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "10px !important",
      lineHeight: "15px !important",
      margin: "10px 0 !important",
    },

    addCustomer: {
      fontFamily: "Poppins",
      fontWeight: "600 !important",
      //position: "absolute",
      //right: "220px",
      //top: "115px",
      height: "40px",
      backgroundColor: "#5A5DF6 !important",
      fontSize: "15px !important",
      color: "#FFFFFF !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      "&:hover": {
        backgroundColor: "#7f81e6 !important",
        border: "1px solid #7f81e6",
      },
    },

    addCustomerOne: {
      fontFamily: "Poppins",
      fontWeight: "600 !important",
      //position: "absolute",
      //right: "220px",
      //top: "115px",
      height: "40px",
      background: "#963460 !important",
      padding: "12px 20px !important",
      fontSize: "15px !important",
      color: "#FFFFFF !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "2px solid #963460",
      "&:hover": {
        background: "#fff !important",
        color: "#963460 !important",
      },
    },

    tableCell: {
      color: "#444445!important",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      // fontWeight: '600!important',
      lineHeight: "18px",
      fontSize: "12px!important",
    },

    tableListing: {
      position: "absolute",
      width: "500px",
      height: "15px",
      marginLeft: "3px !important",
      top: "85px",
      fontFamily: "Poppins !important",
      fontStyle: "normal",
      fontWeight: "300 !important",
      fontSize: "10px !important",
      lineHeight: "15px",
      color: "#242424",
      letterSpacing: "0.08em",
    },

    actionIcon: {
      color: "#5A5DF6 !important",
      // fontSize: "18px",
    },
    acceptIcon: {
      color: "#5A5DF6",
      fontSize: "20px",
    },
    rejectIcon: {
      color: "#ff0000",
      fontSize: "20px",
    },
    disabledIcon: {
      color: "#808080",
      fontSize: "20px",
    },
    dataUpload: {
      position: "absolute",
      right: "180px",
      top: "33px",
      width: "150px",
      height: "40px",
      border: "1px solid #5A5DF6",
      borderRadius: "3px",
      display: "inline-block",
      // padding: '8px 12px',
      cursor: "pointer",
      color: "#5A5DF6",
      fontWeight: "bold",
      fontSize: "15px",
      padding: "0 20px",
    },
    dataUploadLabel: {
      width: "100%",
      position: "absolute",
      top: "-2px",
      right: "-20px",
      cursor: "pointer",
    },
    noValues: {
      textAlign: "center",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "18px",
      margin: "20px 0",
    },
    employeeItems: {
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: "800 !important",
      fontSize: "14px !important",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    employeeItemsDetails: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      // fontWeight: "600",
      fontSize: "14px !important",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    secHeading: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600 !important",
      fontSize: "20px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listHeading: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "800 !important",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listValues: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    headContainer: {
      display: "flex !important",
      gap :'20px',
      width: "40%",
      //position: "absolute",
      //right: "50px",
      //top: "45px",
      justifyContent: "space-between !important",
    },
    innerHeadContainer: {
      backgroundColor: "#6b47a7",
      //width: "48% !important",
      //height: "80px !important",
      borderRadius: "15px",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "space-between",
      flexDirection: "column !important",
      padding: '15px 25px'
    },
    innerHeadContainerOne: {
      backgroundColor: "#6b47a7",
      // width: "50% !important",
      //height: "110px !important",
      borderRadius: "15px",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
      flexDirection: "column !important",
      gap:'10px',
      padding:'15px 25px'
    },
    containerHeader: {
      color: "#ffffff",
      fontFamily: "Poppins",
      margin: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "0.9rem",
      },
    },
    containerBody: {
      color: "#ffffff",
      fontFamily: "Poppins",
      fontSize: "1em",
      fontWeight: "bold",
      margin:0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "0.9rem",
      },
    },
    searchIconColour: {
      color: "#963460",
      height: "20px",
    },
    searchTextBar: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      width: "400px !important",
      borderRadius: "8px",
      position: "relative",
      //top: "82px !important",
      //margin: "30px 0 !important",
    },
    preAppSearchTextBar: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      width: "400px !important",
      borderRadius: "8px",
      //position: "absolute !important",
      //top: "230px !important",
    },
    loanDetailsHeadSection: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      marginTop: "95px",
      padding: "25px",
    },
    loanDetailsTableOneCaption: {
      color: "#963460",
      fontWeight: "bold !important",
      position: "relative",
      // top: "70px",
    },
    loanDetailsHeadContent: {
      fontSize: "15px",
      fontFamily: "Poppins",
      fontWeight: "800",
    },
    loanDetailsHeadBodyContent: {
      fontSize: "15px",
      fontFamily: "Poppins",
      // fontWeight: "800",
    },
    cardSection: {
      marginTop: "90px",
    },
    loanStatementCards: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      position: "relative",
      display: "flex",
      justifyContent: "stretch",
    },
    loanStatementSummaryTableCaption: {
      position: "relative",
      // top: "40px",
      color: "#963460",
      fontWeight: "bold !important",
    },
    loanStatementTransactionTableCaption: {
      position: "relative",
      top: "80px",
      color: "#963460",
      fontWeight: "bold !important",
    },
    lstcontainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "100px",
    },
    lsttypography: {
      color: "#963460",
      fontWeight: "bold",
      marginTop: "50px",
    },
    lsttableLeft: {
      maxWidth: "100%",
      width: "100%",
    },
    lsttableRight: {
      maxWidth: "100%",
      width: "100%",
    },
    lsttable: {
      border: "2px solid #1A0045",
    },
    repayButton:{
      display: 'flex',
      width: '86px',
      height: '30px',
      padding: '12px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '4px',
      background: 'var(--Light-blue, #1976D2)',
      fontSize:'12px',
      color:'#ffffff',
      "&:hover": {
        background: "#40a9ff",
      },
      "&:disabled":{
        color:'#ffffff'
      }
    },
    repayModalWrap:{
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      gap:20
    },
    repayModalDoneWrap:{
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      gap:30
    },
    repayModalTitle:{
      position:'relative',
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      color: '#963460',
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '36px',
      letterSpacing: '0.18px',
    },
    closeIconStyle:{
      position: "absolute",
      right:0,
    },
    reversIcon:{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    actionBtnsWrap:{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding: '10px 0px'
    },
    actionBtnDoneWrap:{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      padding: '10px 30px'
    },
    reveseBack:{
      display: 'inline-flex',
      minWidth: '64px',
      padding: '8px 20.941px 8px 21.649px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      background: '#ECECEC',
      border:'none',
      color: '#585858',
      fontWeight : 500,
      cursor:'pointer',
      height: '42.25px',

    },
    reveseYes:{
      cursor:'pointer',
      border:'none',
      display: 'inline-flex',
      height: '42.25px',
      minWidth: '64px',
      padding: '8px 20.697px 8px 21.693px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '4px',
      background: '#3F51B5',
      color: '#FFF',
      fontWeight: 500,
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
    },
    repayform:{
      width:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      gap:20
    },
    formcontrol:{
      width : '100%',
      flexDirection : 'row',
      display:'flex',
      alignItems : 'center',
      flexWrap : 'wrap',
      gap: 10
    },

    manualrepaymentform:{
      width:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      gap:20,
      padding:20
    },
    manualrepaymentformcontent:{
      padding:'15px',
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
      gap:'5px'
    },
    manualRepaymentFormactionBtnsWrap:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding: '10px 0px',
      gap:'30px'
    },
  })
);
