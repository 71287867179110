import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getloanClosures = createAsyncThunk(
    'getloanClosures',
    async (value) => {
        try {
            const url = `/console-web/loan-closures`
            const response = await DataService.post(url, value)
            return response
        } catch (err) {
            return err.response
        }
    })

const initialState = {
    isLoading: true,
    loanClosuresData: '',
}

const loanClosures = createSlice({
    name: 'loanclosures',
    initialState,
    extraReducers: {
        [getloanClosures.pending]: (state) => {
            return { ...state, isLoading: true }
        },
        [getloanClosures.fulfilled]: (state, { payload }) => {
            return { ...state, loanClosuresData: payload?.data, isLoading: false }
        },
    }
})

export const getloanClosuresData = (state) => state.loanclosuresapi;
export default loanClosures.reducer