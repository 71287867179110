import React from "react";
import {
  Grid,
  Typography,
  ListItemText,
} from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'

const Termsview = () => {
  const classes = makemyStyles();
  const { loanDetails } = useSelector((store) => store.loanProducts
  );
  
  return (
    <>
      <Grid>
        <h1 style={{ paddingLeft: "30px" }} className={classes.secHeading}>Terms</h1>
        <hr />
      </Grid>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={
                  <Typography className={classes.employeeItems}>Principal</Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography className={classes.employeeItemsDetails}>
                    {loanDetails.principal || <Skeleton />}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Maximum Principal:</Typography>}
              />
              <ListItemText 
                primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.max_principal_amount || <Skeleton />}</Typography>}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Minimum Principal:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.minprincipal || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Number of Repayments:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.numberOfREpayments || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Repay Every:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.repaymentEvery || <Skeleton />} {loanDetails.repaymentFerequencyType}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Interest Rate:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.interest_rate || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Loan Status:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.status === true ? "Active" : "Inactive" || <Skeleton />}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
            <Grid item md={12}>
              <ListItemText
                primary={<Typography className={classes.employeeItems}>Minimum days between disbursal and first repayment date:</Typography>}
              />
              <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.minimumGap}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "35px" }}></Grid>
      </Grid>
    </>
  );
};

export default Termsview;
