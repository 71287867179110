import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from '../services/DataService'

export const addEmployee = createAsyncThunk(
    "addEmployee",
    async (state, thunkAPI) => {
        try {
            const url = '/users/create';
            const { ...data } = state;
            const res = await DataService.post(url, data);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getEmployeeDetails = createAsyncThunk(
    "getEmployeeDetails",
    async (id, { rejectWithValue }) => {
        try {
            const url = `/users/${id}`
            const response = await DataService.get(url);
            return response.data.data;
        } catch (err) {
            return rejectWithValue([], err);
        }
    }
);

export const getEmployeeList = createAsyncThunk(
    "getEmployeeList",
    async (data, thunkAPI) => {
        const { searchTerm, pageNum } = data;
        try {
            const url = `/users?role=customer&page=${pageNum}&limit=10&searchqry=${searchTerm}`;
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

//download customer excel
export const getEmployeeExcel = createAsyncThunk(
    "getemployeeexcel",
    async (data, thunkAPI) => {
        const { searchTerm, pageNum, totalCount } = data;
        try {
            const url = searchTerm ? `/users?role=customer&page=${pageNum}&list_type=excel&limit=10&searchqry=${searchTerm}` : `/users?role=customer&page=1&list_type=excel&limit=${totalCount}`
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

export const getCustomerTypes = createAsyncThunk(
    "getCustomerTypes",
    async () => {
        const url = '/users/user-types'
        const res = await DataService.get(url);
        return res.data.data
    }
)

export const getRegionList = createAsyncThunk(
    "getregionlist",
    async () => {
        const url = '/region/list';
        const res = await DataService.get(url);
        return res.data;
    }
);

export const uploadExcel = createAsyncThunk(
    "uploadExcel",
    async (formData, thunkAPI) => {
        try {
            const url = '/users/customer-upload';
            const res = await DataService.filepost(url, formData);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

export const resetstate = createAsyncThunk(
    "resetstate",
    async () => {
        return;
    }
)

const customerSlice = createSlice({
    name: "employee",
    initialState: {
        addEmployeeList: [],
        employeeDetails: [],
        employeeList: [],
        employeeListExcel: [],
        regionList: [],
        customerList: [],
        userid: '',
        name: '',
        email: '',
        mobile: '',
        aadhar: '',
        pan: '',
        address: '',
        requestId: '',
        totalCount: '',
        isLoading: true,
        cust_details_loading: true,
    },
    extraReducers: {
        [getEmployeeList.pending]: (state) => {
            state.isLoading = true;
        },
        [getEmployeeList.fulfilled]: (state, action) => {
            // console.log("CUST act",action);
            state.message = ''
            state.success = ''
            state.totalCount = action.payload.totalItmes
            state.employeeList = action.payload.data
            state.isLoading = false;
        },
        [getEmployeeList.rejected]: (state) => {
            state.isLoading = false;
        },
        [getEmployeeExcel.fulfilled]: (state, action) => {
            state.employeeListExcel = action.payload.data
        },
        [getEmployeeDetails.pending]: (state) => {
            state.cust_details_loading = true
        },
        [getEmployeeDetails.fulfilled]: (state, action) => {
            state.employeeDetails = action.payload
            state.cust_details_loading = false
        },
        [getEmployeeDetails.rejected]: (state) => {
            state.cust_details_loading = false
        },
        [addEmployee.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
            state.addEmployeeList = action.payload
        },
        [addEmployee.rejected]: (state, action) => {
            state.userid = action.payload.userid;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.mobile = action.payload.mobile;
            state.aadhar = action.payload.aadhar;
            state.pan = action.payload.pan;
            state.address = action.payload.address;
            state.requestId = action.meta.requestId;
        },
        [getCustomerTypes.fulfilled]: (state, action) => {
            state.customerList = action.payload;
        },
        [uploadExcel.fulfilled]: (state, action) => {
            if (action.payload.success === true) {
                state.addEmployeeList = action.payload.data;
                let newData = state.addEmployeeList
                let addedData = newData.reverse();
                let customerList = state.employeeList
                let cust_ids = []
                customerList.map((item) => {
                    cust_ids.push(item.userid)
                })

                let sum = [];
                addedData.map((item) => {
                    if (!(cust_ids.includes(item.userid)))
                        sum.push(item)
                })
                state.employeeList = [...sum, ...customerList]
                state.message = action.payload.message;
                state.success = action.payload.success;
                state.requestId = action.meta.requestId;
            }
            else {
                state.message = action.payload.message;
                state.success = action.payload.success;
            }
        },
        [uploadExcel.rejected]: (state, action) => {
        },
        [getRegionList.fulfilled]: (state, action) => {
            state.regionList = action.payload.data
        },
        [resetstate.fulfilled]: (state) => {
            state.mobile = '';
            state.email = '';
            state.name = '';
            state.success = '';
            state.userid = '';
            // state.employeeList = '';
            state.employeeDetails = '';
            state.message = '';
            state.aadhar = '';
            state.pan = '';
            state.address = '';
        },
    }
})

const { reducer } = customerSlice
export default reducer