import React from 'react'
import { Typography, Grid, ListItemText } from '@material-ui/core'
import makemyStyles from '../Sharedfeatures/SharedStyles'
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'

const LoanDetailsHeaderCell = ({ title, details }) => {

    const classes = makemyStyles();
    const { isLoading } = useSelector((state) => state.preApprovedLoanOffer)

    return (
        <>
            <Grid item xs={10}>
                <Grid container spacing={25} style={{ paddingLeft: "30px", paddingBottom: '15px' }}>
                    <Grid item md={6} >
                        <ListItemText className={classes.employeeItems} primary={<Typography className={classes.listHeading}>{title}</Typography>} />
                    </Grid>
                    <Grid item md={6} >
                        <ListItemText className={classes.employeeItems} primary={<Typography className={classes.listValues}>{isLoading ? <Skeleton /> : details}</Typography>} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LoanDetailsHeaderCell