import React from 'react'
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer, Tooltip } from 'recharts';

const RepaymentsChart = ({ graphData }) => {

    const style = {
        top: '50%',
        right: 0,
        transform: 'translate(0, -50%)',
        lineHeight: '24px',
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0]?.payload.name} : ${payload[0].value}%`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className='displayflex repayment-chart'>
            <ResponsiveContainer width={'60%'} height={400}>
                <RadialBarChart innerRadius="30%" outerRadius="100%" barSize={70} data={graphData} startAngle={90} endAngle={-270}>
                    <RadialBar
                        minAngle={15}
                        //label={{ position: 'insideStart', fill: '#000' }}
                        dataKey="percentage"
                        background
                    />

                    <Tooltip content={<CustomTooltip />} />
                </RadialBarChart>
            </ResponsiveContainer>

            <div className="legend-wraper displayflexcol">
                <div className="legends leg-disbursed">
                    <div className="leg-square"></div>
                    <div className="leg-label">Disbursed</div>
                </div>

                <div className="legends leg-repaid">
                    <div className="leg-square"></div>
                    <div className="leg-label">Repaid</div>
                </div>

                <div className="legends leg-due">
                    <div className="leg-square"></div>
                    <div className="leg-label">Due</div>
                </div>

            </div>
        </div>
    )
}

export default React.memo(RepaymentsChart)
