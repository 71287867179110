import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getupcomingRepayments = createAsyncThunk(
    "getupcomingRepayments",
    async (data, thunkAPI) => {
        const { fromDate, toDate, pageNo, searchTerm } = data;
        try {
            const url = `/repayments/list?start=${fromDate}&end=${toDate}&page=${pageNo}&searchqry=${searchTerm}&limit=10`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getupcomingRepaymentsDataExcel = createAsyncThunk(
    "getupcomingRepaymentsDataExcel",
    async (data, thunkAPI) => {
        const { fromDate, toDate, pageNo, searchTerm } = data;
        try {
            const url = `/repayments/list?start=${fromDate}&end=${toDate}&page=${pageNo}&searchqry=${searchTerm}&limit=0`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const repayAmount = createAsyncThunk(
    "repayAmount",
    async (values, thunkAPI) => {
        try {
            const url = `loans/repayment`;
            const res = await DataService.post(url,values);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const initialState = {
    upcRepList: [],
    isLoading: true,
    totalCount: '',
    uprepaymentDataExcel: []

}

const getUpcomingRepaymentsList = createSlice({
    name: 'upcomingRepayments',
    initialState,
    extraReducers: {
        [getupcomingRepayments.pending]: (state) => {
            state.isLoading = true;
        },
        [getupcomingRepayments.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.upcRepList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
        },
        [getupcomingRepayments.rejected]: (state) => {
            state.isLoading = false;
        },
        [getupcomingRepaymentsDataExcel.fulfilled]: (state, action) => {
            state.uprepaymentDataExcel = action.payload.data;
        },
    }
})

export default getUpcomingRepaymentsList.reducer