import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import {
  LOAN_STATEMENT_HEADER,
  LOAN_STATEMENT_SUBHEADER,
  BACK_BUTTON,
} from "../../utils/pageHeadings";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import HeadCells from "../Common/HeadCells";
import useTable from "../Sharedfeatures/useTable";
import { LOAN_SANCT_URL } from "../../utils/links";
import { getLoanStatement } from "../../slice/loansanctioned";

const useStyles = makeStyles({
  table: {
    marginTop: "50px",
    "&": {
      borderRadius: "20px",
    },
    "& thead th": {
      fontWeight: "600",
      fontSize: "15px",
      letter: "8%",
      color: "#f8f7fc",
      lineHeight: "22.5px",
      backgroundColor: "#1A0045",
    },
    "& tbody td": {
      fontWeight: "500",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
    },
    "& thead th:first-child": {
      borderRadius: "15px 0 0 15px",
    },
    "& thead th:last-child": {
      borderRadius: "0 15px 15px 0",
    },
  },
});

const LoanAccountStatement = () => {
  const { loanStatement } = useSelector((store) => store.loanSanctioned);
  const classes = makemyStyles();
  const tableClass = useStyles();
  const dispatch = useDispatch();
  const { id, clientId } = useParams();
  const { TblContainer, TblHead } = useTable(
    loanStatement,
    HeadCells.loanStatementHead
  );

  const dateFormatter = (dateArray) => {
    let recArray = dateArray;
    let dateRArray = recArray.map((item) => item).reverse();
    let lengthOfArray = dateRArray.length;
    let correctedDate = [];
    dateRArray.map((item, key) => {
      key++;
      if (key < lengthOfArray) correctedDate.push(`${item}-`);
      else correctedDate.push(`${item}`);
    });
    return correctedDate;
  };

  useEffect(() => {
    dispatch(getLoanStatement(id));
  }, []);

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={LOAN_STATEMENT_HEADER}
          subHeader={LOAN_STATEMENT_SUBHEADER}
          icon={BACK_BUTTON}
          link={`/admin/loansanctioned/loanaccountdetails/${clientId}`}
        />

        <div className={classes.lstcontainer}>
          <Paper className={classes.tableBoxShadow}>
            <TableContainer className={classes.lsttableLeft}>
              <TableBody>
                <TableRow>
                  <TableCell>Name:</TableCell>
                  <TableCell>{loanStatement.clientName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Office Id:</TableCell>
                  <TableCell>{loanStatement.clientOfficeId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account No:</TableCell>
                  <TableCell>{loanStatement.clientAccountNo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tranche No:</TableCell>
                  <TableCell>
                    {loanStatement && loanStatement.externalId}
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </Paper>

          <Paper className={classes.tableBoxShadow}>
            <TableContainer className={classes.lsttableRight}>
              <TableBody>
                <TableRow>
                  <TableCell>Loan Id:</TableCell>
                  <TableCell>
                    {loanStatement && loanStatement.loanProductId}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Loan Name:</TableCell>
                  <TableCell>{loanStatement.loanProductName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Loan Type:</TableCell>
                  <TableCell>
                    {loanStatement &&
                      loanStatement.loanType &&
                      loanStatement.loanType.value}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Annual Interest Rate:</TableCell>
                  <TableCell>{loanStatement.annualInterestRate}%</TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </Paper>
        </div>

        <Typography variant="h5" className={classes.loanDetailsTableOneCaption}>
          Basic Details
        </Typography>

        <Grid container className={classes.cardSection}>
          <Grid item md={5}>
            <Paper className={classes.loanStatementCards}>
              <TableContainer className={classes.lsttableLeft}>
                <TableBody>
                  <TableRow>
                    <TableCell>Principal:</TableCell>
                    <TableCell>{loanStatement.principal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Approved Principal:</TableCell>
                    <TableCell>
                      {loanStatement && loanStatement.approvedPrincipal}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Proposed Principal:</TableCell>
                    <TableCell>{loanStatement.proposedPrincipal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net Disbursal Amount:</TableCell>
                    <TableCell>{loanStatement.netDisbursalAmount}</TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={5}>
            <Paper className={classes.loanStatementCards}>
              <TableContainer className={classes.lsttableLeft}>
                <TableBody>
                  <TableRow>
                    <TableCell>Repayment Frequency:</TableCell>
                    <TableCell>
                      {loanStatement.repaymentEvery}{" "}
                      {loanStatement &&
                        loanStatement.repaymentFrequencyType &&
                        loanStatement.repaymentFrequencyType.value}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Interest Rate:</TableCell>
                    <TableCell>
                      {loanStatement.interestRatePerPeriod}%{" "}
                      {loanStatement &&
                        loanStatement.interestRateFrequencyType &&
                        loanStatement.interestRateFrequencyType.value}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Interest Rate Type:</TableCell>
                    <TableCell>
                      {loanStatement.isFloatingInterestRate === false
                        ? "Nominal Interest Rate"
                        : "Floating Interest Rate"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Amortization Type:</TableCell>
                    <TableCell>
                      {loanStatement &&
                        loanStatement.amortizationType &&
                        loanStatement.amortizationType.value}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          className={classes.loanStatementSummaryTableCaption}
        >
          Summary
        </Typography>
        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.principalDisbursed}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.principalPaid}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.principalOutstanding}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.principalOverdue}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.interestCharged}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.interestPaid}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.interestWaived}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.interestOutstanding}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.interestOverdue}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.totalExpectedCostOfLoan}
                </TableCell>
                <TableCell align="center">
                  {loanStatement &&
                    loanStatement.summary &&
                    loanStatement.summary.totalExpectedRepayment}
                </TableCell>
              </TableRow>
            </TableBody>
          </TblContainer>
        </Paper>

        <Typography
          variant="h5"
          className={classes.loanStatementTransactionTableCaption}
        >
          Transactions
        </Typography>

        {/* <Paper className={classes.tableBoxShadow}> */}
          <Table className={tableClass.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr No.</TableCell>
                <TableCell align="center">Amt.</TableCell>
                <TableCell align="center">Payment Type</TableCell>
                <TableCell align="center">Net Disb. Amt</TableCell>
                <TableCell align="center">O/s Loan Balance</TableCell>
                <TableCell align="center">Date</TableCell>
                {/* <TableCell align='center'>No. of Repayments</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {loanStatement.transactions &&
                loanStatement.transactions.map((item, key) => {
                  key++;
                  return (
                    <TableRow key={key}>
                      <TableCell align="center">{key}</TableCell>
                      <TableCell align="center">{item.amount}</TableCell>
                      <TableCell align="center">
                        {item && item.type && item.type.value}
                      </TableCell>
                      <TableCell align="center">
                        {item.netDisbursalAmount}
                      </TableCell>
                      <TableCell align="center">
                        {item.outstandingLoanBalance}
                      </TableCell>
                      {/* <TableCell>{item.submittedOnDate.map((item) => (`${item}` + `-`)).reverse()}</TableCell> */}
                      {/* <TableCell>{dateFormatter(item && item.submittedOnDate)}</TableCell> */}
                      {/* <TableCell>{dateFormatter([2022, 6, 20])}</TableCell> */}
                      <TableCell align="center">
                        {dateFormatter(item.date)}
                      </TableCell>
                      {/* <TableCell align='center'>{item.numberOfRepayments}</TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      {/* </Paper> */}
    </>
  );
};

export default LoanAccountStatement;
