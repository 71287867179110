import React from 'react'

const useYAxisFormatter = () => {

  const dataFormater = (number) => {
    if (number > 10000000) {
      return (number / 10000000).toString() + 'Cr';
    } else if (number > 100000) {
      return (number / 100000).toString() + 'L';
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K';
    } else {
      return number.toString();
    }
  }

  return { dataFormater }
}

export default useYAxisFormatter