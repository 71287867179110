import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";


export const getNotifications = createAsyncThunk(
    "get-notifications",
    async (thunkAPI) => {
        try {
            const url = `loans/notifications-list`
            const res = await DataService.get(url);
            // console.log("Response", res)
            return res.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    isLoading: true,
    notificationsList: [],
    notificationCount: 0
}

const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    extraReducers: {
        [getNotifications.pending]: (state) => {
            state.isLoading = true;
        },
        [getNotifications.fulfilled]: (state, action) => {
            // console.log("Action", action)
            state.notificationsList = action.payload.data
            // state.notificationCount = action.payload.unSeen
            state.isLoading = false;
        },
        [getNotifications.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        },
    }
})

export default notificationSlice.reducer