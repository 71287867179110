import React, { useEffect } from 'react'
import { Typography, Container, } from '@material-ui/core'
import makeStyles from './LoanStatementStyle'
import makemyStyles from '../../Sharedfeatures/SharedStyles'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageHeader from '../../Common/PageHeader'
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux'
import { getLoanStatement } from '../../../slice/loansanctioned'
import { useParams } from 'react-router-dom'
import { LOAN_GEN_LIST } from '../../../utils/links'
import { LOAN_STATEMENT_HEADER, LOAN_STATEMENT_SUBHEADER, BACK_BUTTON } from '../../../utils/pageHeadings'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1A0045',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  { id: "principal", label: "Principal" },
  { id: "approvedPrincipal", label: "Approved Principal" },
  { id: "proposedPrincipal", label: "Proposed Principal" },
  { id: "netDisbursalAmount", label: "Net Disbursal Amount" },
  { id: "termFrequency", label: "Term Frequency" },
  { id: "numberOfRepayments", label: " Number Of Repayments" },
  { id: "repaymentEvery", label: "Repayment Every" },
  { id: "interestRatePerPeriod", label: "Interest Rate Per Period" },
  { id: "interestType", label: "Interest Type" },
  { id: "amortizationType", label: "Amortization Type" }
]

const tableData = [
  { cardtype: 'Visa Gold', balance: '7126.24', payment: '7126.24', purchases: '3898.57', installment: '0.00', financecharges: '0.00', latepayments: '0.00', amountdue: '0.00' },
  { cardtype: 'Visa Gold', balance: '7126.24', payment: '7126.24', purchases: '3898.57', installment: '0.00', financecharges: '0.00', latepayments: '0.00', amountdue: '0.00' },
  { cardtype: 'Visa Gold', balance: '7126.24', payment: '7126.24', purchases: '3898.57', installment: '0.00', financecharges: '0.00', latepayments: '0.00', amountdue: '0.00' },
  { cardtype: 'Visa Gold', balance: '7126.24', payment: '7126.24', purchases: '3898.57', installment: '0.00', financecharges: '0.00', latepayments: '0.00', amountdue: '0.00' },
]


const StyledTableCellTwo = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1A0045',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowTwo = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCellsTwo = [
  { id: "id", label: "ID" },
  { id: "officeid", label: "Office ID" },
  { id: "officename", label: "Office Name" },
  { id: "amount", label: "Amount" },
  { id: "paymenttype", label: "Payment Type" },
  { id: "netdisbursalamount", label: "Net Disbursal Amount" },
  { id: "outstandingloanbalance", label: "Outstanding Loan Balance" },
  { id: "submissiondate", label: "Submission Date" },
  { id: "noofrepayments", label: "Number of Repayments" },
  { id: "currency", label: "Currency" },
]

const tableDataTwo = [
  { date: 'mm/dd/yy', description: 'Petron - C5 Station', amount: '223.26', },
  { date: 'mm/dd/yy', description: 'Petron - C5 Station', amount: '223.26', },
  { date: 'mm/dd/yy', description: 'Petron - C5 Station', amount: '223.26', },
  { date: 'mm/dd/yy', description: 'Petron - C5 Station', amount: '223.26', },
  { date: 'mm/dd/yy', description: 'Petron - C5 Station', amount: '223.26', },
]

const headCellsThree = [
  { id: "id", label: "ID" },
  { id: "expectedDisbursementDate", label: "Expected Disbursement Date" },
  { id: "actualDisbursementDate", label: "Actual Disbursement Date" },
  { id: "principal", label: "Principal" },
  { id: "netDisbursalAmount", label: "Net Disbursal Amount" },
]

const LoanStatements = () => {

  const classes = makemyStyles();
  const localStyles = makeStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loanStatement } = useSelector((state) => state.loanSanctioned)

  useEffect(() => {
    dispatch(getLoanStatement(id))
  }, [dispatch, id])


  return (
    <>
      <Paper className={classes.pageContent}>

        <PageHeader header={LOAN_STATEMENT_HEADER} subHeader={LOAN_STATEMENT_SUBHEADER} icon={BACK_BUTTON} link={LOAN_GEN_LIST} />

        <Container className={localStyles.container}>
          <TableContainer className={localStyles.tableLeft}>
            <TableBody>
              <TableRow>
                <TableCell>Name:</TableCell>
                <TableCell>{loanStatement.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Client Office Id:</TableCell>
                <TableCell>{loanStatement.clientOfficeId}</TableCell>
              </TableRow>
            </TableBody>
          </TableContainer>

          <TableContainer className={localStyles.tableRight}>
            <TableBody>
              <TableRow>
                <TableCell>Account Number:</TableCell>
                <TableCell>{loanStatement.clientAccountNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Loan Product Name</TableCell>
                <TableCell>{loanStatement.loanProductName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Loan Type</TableCell>
                <TableCell>{loanStatement && loanStatement.loanType && loanStatement.loanType.value}</TableCell>
              </TableRow>
            </TableBody>
          </TableContainer>
        </Container>

        <Container>
          <Typography variant="h5" gutterBottom
            className={localStyles.typography}>Summary</Typography>
        </Container>

        <TableContainer component={Paper} className={localStyles.table}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {
                  headCells && headCells.map((item, key) => {
                    return <StyledTableCell key={key} align="center">{item.label}</StyledTableCell>
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="center">{loanStatement.principal}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.approvedPrincipal}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.proposedPrincipal}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.netDisbursalAmount}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.termFrequency} {loanStatement && loanStatement.termPeriodFrequencyType && loanStatement.termPeriodFrequencyType.value}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.numberOfRepayments}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.repaymentEvery} {loanStatement && loanStatement.repaymentEvery && loanStatement.repaymentFrequencyType.value}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement.interestRatePerPeriod}% {loanStatement && loanStatement.interestRateFrequencyType && loanStatement.interestRateFrequencyType.value}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement && loanStatement.interestType && loanStatement.interestType.value}</StyledTableCell>
                <StyledTableCell align="center">{loanStatement && loanStatement.amortizationType && loanStatement.amortizationType.value}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>


        <Container>
          <Typography variant="h5" gutterBottom className={localStyles.typography}>Transaction</Typography>
        </Container>

        <TableContainer component={Paper} className={localStyles.table}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {
                  headCellsTwo && headCellsTwo.map((item, key) => {
                    return <StyledTableCellTwo key={key} align="center">{item.label}</StyledTableCellTwo>
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loanStatement.transactions && loanStatement.transactions.map((item, key) => {
                  return (
                    <StyledTableRowTwo key={key}>
                      <StyledTableCellTwo align="center">{item.id}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.officeId}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.officeName}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.amount}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item && item.type && item.type.value}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.netDisbursalAmount}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.outstandingLoanBalance}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.submittedOnDate}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.numberOfRepayments}</StyledTableCellTwo>
                      <StyledTableCellTwo align="center">{item.currency.displayLabel}</StyledTableCellTwo>
                    </StyledTableRowTwo>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>


        <Container>
          <Typography variant="h5" gutterBottom className={localStyles.typography}>Disbursement Details</Typography>
        </Container>
        
        <TableContainer component={Paper} className={classes.table}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {headCellsThree &&
                  headCellsThree.map((item, key) => {
                    return (
                      <StyledTableCellTwo key={key} align="center">
                        {item.label}
                      </StyledTableCellTwo>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loanStatement.disbursementDetails &&
                loanStatement.disbursementDetails.map((item, key) => {
                  return (
                    <StyledTableRowTwo key={key}>
                      <StyledTableCellTwo align="center">
                        {item.id}
                      </StyledTableCellTwo>
                      <StyledTableCellTwo align="center">
                        {item.expectedDisbursementDate}
                      </StyledTableCellTwo>
                      <StyledTableCellTwo align="center">
                        {item.actualDisbursementDate}
                      </StyledTableCellTwo>
                      <StyledTableCellTwo align="center">
                        {item.principal}
                      </StyledTableCellTwo>
                      <StyledTableCellTwo align="center">
                        {item.netDisbursalAmount}
                      </StyledTableCellTwo>
                    </StyledTableRowTwo>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
    </>
  )
}

export default LoanStatements