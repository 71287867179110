import React, { useContext } from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import AppDetailsStepper from './AppDetailsStepper';
import useStyles from './LoanAppStyles';
import { ApplicationContext } from './LoanApplication';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    // height: 575,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    p: 3
};

const LoanAppDetailsModal = ({ open, handleClose }) => {
    const applicationDetails = useContext(ApplicationContext)
console.log('applicationDetails :>> ', applicationDetails);
    const classes = useStyles()

    return (
        <>
            <Modal open={open}>
                <Box sx={style}>

                    <Box className={classes.loanAppDetailParent}>
                        <IconButton onClick={handleClose}>
                            <AiOutlineClose />
                        </IconButton>
                    </Box>

                    <Typography
                        variant="h5"
                        color={'#963460'}
                        fontWeight={'bold'}
                        textAlign={'center'}
                    >
                       {`Loan Application Details${applicationDetails?.loanApplicationNumber ? ` - ${applicationDetails.loanApplicationNumber}` : ''}`}
                    </Typography>

                    <AppDetailsStepper handleClose={handleClose} />

                </Box>
            </Modal>
        </>
    )
}

export default LoanAppDetailsModal