import Login from './components/Login/Login'
import Logout from './components/Login/Logout'
import CustomerForm from './components/Customers/CustomerForm'
import CustomerList from './components/Customers/CustomerList'
import LoanSanctioned from './components/LoanSanctioned/LoanSanctioned.jsx'
import CustomerDetails from './components/Customers/customerDetails.jsx'
import Fos from './components/Fos/Fos'
import FosForm from './components/Fos/FosForm.jsx'
import RegionList from './components/RegionManagement/RegionList';
import NewRegion from './components/RegionManagement/NewRegion';
import Preapproved from './components/Preapprovedloanoffer/Preapproved.jsx'
import SalesData from './components/Sales/SalesData.jsx'
import PageNotFound from './components/PageNotFound/PageNotFound.jsx'
import Loandetails from './components/Preapprovedloanoffer/Loandetails.jsx'
import LoanStatement from './components/LoanSanctioned/LoanStatement.jsx'
import Preapprovedsales from './components/Preapprovedloanoffer/Preapprovedsales.jsx'
import LoanDisbursed from './components/LoanDisbursed/LoanDisbursed.jsx'
import LoanProducts from './components/LoanProducts/LoanProducts.jsx'
import LoanGenerated from './components/LoanGenerated/LoanGenerated.jsx'
import LoanStatements from './components/LoanGenerated/LoanSanctioned/LoanStatement.jsx'
import Repayment from './components/Repayment/Repayment.jsx'
import Dashboardthree from './components/DashThree/Dashboardthree.jsx'
import LoanViewDetails from './components/LoanProducts/LoanViewDetails'
import LoanAccountDetails from './components/LoanSanctioned/LoanAccountDetails'
import LoanAccountStatement from './components/LoanSanctioned/LoanAccountStatement'
//Icons
import { RiDashboardLine, RiExchangeDollarLine } from 'react-icons/ri';
import { FaGlobeAmericas, FaMoneyCheckAlt } from 'react-icons/fa'
import { BsPeopleFill, BsCurrencyDollar, BsPersonFill, BsGraphUp, BsFillFilePostFill } from 'react-icons/bs'
import { MdPayment, MdLogout, MdOutlinePayments, MdViewCarousel } from 'react-icons/md'
import { IoIosCash } from 'react-icons/io'
import LoansDefaulted from './components/LoansDefaulted/LoansDefaulted'
import LoansClosed from './components/LoansClosed/LoansClosed'
import Redirect from './components/Sharedfeatures/Redirect'
import LoanApplication from './components/LoanApplications/LoanApplication'
import UpcomingRepayments from './components/UpcomingRepayments/UpcomingRepayments'
import TranchesList from './components/TranchesList/TranchesList'
import LoanAppParentTab from './components/LoanApplications/LoanAppParentTab'



var routes = [
  // {
  //   collapse: false,
  //   path: "*",
  //   layout: "/admin",
  //   name: "Page Not Found",
  //   state: "page not found",
  //   key: 'pagenotfound',
  //   show: false,
  //   component: PageNotFound,
  // },
  {
    collapse: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    state: "dashboard",
    key: 'dashboard',
    show: true,
    icon: <RiDashboardLine />,
    component: Dashboardthree,
  },
  {
    collapse: true,
    path: "/regionlist",
    layout: "/admin",
    name: "Regions",
    state: "regions",
    key: 'regions',
    show: true,
    icon: <FaGlobeAmericas />,
    component: RegionList,
  },
  {
    collapse: false,
    path: "/fos/addFos",
    layout: "/admin",
    name: "Add Fos",
    state: "add fos",
    key: 'addfos',
    show: false,
    component: FosForm,
  },
  {
    collapse: false,
    path: "/fos/editfos/:id",
    layout: "/admin",
    name: "Edit Fos",
    state: "edit fos",
    key: 'editfos',
    show: false,
    component: FosForm,
  },
  {
    collapse: false,
    path: "/customerdetails/:_id",
    layout: "/admin",
    name: "Customer Details",
    state: "Customer Details",
    key: 'customerdetails',
    show: false,
    component: CustomerDetails,
  },
  {
    collapse: false,
    path: "/addcustomer",
    layout: "/admin",
    name: "Add Customer",
    state: "Add Customer",
    key: 'addcustomer',
    show: false,
    component: CustomerForm,
  },
  {
    collapse: false,
    path: "/editcustomer/:id",
    layout: "/admin",
    name: "Edit customer",
    state: "Edit customer",
    key: 'editcustomer',
    show: false,
    component: CustomerForm,
  },
  {
    collapse: true,
    path: "/fos",
    layout: "/admin",
    name: "FOS",
    state: "fos",
    key: 'fos',
    show: true,
    icon: <BsPersonFill />,
    component: Fos,
  },
  {
    collapse: true,
    path: "/customerlist",
    layout: "/admin",
    name: "Customers",
    state: 'customers',
    key: 'customers',
    show: true,
    icon: <BsPeopleFill />,
    component: CustomerList,
  },
  {
    collapse: true,
    path: "/salesdata",
    layout: "/admin",
    name: "Sales Data",
    state: 'sales data',
    key: 'salesdata',
    show: true,
    icon: <BsGraphUp />,
    component: SalesData,
  },
  {
    collapse: true,
    path: "/loanproducts",
    layout: "/admin",
    name: "Loan Products",
    state: "loanlist",
    key: 'loanlist',
    show: true,
    icon: <BsCurrencyDollar />,
    component: LoanProducts,
  },
  {
    collapse: true,
    path: "/loanapplications",
    layout: "/admin",
    name: "Loan Applications",
    state: "loanapplications",
    key: 'loanapplications',
    show: true,
    icon: <BsFillFilePostFill />,
    component: LoanAppParentTab,
  },
  {
    collapse: false,
    path: "/loandetails/:_id",
    layout: "/admin",
    name: "Loan Details",
    state: "Loan Details",
    key: 'loandetails',
    show: false,
    component: LoanViewDetails,
  },

  {
    collapse: true,
    path: "/preapprovedloan",
    layout: "/admin",
    name: "Pre Approved Loan Offer",
    state: "pre approved",
    key: 'preapproved',
    show: true,
    icon: <MdPayment />,
    component: Preapproved,
  },
  {
    collapse: true,
    path: "/loangenerated",
    layout: "/admin",
    name: "Loan Generated",
    state: "loan generated",
    key: 'loangenerated',
    show: true,
    icon: <RiExchangeDollarLine />,
    // component: LoanGenerated,
    views: [
      {
        collapse: true,
        path: "/loansanctioned",
        layout: "/admin",
        name: "Loan Sanctioned",
        state: "loan sanctioned",
        key: 'loansanctioned',
        show: true,
        icon: <FaMoneyCheckAlt />,
        component: LoanSanctioned,
      },
      {
        collapse: true,
        path: "/loandisbursed",
        layout: "/admin",
        name: "Loan Disbursed",
        state: "loan disbursed",
        key: 'loandisbursed',
        show: true,
        icon: <IoIosCash />,
        component: LoanDisbursed,
      },
      {
        collapse: true,
        path: "/loandefaulted",
        layout: "/admin",
        name: "Loan Defaulted",
        state: "loan defaulted",
        key: 'loandefaulted',
        show: true,
        icon: <FaMoneyCheckAlt />,
        component: LoansDefaulted,
      },
      {
        collapse: true,
        path: "/loanclosed",
        layout: "/admin",
        name: "Loan Closed",
        state: "loan closed",
        key: 'loanclosed',
        show: true,
        icon: <IoIosCash />,
        component: LoansClosed,
      },
    ]
  },
  {
    collapse: true,
    path: "/repayment",
    layout: "/admin",
    name: "Latest Repayments",
    state: "repayments",
    key: 'repayments',
    show: true,
    icon: <MdPayment />,
    component: Repayment,
  },
  {
    collapse: true,
    path: "/upcomingrepayments",
    layout: "/admin",
    name: "Upcoming Repayments",
    state: "upcomingrepayments",
    key: 'upcomingrepayments',
    show: true,
    icon: <MdOutlinePayments />,
    component: UpcomingRepayments,
  },
  {
    collapse: true,
    path: "/tranchescreated",
    layout: "/admin",
    name: "Tranches Created",
    state: "tranches",
    key: 'tranches',
    show: true,
    icon: <MdViewCarousel />,
    component: TranchesList,
  },
  // {
  //   collapse: false,
  //   path: "/loangenerated/loansanctioned/loanstatement/:id",
  //   layout: "/admin",
  //   name: "Loan Statement",
  //   state: "loanstatement",
  //   key: 'loanstatement',
  //   show: false,
  //   component: LoanStatements,
  // },
  // {
  //   collapse: false,
  //   path: "/preapprovedsales",
  //   layout: "/admin",
  //   name: "Pre Approved Sales",
  //   state: "pre approved",
  //   show: true,
  //   component: Preapprovedsales,
  // },
  {
    collapse: false,
    path: "/preapprovedloan/loandetails/:id",
    layout: "/admin",
    name: "Loan Details",
    state: "loandetails",
    key: 'loan details',
    show: false,
    component: Loandetails,
  },
  {
    collapse: false,
    path: "/loansanctioned",
    layout: "/admin",
    name: "Loan Sanctioned",
    state: "loansanctioned",
    key: 'loansanctioned',
    show: false,
    component: LoanSanctioned,
  },
  {
    collapse: false,
    path: "/loansanctioned/loanaccountdetails/:clientId",
    layout: "/admin",
    name: "Loan Account Details",
    state: "loanaccountdetails",
    key: 'loanaccountdetails',
    show: false,
    component: LoanAccountDetails,
  },
  // {
  //   collapse: false,
  //   path: "/loansanctioned/loanstatement/:id",
  //   layout: "/admin",
  //   name: "Loan Statement",
  //   state: "loanstatement",
  //   key: 'loanstatement',
  //   show: false,
  //   component: LoanStatement,
  // },
  {
    collapse: false,
    path: "/loansanctioned/loanaccountdetails/loanaccountstatement/:id/:clientId",
    layout: "/admin",
    name: "Loan Statement",
    state: "loanstatement",
    key: 'loanstatement',
    show: false,
    component: LoanAccountStatement,
  },
  {
    collapse: false,
    path: "/loandisbursed",
    layout: "/admin",
    name: "Loan Disbursed",
    state: "loandisbursed",
    key: 'loandisbursed',
    show: false,
    component: LoanDisbursed,
  },
  {
    collapse: false,
    path: "/addregion",
    layout: "/admin",
    name: "Add Region",
    state: "add Region",
    key: 'addregion',
    show: false,
    component: NewRegion,
  },
  {
    collapse: false,
    path: "/loandefaulted",
    layout: "/admin",
    name: "Loan Defaulted",
    state: "loan defaulted",
    key: 'loandefaulted',
    show: false,
    icon: <FaMoneyCheckAlt />,
    component: LoansDefaulted,
  },
  {
    collapse: false,
    path: "/loanclosed",
    layout: "/admin",
    name: "Loan Closed",
    state: "loan closed",
    key: 'loanclosed',
    show: false,
    icon: <IoIosCash />,
    component: LoansClosed,
  },
  {
    collapse: false,
    path: "/",
    layout: "/admin",
    name: "Redirect",
    state: "redirect",
    key: 'redirect',
    show: false,
    icon: <IoIosCash />,
    component: Redirect,
  },
  {
    collapse: false,
    path: "/editRegion/:id",
    layout: "/admin",
    name: "Edit Region",
    state: "edit region",
    key: 'editregion',
    show: false,
    component: NewRegion,
  },
  {
    collapse: false,
    path: "/login",
    layout: "/auth",
    name: "Login",
    state: "login",
    key: 'login',
    show: false,
    component: Login
  },
  {
    collapse: true,
    path: "/logout",
    layout: "/auth",
    name: "Logout",
    state: "logout",
    key: 'logout',
    show: true,
    icon: <MdLogout />,
    component: Logout,
  },
]

export default routes