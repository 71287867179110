import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanApplications = createAsyncThunk(
    "getLoanApplications",
    async (data, thunkAPI) => {
        const { pageNo, search, fromDate, toDate } = data;
        try {
            const url = `/loans/loan-applications?searchqry=${search}&page=${pageNo}&limit=10&fromDate=${fromDate}&toDate=${toDate}`;
            const res = await DataService.get(url);
            return res.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

//download loanapplication excel
export const getLoanApplicationsExcel = createAsyncThunk(
    "getloanapplicationsexcel",
    async (data) => {
        const { pageNo, search, fromDate, toDate, totalCount } = data
        const url = search ? `/loans/loan-applications?searchqry=${search}&page=${pageNo}&limit=10&list_type=excel&fromDate=${fromDate}&toDate=${toDate}`
            : `/loans/loan-applications?page=1&limit=${totalCount}&list_type=excel&fromDate=${fromDate}&toDate=${toDate}`
        const res = await DataService.get(url);
        return res.data;
    }
);

export const getLoanApplicationDetails = createAsyncThunk(
    "getApplicationDetails",
    async (data, thunkAPI) => {
        try {
            const url = `/loans/loan-applicant/${data}`;
            const res = await DataService.get(url);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getRejectedApplicatons = createAsyncThunk(
    "getRejectedApplications",
    async (data, thunkAPI) => {
        const { pageNo, search } = data;
        try {
            const url = `/loans/rejected-loanapplications?searchqry=${search}&page=${pageNo}&limit=10`;
            const res = await DataService.get(url);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getIncompleteApplicationList = createAsyncThunk(
    "incompleteApplicationsList",
    async ({ pageNum, searchQry }) => {
        const url = `/loans/incomplete-applications?searchqry=${searchQry}&page=${pageNum}&limit=10`;
        const res = await DataService.get(url);
        console.log("response", res)
        return res.data
    }
)

export const getRejectedDocs = createAsyncThunk(
    "getRejectedDocs",
    async (data, thunkAPI) => {
        try {
            const url = `/documentation/rejected-docs-list/${data}`;
            const res = await DataService.get(url);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const initialState = {
    applicationList: [],
    analyticsData: [],
    loanApplicationData: [],
    incompApplications: [],
    applicationDetails: {},
    rejectedList: [],
    rejectedDocs: [],
    isLoading: true,
    totalCount: ''
}

const getApplicationList = createSlice({
    name: "applicationList",
    initialState,
    extraReducers: {
        [getLoanApplications.pending]: (state) => {
            state.isLoading = true;
        },
        [getLoanApplications.fulfilled]: (state, action) => {
            // console.log("LOANAPPL", action)
            state.isLoading = false;
            state.applicationList = action.payload.data;
            state.analyticsData = action.payload;
            state.totalCount = action.payload.totalItmes;
        },
        [getLoanApplications.rejected]: (state) => {
            state.isLoading = false;
        },
        [getLoanApplicationsExcel.fulfilled]: (state, action) => {
            state.loanApplicationData = action.payload.data
        },
        [getLoanApplicationDetails.fulfilled]: (state, action) => {
            // console.log("LoanACT", action)
            state.applicationDetails = action.payload.data;
        },
        [getRejectedApplicatons.pending]: (state) => {
            state.isLoading = true;
        },
        [getRejectedApplicatons.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.rejectedList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
        },
        [getRejectedApplicatons.rejected]: (state) => {
            state.isLoading = false;
        },

        [getIncompleteApplicationList.pending]: (state, action) => {
            state.isLoading = true;
        },
        [getIncompleteApplicationList.fulfilled]: (state, action) => {
            // console.log("Act", action)
            state.incompApplications = action.payload.data
            state.totalCount = action.payload.totalItmes
            state.isLoading = false
        },
        [getIncompleteApplicationList.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        },

        [getRejectedDocs.fulfilled]: (state, action) => {
            // console.log("ACT", action)
            state.rejectedDocs = action.payload.data;
        }
    },
    reducers: {
        resetStateValues: (state) => {
            state.applicationDetails = {};
        },
    }
})

export const { resetStateValues } = getApplicationList.actions
export default getApplicationList.reducer
