
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../services/DataService";

export const getApplicationStatus = createAsyncThunk('getApplicationStatus', async ()=>{
    try{
        const url = `/console-web/application-status`
        const response = await DataService.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    isLoading : true,
    applicationStatusData : '',
}

const applicationStatusSlice = createSlice({
    name : 'applicationstatusslice',
    initialState,
    extraReducers:{
        [getApplicationStatus.pending]: (state) => {
            return {...state, isLoading:true}
        },
        [getApplicationStatus.fulfilled]:(state,{payload})=>{
            return {...state, applicationStatusData:payload?.data, isLoading:false}
        },
    }
})
export const getApplicationStatusData = (state) => state.applicationstatusapi;
export default applicationStatusSlice.reducer