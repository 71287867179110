import { Table, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import Pagination from "@mui/material/Pagination";
import React, { useState } from 'react'
import { PAGE_SIZE } from '../../utils/variable'

const useStyles = makeStyles(theme => ({
    table: {
        //marginTop: '100px',
        '&': {
            borderRadius: '20px',
        },
        '& thead th': {
            fontWeight: '600',
            fontSize: '15px',
            letter: '8%',
            color: "#f8f7fc",
            lineHeight: '22.5px',
            backgroundColor: "#1A0045",
        },
        '& tbody td': {
            fontWeight: '500',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
        },
        "& thead th:first-child": {
            borderRadius: "15px 0 0 15px"
        },
        "& thead th:last-child": {
            borderRadius: "0 15px 15px 0"
        },
    },
    pagentn: {
        marginTop: '25px',
        boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
        maxWidth: '26vw',
    },
}))

export default function useTable(records, headCells, totalCount) {
    const classes = useStyles();
    const [pageNo, setPageNo] = useState(1);
    const [slNo, setSlNo] = useState(0);

    const handleChange = (event, value) => {
        setPageNo(value);
        setSlNo(value - 1);
    };

    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    )

    const TblHead = props => (
        <TableHead>
            <TableRow>
                {
                    headCells.map(headCell => (
                        <TableCell key={headCell.label}>
                            {headCell.label}
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )

    const TblPagination = () => (
        <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE)}
            page={pageNo}
            onChange={handleChange}
            className={classes.pagentn}
        />
    );
    return {
        TblContainer,
        TblHead,
        TblPagination,
        pageNo,
        slNo,
        handleChange
    };
}
