import React from 'react'
import './pagelayout.css'
import Sidebar from './Sidebar'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNew from '../components/Header/HeaderNew';
import MainContent from './MainContent';

const PageLayout = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    useEffect(()=>{
        function handleResize() {
            if(window.innerWidth < 1080){
                setOpen(false)
            }else{
                setOpen(true)
            }
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener('load', handleResize)
    })
    return (
        <div className="atlas-pagelayout">
            <Sidebar open={open} setOpen={setOpen}/>
            <div className={`content-wrap ${open ? 'open' : 'close'}`}>
                <div className="header-wrap">
                    <HeaderNew/>
                </div>
                <div className="maincontent-wrap" >
                    <MainContent/>
                </div>
            </div>
        </div>
    )
}

export default PageLayout