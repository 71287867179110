import React, { useEffect } from 'react'
import { TableRow, Box, TableCell, TableBody, IconButton, Typography } from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import useTable from "../Sharedfeatures/useTable";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import HeadCells from "../Common/HeadCells";
import PageHeader from "../Common/PageHeader";
import ProgressSpinner from "./ProgressSpinner";
import { REPAYMENT_DETAILS_HEADER, REPAYMENT_DETAILS_SUB_HEADER } from '../../utils/pageHeadings';
import { getRepaymentDetails } from '../../slice/loandisbursed';


const RepaymentModal = ({ open, onClose, id }) => {

    const dispatch = useDispatch();
    const classes = makemyStyles();
    const moment = require("moment");
    const { repayDetails, isLoading } = useSelector((store) => store.loanDisbursed)

    useEffect(() => {
        dispatch(getRepaymentDetails(id));
    }, []);

    const { TblContainer, TblHead } = useTable(repayDetails, HeadCells.repaymentModal);

    const style = {
        position: "absolute",
        top: "50%",
        left: "57%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        maxHeight: "70%",
        overflow: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "15px",
        p: 7,
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <PageHeader header={REPAYMENT_DETAILS_HEADER} />

                    <IconButton style={{ position: "absolute", right: "20px", top: "20px" }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>

                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={7} align='center'>
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (repayDetails && repayDetails.length !== 0) ? (
                                            repayDetails &&
                                            repayDetails.map(({ trancheid, bank_reference_number, amount, principal_portion, interest_portion, transaction_date, penalty }, key) => {
                                                key++;
                                                return (
                                                    <TableRow key={key.toString()}>
                                                        <TableCell>{key}</TableCell>
                                                        <TableCell>{trancheid}</TableCell>
                                                        <TableCell>{Number(amount).toFixed(2)}</TableCell>
                                                        <TableCell>{Number(principal_portion).toFixed(2)}</TableCell>
                                                        <TableCell>{Number(interest_portion).toFixed(2)}</TableCell>
                                                        <TableCell align='center'>{penalty}</TableCell>
                                                        <TableCell align='center'>
                                                            {moment(transaction_date).format("DD MMMM YYYY")}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })) : (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    No Data to Show
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>


                </Box>
            </Modal>
        </>
    )
}

export default RepaymentModal