import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { formatRupees } from '../../../utils/formatRupees';


const ApplicationStatusCards = ({ cardData }) => {

    return (

        <div className="cards-container">
            <div className="card revenue displayflexcol">
                <div className="card-header"><CurrencyRupeeIcon className='rupee' />{formatRupees(cardData?.total_revenue)}</div>
                <div className="card-content">Total Revenue Collected.</div>
            </div>

            <div className="card tranches displayflexcol">
                <div className="card-header"><CurrencyRupeeIcon className='rupee' />{formatRupees(cardData?.tranches_disbursed)}</div>
                <div className="card-content">Tranches Disbursed.</div>
            </div>

            <div className="card received displayflexcol">
                <div className="card-header">{cardData?.total_applications}</div>
                <div className="card-content">Total Applications Received</div>
            </div>
        </div>
    )
}

export default React.memo(ApplicationStatusCards)
