import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './App.css';
import Admin from './Layout/Admin'
import Auth from './Layout/Auth'
import Login from './components/Login/Login'
import { Routes, Route } from 'react-router-dom';
// import { ThemeProvider } from '@material-ui/core/styles';
// import { createTheme } from '@material-ui/core/styles'
import PageNotFound from "./components/PageNotFound/PageNotFound";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import ProtectedRoutes from "./components/Sharedfeatures/ProtectedRoutes";
import PageLayout from "./Layout/PageLayout";



function App() {
  let navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  // useEffect(() => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (accessToken === null || accessToken === ' undefined') {
  //     navigate("/auth/login");
  //   }
  //   // window.unload(function(){
  //   //   localStorage.clear();
  //   // });
  // }, []);

  return (
    <div className="">
      <Routes>
        <Route path="/admin/*" element={<ProtectedRoutes accessToken={accessToken}><PageLayout/></ProtectedRoutes>} /> :
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path='/resetpassword/:id/:token' element={<ResetPassword />} />
        {/* {
            window.addEventListener("beforeunload", (ev) => {
              localStorage.removeItem("accessToken");
              ev.preventDefault();
              return ev.returnValue = 'Are you sure you want to close?';
            })
          } */}
      </Routes>
    </div>
  );
}

export default App;
