import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getTranchesList = createAsyncThunk(
    'getTranchesList',
    async (data, thunkAPI) => {
        const { fromDate, toDate, pageNo, searchTerm } = data;
        try {
            const url = `/loans/tranches-list?fromDate=${fromDate}&toDate=${toDate}&page=${pageNo}&searchqry=${searchTerm}&limit=10`;
            const res = await DataService.get(url);
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

//download Tranche Data excel
export const getTranchesDataExcel = createAsyncThunk(
    "TranchDataExcel",
    async (data, thunkAPI) => {
        const { fromDate, toDate, pageNo, searchTerm, totalCount } = data;
        try {
            const url = searchTerm ?
                `loans/disbursed-tranches-list?page=1&limit=${totalCount}&searchqry=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}` :
                `loans/disbursed-tranches-list?page=1&limit=${totalCount}&fromDate=${fromDate}&toDate=${toDate}`
            const res = await DataService.get(url);
            console.log("RES=>", res)
            return res.data
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    tranchesList: [],
    tranchDataExcel: [],
    isLoading: true,
    totalCount: ''
}

const getTranches = createSlice({
    name: 'getTranches',
    initialState,
    extraReducers: {
        [getTranchesList.pending]: (state) => {
            state.isLoading = true;
        },
        [getTranchesList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.tranchesList = action.payload.data;
            state.totalCount = action.payload.totalItmes;
        },
        [getTranchesList.rejected]: (state) => {
            state.isLoading = false;
        },
        [getTranchesDataExcel.fulfilled]: (state, action) => {
            console.log("action=>", action)
            state.tranchDataExcel = action.payload.data;
        },
    }
})

export default getTranches.reducer