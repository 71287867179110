import React, { useState, useEffect } from "react";
import { Paper, TableBody, TableRow, TableCell, TextField, IconButton, Link, Button } from "@material-ui/core";
import useTable from "../Sharedfeatures/useTable";
import { useDispatch, useSelector } from "react-redux";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { uploadSalesData, getSalesDataList, getSalesDataExcel } from "../../slice/salesdata";
import { BsSearch } from "react-icons/bs";
import ImportModal from "../Common/ImportModal";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { SALES_DATA_HEADER, SALES_DATA_SUBHEADER, SALES_MODAL_HEADER } from "../../utils/pageHeadings";
import { SALES_DATA_URL } from "../../utils/links";
import { DatePicker } from 'antd';
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";


const SalesData = () => {

  //For getting today and yesterday date
  let today = new Date();
  let yesterday = new Date();
  let today_dd = String(today.getDate()).padStart(2, '0');
  let yest_dd = String(today.getDate() - 1).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + today_dd;
  yesterday = yyyy + '-' + mm + '-' + yest_dd;


  const classes = makemyStyles();
  const [open, setOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false)
  const [fileType, setFileType] = useState(true);
  const dispatch = useDispatch();
  const moment = require("moment");
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState('')
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const { RangePicker } = DatePicker;
  const excelData = useExcelDownload();
  const [excelSheetData, setExcelSheetData] = useState([]);

  const { salesDataList, salesDataExcel, message, requestId, totalCount, isLoading } = useSelector((state) => state.salesDataUpload);
  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(salesDataList, HeadCells.salesHead, totalCount);

  const onDateChange = (dates, dateStrings) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  }

  const URL = process.env.REACT_APP_API_BASE_URL;
  const onBtnClick = () => {
    setOpen(!open);
  };

  const readExcel = (e) => {
    const data = e.target.files[0];
    if (
      data.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileType(false);
      setTimeout(() => {
        setFileType(true);
      }, 3000);
    } else {
      const formData = new FormData();
      formData.append("sales_data", data, data.name);
      dispatch(uploadSalesData(formData))
        .then((res) => {
          if (res.payload.message === 'File uploaded!' && res.payload.success === true) {
            setFileUploaded(true)
            setTimeout(() => {
              setFileUploaded(false)
              onBtnClick()
            }, 2000)
          }
        })
        .catch((error) => {
        })
    }
  };

  useEffect(() => {
    const data = {
      searchTerm: search,
      pageNumb: pageNo,
      fromDate,
      toDate,
      totalCount
    }
    dispatch(getSalesDataList(data));
    dispatch(getSalesDataExcel(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

  let serialNo = PAGE_SIZE * slNo;

  // useEffect(() => {
  //   console.log(slNo)
  // }, [slNo])

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    salesDataExcel?.map(item => {
      excelsheetdata.push({
        "FileName": item.fileName,
        "Created On": moment(item.createdAt).format('DD MMMM YYYY'),
        "Updated On": moment(item.updatedAt).format('DD MMMM YYYY'),
        "Status": item.status,
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [salesDataExcel])

  return (
    <div>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={SALES_DATA_HEADER}
          subHeader={SALES_DATA_SUBHEADER}
        />
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
          <TextField
            size="small"
            className={classes.searchTextBar}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            id="standard-bare"
            variant="outlined"
            placeholder="Search Sales Data"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <BsSearch className={classes.searchIconColour} />
                </IconButton>
              ),
            }}
          />
          <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'column' }} >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
              <Button
                className={classes.addCustomerOne}
                onClick={() =>
                  excelData.generateExcelFile(
                    excelSheetData,
                    "SalesDataSheet",
                    "SalesData.xlsx"
                  )
                }
                endIcon={<DownloadIcon />}
              >
                Download Report
              </Button>
              <button className={classes.newButton} onClick={onBtnClick}> IMPORT DATA </button>
            </div>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', justifyContent: 'space-between' }} >
              <RangePicker
                placeholder={["From Date", "To Date"]}
                format={dateFormat}
                className={classes.datePickerOne}
                onChange={onDateChange}
              />
              <div>
                <Link href={`${URL}${SALES_DATA_URL}`} target="blank">Click here to download Sample Format</Link>
              </div>
            </div>
          </div>
        </div>

        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align='center' className={classes.tableCell}>
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {
                  (salesDataList.length !== 0) ?
                    (
                      salesDataList &&
                      salesDataList.map((item, key) => {
                        key++;
                        serialNo++;
                        return (
                          <TableRow key={key.toString()}>
                            <TableCell className={classes.tableCell}>
                              {serialNo}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.fileName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(item.createdAt).format("DD MMMM YYYY")}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.status}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align='center'>No Data to Show</TableCell>
                      </TableRow>
                    )
                }
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {open ? (
          <ImportModal
            open={open}
            onClose={onBtnClick}
            title={SALES_MODAL_HEADER}
            readExcel={readExcel}
            fileType={fileType}
            message={message}
            requestId={requestId}
            fileUploaded={fileUploaded}
          />
        ) : null}
        {showPageNtn ? <TblPagination /> : ""}
      </Paper>
    </div>
  );
};

export default SalesData;
