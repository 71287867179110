import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { useForm } from '../Sharedfeatures/useForm';
import { repayAmount } from '../../slice/upcomingrepayments';
import Modal from "@mui/material/Modal";
import { Box, FormControl } from '@material-ui/core';
import CloseIcon from './CloseIcon';
import SuccessIcon from './SuccessIcon';
import FailedIcon from './FailedIcon';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LoadingButton from '@mui/lab/LoadingButton';


const RepayModal = ({loanApprovalId, trancheId,setTrancheId,repayAmt}) => {
    const [repayCompleted,setRepayCompleted] = useState('')
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const style = {
        position: "absolute",
        top: "50%",
        left: "57%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        maxHeight: "70%",
        overflow: "auto",
        bgcolor: "background.paper",
        borderRadius: "15px",
        boxShadow: 24,
        padding:'22px'
    };
    const classes = makemyStyles();

    const initialFValues = {
        amount: repayAmt,
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("amount" in fieldValues)
            temp.amount = (fieldValues.amount > 0) ? (Number(fieldValues.amount) > repayAmt) ? "Amount must be less than actual amount" : '' :  "Amount must be greater than zero"
        setErrors({ ...temp });
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    }
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, true, validate)

    const reverseAmountSubmit = (e)=>{
        e.preventDefault();
        if (validate()) {
            setLoading(true)
            dispatch(repayAmount({loanapproval_id :loanApprovalId,trancheId :trancheId}))
            .then(res=>{
                console.log('repay res => ',res);
                if(res?.payload !== undefined && res?.payload?.success === true){
                    setRepayCompleted(res?.payload)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            console.log('valid fields => ',values);
        }else{
            console.log(' field not valid');
        }
    }
    return (
        <div>
            <Modal open={trancheId ? true : false} onClose={()=>setTrancheId('')}>
                <Box sx={style}>
                    {
                        !repayCompleted?.success ?
                        <div className={classes.repayModalWrap}>
                            <div className={classes.repayModalTitle}>
                                <span>Repayment</span>
                                <div className={classes.closeIconStyle} onClick={()=>setTrancheId('')}>
                                    <CloseIcon/>                               
                                </div>
                            </div>
                            <form onSubmit={(e)=>reverseAmountSubmit(e)} autoComplete="off" className={classes.repayform}>
                                <FormControl className={classes.formcontrol}>
                                    <p style={{margin:0}}>Repayment Amount : <CurrencyRupeeIcon sx={{ fontSize: 15 }} />{repayAmt}</p> 
                                    
                                </FormControl>
                                <p>Are you sure you want to repay this amount?</p>
                                <div className={classes.actionBtnsWrap}>
                                    <button type='button' onClick={()=>setTrancheId('')} className={classes.reveseBack}>NO</button>
                                    <LoadingButton
                                        className={classes.reveseYes}
                                        loading={loading}
                                        loadingPosition="center"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {
                                            !loading ? 'YES' : ''
                                        }
                                    </LoadingButton>
                                </div>
                            </form>
                        </div>
                        :
                        repayCompleted?.success === true ?
                            <div className={classes.repayModalDoneWrap}>
                                <div className={classes.repayModalTitle}>
                                    <span>{repayCompleted?.message}</span>
                                    <div className={classes.closeIconStyle} onClick={()=>setTrancheId('')}>
                                        <CloseIcon/>                               
                                    </div>
                                </div>
                                <div className={classes.reversIcon}>
                                    <SuccessIcon/>
                                </div>
                                <div className={classes.actionBtnDoneWrap}>
                                    <button type='button' onClick={()=>setTrancheId('')} className={classes.reveseYes}>DONE</button>
                                </div>
                            </div>
                        :
                        repayCompleted?.success === false &&
                            <div className={classes.repayModalDoneWrap}>
                                <div className={classes.repayModalTitle}>
                                    <span>{repayCompleted?.message}</span>
                                    <div className={classes.closeIconStyle} onClick={()=>setTrancheId('')}>
                                    <CloseIcon/>
                                    </div>
                                </div>
                                <div className={classes.reversIcon}>
                                    <FailedIcon/>
                                </div>
                                <div className={classes.actionBtnDoneWrap}>
                                    <button type='button' onClick={()=>setTrancheId('')} className={classes.reveseYes}>DONE</button>
                                </div>
                            </div>
                    }
                </Box>
            </Modal>
        </div>
    )
}

export default RepayModal