export const INTEREST_CARD_HEADER = 'Interest Collected';
export const INTEREST_CARD_SUBHEADER = 'Chart how your business grows';

export const LOAN_OFFER_GEN_HEADER = 'Pre-Approved Loan Offers Generated';
export const LOAN_OFFER_GEN_SUBHEADER = '823,470.00';

export const REPAYMENTS_HEADER = 'Repayments';

export const DISBURSED_HEADER = 'Loan Disbursed';
export const DISBURSED_SUBHEADER = '823,470.00';

export const LOAN_SANCTIONED_HEADER = 'Loan Sanctioned';
export const LOAN_SANCTIONED_SUBHEADER = '823,470.00';

export const DEFAULTED_HEADER = 'Defaulted';
export const DEFAULTED_SUBHEADER = '823,470.00';

export const GRAPH_SIX_HEADER = 'Loan Defaulted - NPA';
export const GRAPH_SIX_SUBHEADER = '823,470.00';

export const TRANCHE_REQ_HEADER = 'Tranche Requests';
export const TRANCHE_REQ_SUBHEADER = '823,470.00';

export const DISBURSED_REGION_WISE_HEADER = 'Loan Disbursed - Region Wise';
export const DISBURSED_REGION_WISE_SUBHEADER = '2022';

export const GRAPH_NINE_HEADER = 'Due Amount';
export const GRAPH_NINE_SUBHEADER = '2022';


