import React, { useState, useEffect, createContext } from "react";
import { Paper, TableBody, TableRow, TableCell, Button, TextField, IconButton, Tooltip } from "@material-ui/core";
import useTable from "../Sharedfeatures/useTable";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import {
    getLoanSanctionedList,
    getLoanSanctionedExcel,
    getLoanAppStatement,
    generateExcel,
    reset_message,
    resetStateValues
} from "../../slice/loansanctioned";
import TrancheModal from "../Common/TrancheModal";
import { PAGE_SIZE } from "../../utils/variable";
import { LOAN_SANCTIONED_HEADER, LOAN_SANCTIONED_SUBHEADER } from "../../utils/pageHeadings";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker } from 'antd';
import LoanSanctionModal from "./LoanSanctionModal";
import { BsFillEyeFill } from "react-icons/bs";
import moment from 'moment';

const ApplicationContext = createContext()

const LoanSanctioned = () => {

    //For getting today and yesterday date

    let today = new Date();
    let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    yesterday = yyyy + '-' + mm + '-' + yest_dd;


    const classes = makemyStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [loanAppId, setLoanAppId] = useState();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const [excelSheetData, setExcelSheetData] = useState([]);

    const { loanSanctionedList, loanSanctionedExcel, totalCount, loanSanctionedView, isLoading, modalStatus, } = useSelector((state) => state.loanSanctioned);
    // console.log('SanctionSTATE', loanSanctionedView)

    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(loanSanctionedList, HeadCells.loanSanctionedHead, totalCount);

    const onBtnClick = (loanapproval_id) => {
        setLoanAppId(loanapproval_id);
        setOpen(!open);
        dispatch(reset_message())
    };

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    // useEffect(() => {
    //     const dates = {
    //         fromDate,
    //         toDate
    //     }
    //     dispatch(generateExcel(dates));
    // }, [fromDate, toDate]);

    const excelData = useExcelDownload();

    useEffect(() => {
        const data = {
            search: search,
            pageNo: pageNo,
            fromDate,
            toDate,
            totalCount,
        };
        dispatch(getLoanSanctionedList(data));
        dispatch(getLoanSanctionedExcel(data))
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, fromDate, toDate, totalCount]);

    let serialNo = PAGE_SIZE * slNo;

    //View Details Modal
    const [viewDetails, setViewDetails] = useState(false)

    const handleDetailsOpen = (loanapproval_id) => {
        dispatch(getLoanAppStatement(loanapproval_id))
        setViewDetails(true)
    }

    const handleDetailsClose = () => {
        setViewDetails(false)
        dispatch(resetStateValues())
    }

    const handleSearch = (value) => {
        setSearch(value)
        handleChange(null, 1)
    }

    useEffect(() => {
        let excelsheetdata = []
        loanSanctionedExcel?.map(item => {
            excelsheetdata.push({
                "RT/DT Code": item.customerid,
                "Loan A/C No": item.loanaccountNumber,
                "Name": item.name,
                // "Client ID": item.clientId,
                "Loan Product": item.loanName,
                "Tenure": item.tenure,
                "Principal": item.amount_sanctioned,
                "Interest Rate": item.interest_rate,
                "Disbursal Date": moment(item.disbursedOn).format('DD MMMM YYYY'),
                "Sanctioned Date": moment(item.sanctionedOn).format('DD MMMM YYYY'),
                "Status": item.status
            })
        })
        setExcelSheetData(excelsheetdata)
    }, [loanSanctionedExcel])

    return (
        <>
            <Paper className={classes.pageContent}>
                <PageHeader
                    header={LOAN_SANCTIONED_HEADER}
                    subHeader={LOAN_SANCTIONED_SUBHEADER}
                />

                
                <div style={{display:'flex', gap:'20px', alignItems:'flex-start', justifyContent:'space-between'}} >
                    <TextField
                        size="small"
                        fullWidth
                        className={classes.searchTextBar}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        variant="outlined"
                        placeholder="Search Name or RT/DT Code"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <BsSearch className={classes.searchIconColour} />
                                </IconButton>
                            ),
                        }}
                    />
                    <div style={{display:'flex', gap:'20px', alignItems:'flex-start', justifyContent:'space-between'}} >
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            format={dateFormat}
                            className={classes.datePicker}
                            onChange={onDateChange}
                        />
                        <Button
                            className={classes.excelUploadButton}
                            onClick={() =>
                                excelData.generateExcelFile(
                                    excelSheetData,
                                    "LoanSanctionedSheet",
                                    "LoanSanctioned.xlsx"
                                )
                            }
                            endIcon={<DownloadIcon />}
                        >
                            Download Report
                        </Button>
                    </div>
                </div>

                <Paper className={classes.tableBoxShadow}>
                    <TblContainer>
                        <TblHead />
                        {
                            isLoading ? (<TableBody><TableRow><TableCell colSpan={10} align='center' className={classes.tableCell}><ProgressSpinner /></TableCell></TableRow></TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (loanSanctionedList.length !== 0) ? (
                                            loanSanctionedList && loanSanctionedList
                                                .map((item, key) => {
                                                    key++;
                                                    serialNo++;
                                                    return (<TableRow key={key} >
                                                        <TableCell className={classes.tableCell}>{serialNo}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.customerid}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.loanaccountNumber}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.loanName}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.tenure}</TableCell>
                                                        <TableCell className={classes.tableCell}>{item.amount_sanctioned}</TableCell>
                                                        <TableCell className={classes.tableCell} align='center'>{item.interest_rate}</TableCell>
                                                        <TableCell className={classes.tableCell} align='center'>
                                                            <Button onClick={() => onBtnClick(item.loanapproval_id)} variant="outlined" color="primary">Show</Button>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Link to={`/admin/loansanctioned/loanaccountdetails/${item.clientId}`}>
                                                                <Button variant="outlined" color="primary">
                                                                    View
                                                                </Button>
                                                            </Link>
                                                        </TableCell>

                                                        <TableCell align="center" className={classes.tableCell}>
                                                            <IconButton onClick={() => handleDetailsOpen(item.loanapproval_id)}>
                                                                <BsFillEyeFill className={classes.actionIcon} />
                                                            </IconButton>
                                                        </TableCell>
                                                        {/* <TableCell align="center"
                                                            className={classes.tableCell}
                                                            onClick={() => handleDetailsOpen(item.loanapproval_id)}
                                                        >
                                                            <Link to="#">
                                                                <Button variant="outlined" color="primary">
                                                                    View
                                                                </Button>
                                                            </Link>
                                                        </TableCell> */}
                                                    </TableRow>)
                                                })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={10} align='center'>No Data to Show</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            )
                        }
                    </TblContainer>
                </Paper>
                {open ? (<TrancheModal open={open} onClose={onBtnClick} id={loanAppId} />) : null}
                {
                    showPageNtn ? <TblPagination /> : ''
                }
            </Paper>


            <ApplicationContext.Provider value={loanSanctionedView}>
                {
                    viewDetails ? <LoanSanctionModal open={viewDetails} handleClose={handleDetailsClose} /> : ''
                }
            </ApplicationContext.Provider>

        </>
    );
};

export default LoanSanctioned;
export { ApplicationContext }
