import React, { useEffect, useState } from "react";
import "./Dashboardstyles.css";
import BigGraphHeader from "./BigGraphHeader";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { INTEREST_CARD_HEADER } from "../../utils/graphHeadings";
import { useDispatch, useSelector } from "react-redux";
import { getInterestData, getInteresetDataByYear } from "../../slice/dashboard";
import useYAxisFormatter from "../Sharedfeatures/useYAxisFormatter";
import { BiRupee } from "react-icons/bi";
import { Card } from "@material-ui/core";

const GraphOne = ({year}) => {
  // const [year, setYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const { interestCollected, totalInterestCollected } = useSelector((store) => store.dashboard);

  // const interestCollected = [
  //   {
  //     "month": "January",
  //     "amount": 10000,
  //     "charges": 10
  //   },
  //   {
  //     "month": "February",
  //     "amount": 10,
  //     "charges": 30
  //   },
  //   {
  //     "month": "March",
  //     "amount": 20,
  //     "charges": 20
  //   },
  //   {
  //     "month": "April",
  //     "amount": 40,
  //     "charges": 10
  //   },
  //   {
  //     "month": "May",
  //     "amount": 20,
  //     "charges": 50
  //   },
  //   {
  //     "month": "June",
  //     "amount": 50,
  //     "charges": 40
  //   },
  //   {
  //     "month": "July",
  //     "amount": 40,
  //     "charges": 30
  //   },
  //   {
  //     "month": "August",
  //     "amount": 30,
  //     "charges": 30
  //   },
  //   {
  //     "month": "September",
  //     "amount": 50,
  //     "charges": 20
  //   },
  //   {
  //     "month": "October",
  //     "amount": 10,
  //     "charges": 30
  //   },
  //   {
  //     "month": "November",
  //     "amount": 20,
  //     "charges": 30
  //   },
  //   {
  //     "month": "December",
  //     "amount": 10,
  //     "charges": 50
  //   }
  // ]

  const { dataFormater } = useYAxisFormatter();

  useEffect(() => {
    const data = {
      year
    }
    dispatch(getInterestData(data));
  }, [year, dispatch]);

  // const handleYearChange = () => {
  //   const data = {
  //     year
  //   }
  //   dispatch(getInterestData(data));
  // };

  let data = [];
  interestCollected.map((item) => {
    let cdata = {
      // name: item.month.substring(0, 3),
      // value: Number(item.amount),
      // charge: Number(item.charges)
      name: item.month.substring(0, 3),
      amount: Number(item.amount),
      month: item.month + "" + item.value,
      charge: Number(item.charges)
    };
    data.push(cdata);
  });

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
      <g>
        <text
          x={x + 25}
          y={405}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="11px"
        >
          ₹ {value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    //This block is for displaying custom tooltip content
    if (active && payload && payload.length == 2) {
      return (
        <div>
          <Card style={{ padding: "7px 12px" }}>
            <p>{`${label}`}</p>
            <p>{`${payload[0].name} : ₹ ${payload[0].value}`}</p>
            <p>{`${payload[1].name} : ₹ ${payload[1].value}`}</p>
          </Card>
        </div>
      );
    }

    else if (active && payload && payload.length < 2) {
      return (
        <>
          <div>
            <Card style={{ padding: '7px 12px' }}>
              <p>{`${label} : ₹ ${payload[0].value}`}</p>
            </Card>
          </div>
        </>
      )
    }

    else return null;
  };

  let curYear = new Date().getFullYear()
  let years = []
  for (let i = curYear; i >= 2022; i--) {
    years.push(i)
  }

  return (
    <div className="graph-one-card">
      <header className="graph-one-card-header">
        <BigGraphHeader header={INTEREST_CARD_HEADER} />

        <article className="ledgers">
          <article className="ledg-first-col">
            <h3 className='ledg-title'><article className='ledg-circle-one' />&nbsp;&nbsp;&nbsp;Interest collected</h3>
            <h3 className='ledg-title'><article className='ledg-circle-two' />&nbsp;&nbsp;&nbsp;Charge</h3>
          </article>
        </article>
      </header>

      <div className="right-info-card">
        {/* <label className="right-info-card-label" htmlFor="year">
          Year&nbsp;&nbsp;&nbsp;
        </label>
        <select
          id="year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {
            years.map(item => <option key={item} value={item}>{item}</option>)
          }
        </select>
        &nbsp;&nbsp;&nbsp;
        <button className="go-button" onClick={handleYearChange}>
          GO!
        </button> */}
        <div>
          <h3 className="int-collected">Total Interest Collected This Year</h3>
          <h5 className="int-collected-sum">
            <BiRupee />
            {Number(Number(totalInterestCollected).toFixed(2)).toLocaleString()}
          </h5>
        </div>
      </div>
      <ResponsiveContainer width="68%" height="80%">
        <BarChart
          width={790}
          height={500}
          data={data}
          margin={{
            top: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis dataKey="name" axisLine={true} />
          <YAxis datakey="value" axisLine={true} tickFormatter={dataFormater} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="amount" fill="#2DB6F5" />
          <Bar dataKey="charge" fill="#39C86A" />

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraphOne;
