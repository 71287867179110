import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core'

const Selectcustomer = (props) => {
    const { name, label, onChange, options, error = null, textFieldProps, value, ...other } = props

    return (
        <FormControl variant='outlined' {...(error && { error: true })}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <MuiSelect
                MenuProps={{
                    style: {
                        maxHeight: 400,
                    },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size='small'
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                // option={options}
            >
                {/* <MenuItem value="" sx={{ height: '150px', marginTop: '100px' }}>Select {label}</MenuItem> */}
                {
                    options.map((item, key) =>
                        (<MenuItem value={item.id}>{item.name}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export default Selectcustomer