import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useForm, Form } from "../Sharedfeatures/useForm";
import Input from "../Controls/Input";
import Button from "../Controls/Button";
import Controls from "../Controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { addFos, getRegionList, reset_state, editFos } from "../../slice/fos";
import { getEmployeeDetails, resetstate } from "../../slice/employee";
import { useNavigate, useParams } from "react-router-dom";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import { FOS_LIST } from "../../utils/links";
import {
  EDIT_FOS_HEADER,
  ADD_FOS_HEADER,
  ADD_FOS_SUB_HEADER,
  BACK_BUTTON,
} from "../../utils/pageHeadings";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from "../Sharedfeatures/useSnackbar";

const initialValues = {
  name: "",
  email: "",
  role: "fos",
  user_type: "6",
  mobile: "",
  region: "",
  userid: "",
};

export default function FosForm() {
  const classes = makemyStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackBar = useSnackbar();

  const { id } = useParams();
  const header = id ? EDIT_FOS_HEADER : ADD_FOS_HEADER;

  const { addFosEmployee, fosDetails, message, error_message, success, email, mobile, regionList, request_id } = useSelector((state) => state.fos);
  const { employeeDetails } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(getRegionList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (email) setErrors({ ...errors, email: email });
    if (mobile) setErrors({ ...errors, mobile: mobile });
    if (employeeDetails) {
      setValues({
        ...values,
        name: employeeDetails && employeeDetails.name,
        email: employeeDetails && employeeDetails.email,
        mobile: employeeDetails && employeeDetails.mobile,
        region:
          employeeDetails &&
          employeeDetails.region &&
          employeeDetails.region.id,
        userid: employeeDetails && employeeDetails.userid,
      });
      dispatch(resetstate());
    }
  }, [email, mobile, employeeDetails]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    temp.name =
      "name" in fieldValues
        ? fieldValues.name
          ? ""
          : "This field is required"
        : "";

    if (id) {
      temp.userid = "userid" in fieldValues ? fieldValues.userid ? "" : "This field is required" : "";
    }

    temp.mobile =
      "mobile" in fieldValues
        ? (fieldValues.mobile ? ((fieldValues.mobile == "0000000000") ? "Invalid Phone Number" : "") : "This field is required") ||
        (fieldValues.mobile.length == 10 ? "" : (fieldValues.mobile.length > 0 && fieldValues.mobile.length < 10) ? "Minimum 10 numbers required" : "Maximum 10 numbers allowed")
        : "";
    temp.region =
      "region" in fieldValues
        ? fieldValues.region
          ? ""
          : "This field is required"
        : "";
    temp.email =
      "email" in fieldValues
        ? (fieldValues.email ? "" : "This field is required.") ||
        (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(fieldValues.email) ? "" : "Email is not valid.")
        : "";

    setErrors({
      ...temp,
    });

    return fieldValues == values
      ? Object.values(temp).every((x) => x == "")
      : "";
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  // useEffect(() => {
  //   dispatch(reset_state());
  // }, [request_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (!id) {
        delete values.userid
        dispatch(addFos(values)).then((res) => {
          if (
            res.payload.message === "successfully created" &&
            res.payload.success === true
          ) {
            snackBar.openSnackbar(res.payload.message);
            setValues(initialValues);
            setTimeout(() => {
              navigate(`${FOS_LIST}`);
            }, 2000);
            setValues(initialValues);
          } else {
            // fosError()
            // snackBar.openSnackbar(message);
          }
        });
      } else if (id) {
        const edit_fos_data = {
          ...values,
          id
        }
        dispatch(editFos(edit_fos_data)).then((res) => {
          if (
            res.payload.message === "Successfully updated" &&
            res.payload.success === true
          ) {
            snackBar.openSnackbar(res.payload.message);
            setValues(initialValues);
            setTimeout(() => {
              navigate(`${FOS_LIST}`);
            }, 2000);
            setValues(initialValues);
          } else {
            setTimeout(() => {
              dispatch(reset_state())
            }, 3000)

          }
        })
          .catch((error) => {
            alert(error)
          })

      }
    }
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={header}
          subHeader={ADD_FOS_SUB_HEADER}
          icon={BACK_BUTTON}
          link={FOS_LIST}
        />

        <Form onSubmit={handleSubmit}>
          <Paper className={classes.addNewPageContent}>
            {
              error_message && <p style={{ color: '#ff0000', paddingLeft: '8px' }}>{error_message}</p>
            }
            <Grid container>
              <Grid item xs={6}>
                <Input
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  onKeyDown={(event) => {
                    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                {
                  id && <Input
                    label="Fos Id"
                    name="userid"
                    value={values.userid}
                    onChange={handleInputChange}
                    error={errors.userid}
                  />
                }
                {
                  !id && <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                }
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Mobile"
                  name="mobile"
                  type="number"
                  value={values.mobile}
                  onChange={handleInputChange}
                  error={errors.mobile}
                />
                <Controls.Selectregion
                  name="region"
                  label="Region"
                  value={values.region}
                  onChange={handleInputChange}
                  options={regionList}
                  error={errors.region}
                />
                <div className="ml-2">
                  <Button
                    type="submit"
                    text="Submit"
                    className={classes.submitButton}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Form>
        <div>
          <Snackbar {...snackBar} />
        </div>
      </Paper>
    </>
  );
}
