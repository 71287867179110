import React from 'react'
import { Routes, Route } from 'react-router-dom'
import routes from '../Routes'
const Auth = () => {
    const getRoutes = (routes) => {
        return routes.map((route, key) => {
            // if (route.collapse) {
            //     return getRoutes(route.views);
            // }
            if (route.layout === '/auth') {
                return (
                    <Route path={route.path} key={key} element={<route.component />} />
                );
            } else {
            return null
            }
        })
    }


    return (
        <Routes>{getRoutes(routes)}</Routes>
    )
}

export default Auth

