import { Column } from '@ant-design/charts';
import React from 'react'
import { formatRupees } from '../../../../utils/formatRupees';


const PreapprovedChart = ({ chartData }) => {
    const config = {
        data: chartData,
        xField: 'month',
        yField: 'amount',
        color: '#9DDCDC',
        label: {
            position: 'top',
            style: {
                fill: '#027878',
                opacity: 1,
            },
            content: (originData) => {
                return originData?.count !== 0 ? originData?.count : ''
            },
        },
        xAxis: {
            tickCount: 12,
        },
        yAxis: {
            min: 0,
            label: {
                formatter: (val) => `${val < 1000 ? '₹ ' + val : formatRupees(val)}`,
            },
            title: {
                text: 'Amount'
            },
        },
        padding: [50, 20, 20, 80]
    };

    return (
        <Column {...config} />
    )
}

export default PreapprovedChart