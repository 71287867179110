import React, { useEffect } from "react";
import {
  TableRow,
  Box,
  TableCell,
  TableBody,
  IconButton,
  Typography,
} from "@material-ui/core";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import useTable from "../Sharedfeatures/useTable";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { getTrancheDetails } from "../../slice/loansanctioned";
import HeadCells from "../Common/HeadCells";
import PageHeader from "../Common/PageHeader";
import { TRANCHE_HEADER, TRANCHE_SUB_HEADER } from "../../utils/pageHeadings";
import ProgressSpinner from "./ProgressSpinner";

const TrancheModal = ({ open, onClose, id, subHeader, loading }) => {

  const dispatch = useDispatch();
  const classes = makemyStyles();
  const moment = require("moment");

  const style = {
    position: "absolute",
    top: "50%",
    left: "57%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    maxHeight: "70%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "15px",
    p: 7,
  };

  useEffect(() => {
    dispatch(getTrancheDetails(id));
  }, [dispatch]);

  const { trancheDetails } = useSelector((state) => state.loanSanctioned);

  const { TblContainer, TblHead } = useTable(
    trancheDetails,
    HeadCells.trancheModal
  );

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <PageHeader header={TRANCHE_HEADER} subHeader={subHeader} />

          <IconButton
            style={{ position: "absolute", right: "20px", top: "20px" }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <TblContainer>
            <>
              <TblHead />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align='center'>
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {trancheDetails && trancheDetails.length != 0 ? (
                    trancheDetails &&
                    trancheDetails.map((item, key) => {
                      key++;
                      return (
                        <TableRow key={key}>
                          <TableCell className={classes.tableCell}>
                            {key}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.trancheid}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.tranche_amount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loan_amount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(item.expected_disbursal_date).format(
                              "DD MMMM YYYY"
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(item.payback_date).format("DD MMMM YYYY")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.status_text}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Data to Show
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>)}
            </>
          </TblContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default TrancheModal;
