import React, { useEffect, useState } from "react";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Button as MuiButton } from '@material-ui/core'
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import useTable from "../Sharedfeatures/useTable";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import {
  getFos,
  getFosExcel,
  enableFosRecord,
  disableFosRecord,
  reset_state,
} from "../../slice/fos";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import PageHeader from "../Common/PageHeader";
import HeadCells from "../Common/HeadCells";
import ProgressSpinner from "../Common/ProgressSpinner";
import Button from "../Controls/Button";
import { PAGE_SIZE } from "../../utils/variable";
import { ADD_FOS, EDIT_FOS } from "../../utils/links";
import { FOS_HEADER, FOS_SUB_HEADER } from "../../utils/pageHeadings";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from "../Sharedfeatures/useSnackbar";
import useExcelDownload from "../Sharedfeatures/useExcelDownload";
import DownloadIcon from "@mui/icons-material/Download";
import Controls from "../Controls/Controls";
import './selectdropdown.css'


function Fos() {
  const classes = makemyStyles();
  const dispatch = useDispatch();
  const snackBar = useSnackbar();
  const excelData = useExcelDownload();
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState("");

  const [select, setSelect] = useState("");

  const handleCreateNewItem = (e) => {
    setSelect(e.target.value);
  };
  const selectItems = [
    { key: '1', value: 'All', label: 'All' },
    { key: '2', value: 'true', label: 'Enabled' },
    { key: '3', value: 'false', label: 'Disabled' }
  ]

  const { fosList, fosExcel, totalCount, isLoading, error } = useSelector((state) => state.fos);
  const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(
    fosList, //passing list of Fos to table
    HeadCells.fosHead, //passing name of table heads to table
    totalCount // passing the count of total items
  );

  useEffect(() => {
    return error ? snackBar.openSnackbar(error && error.message) : ""
  }, [error])

  useEffect(() => {
    dispatch(reset_state()); //dispatch function to reset the state
    const data = {
      searchTerm: search,
      pageNum: pageNo,
      totalCount,
      select
    };
    dispatch(getFos(data));
    dispatch(getFosExcel(data));  // dispatch function to get Fos Data on pagination
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, select, totalCount]);


  //function to enable an Fos
  const enableFos = (item) => {
    const enablingData = {
      user_id: `${item._id}`,
      status: "active",
    };
    dispatch(enableFosRecord(enablingData));
  };

  //function to disable an Fos
  const disableFos = (item) => {
    const disablingData = {
      user_id: `${item._id}`,
      status: "inactive",
    };
    dispatch(disableFosRecord(disablingData));
  };

  let serialNo = PAGE_SIZE * slNo;

  const handleSearch = (value) => {
    setSearch(value)
    handleChange(null, 1)
  }

  useEffect(() => {
    let excelsheetdata = []
    fosExcel?.map(item => {
      excelsheetdata.push({
        "FOS Id": item.userid,
        "Name": item.name,
        "Email": item.email,
        "Mobile": item.mobile,
        "Region": item.region_name,
        // "Status": item.active,
      })
    })
    setExcelSheetData(excelsheetdata)
  }, [fosExcel])

  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader header={FOS_HEADER} subHeader={FOS_SUB_HEADER} />
        <div className="action-wrap">
          <div className="searchwrap">
            <TextField
              size="small"
              className={classes.searchTextBar}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              id="standard-bare"
              variant="outlined"
              placeholder="Search FOS Name"
              InputLabelProps={{ shrink: false }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <BsSearch className={classes.searchIconColour} />
                  </IconButton>
                ),
              }}
            />
            <div className="selectdiv">
              <select
                name="select"
                label="select"
                value={select}
                onChange={handleCreateNewItem}
                className={classes.lpselectDropdownBar}
              >
                {/* <option value={0} disabled>Status</option> */}
                {
                  selectItems.map(item => (
                    <option key={item.key} value={item.value}>{item.label}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="action-btn-wrap">
            <Link to={`${ADD_FOS}`}>
              <Button
                text="Add New"
                variant="outlined"
                startIcon={<AddBoxOutlinedIcon />}
                className={classes.newButton}
              />
            </Link>
            <MuiButton
              className={classes.excelUploadButton}
              onClick={() =>
                excelData.generateExcelFile(
                  excelSheetData,
                  "FOSDataSheet",
                  "FOSData.xlsx"
                )
              }
              endIcon={<DownloadIcon />}
            >
              Download Report
            </MuiButton>
          </div>
        </div>


        <Paper className={classes.tableBoxShadow}>
          <TblContainer>
            <TblHead />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    className={classes.tableCell}
                  >
                    <ProgressSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {fosList.length !== 0 ? (
                  fosList &&
                  fosList.map((item, key) => {
                    serialNo++;
                    return (
                      <TableRow key={item.userid.toString()}>
                        <TableCell className={classes.tableCell}>
                          {serialNo}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.userid}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.name}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          {item.email}
                        </TableCell> */}
                        <TableCell className={classes.tableCell}>
                          {item.region && item.region.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.mobile}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.active ? "Enabled" : "Disabled"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Tooltip
                            title={
                              item.active === true
                                ? "Disable Fos"
                                : "Enable Fos"
                            }
                          >
                            <IconButton
                              onClick={
                                !item.active
                                  ? () => enableFos(item)
                                  : () => disableFos(item)
                              }
                            >
                              {item.active === true ? (
                                <CancelIcon />
                              ) : (
                                <CheckCircleIcon />
                              )}
                            </IconButton>
                          </Tooltip>

                          <Link to={`${EDIT_FOS}/${item._id}`}>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </TblContainer>
        </Paper>
        {showPageNtn ? <TblPagination /> : ""}
        <div>
          <Snackbar {...snackBar} />
        </div>
      </Paper>
    </>
  );
}

export default Fos;
