import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getInterestData = createAsyncThunk(
    'getInterestData',
    async(data) => {
        const url = `/dashboard/lastoneyear`;
        const res = await DataService.post(url,data);
        return res.data
    }
)

export const getPreApprovedLoanOffers = createAsyncThunk(
    'getPreApprovedLoanOffers',
    async(year) => {
        const url = `/dashboard/loanoffer-generated-monthwise?year=${year}`;
        const res = await DataService.get(url);
        return res.data
    }
)

export const getRepaymentsData = createAsyncThunk(
    'getRepaymentsData',
    async (year) => {
        const url = `/dashboard/repayments-monthwise?year=${year}`
        const res = await DataService.get(url);
        return res.data
    }
)

export const getTrancheRequestData = createAsyncThunk(
    'tranche-request-data',
    async() => {
        const url = `/dashboard/today-Tranche-Requests`;
        const res = await DataService.get(url);
        return res.data
    }
)

export const getSanctionedData = createAsyncThunk(
    'getSanctionedData',
    async(year) => {
        const url = `/dashboard/loan-sanctioned-monthwise?year=${year}`;
        const res = await DataService.get(url);
        return res.data
    }
)

export const getDisbursedData = createAsyncThunk(
    'getDisbursedData',
    async(year) => {
        const url = `/dashboard/loan-disburesed-monthwise?year=${year}`;
        const res = await DataService.get(url);
        return res.data
    }
)

export const getDefaultedData = createAsyncThunk(
    'getDefaultedData',
    async(year) => {
        const url = `dashboard/total-loans-defaulted?year=${year}`;
        const res = await DataService.get(url);
        return res.data
    }
)

export const getRegionData = createAsyncThunk(
    'getRegionData',
    async({region, year}) => {
        const url = `dashboard/loan-disbursed-region-wise?region=${region}&year=${year}`;
        const res = await DataService.get(url);
        // console.log("RESPONSE", res)
        return res.data
    }
)


const initialState = {
    interestCollected: [],
    totalInterestCollected: '0',

    preApprovedLoanOffers: [],
    preApprovedLoanOffers_totalVolume: '0',

    repayments: [],
    repayments_totalVolume: '0',

    loanDisbursed: [],
    loanDisbursed_totalVolume: '0',

    loanSanctioned: [],
    loanSanctioned_totalVolume: '0',

    tranche_request_count: '0',
    tranche_request_volume: '0',

    loan_applications: [],
    loan_applications_volume: '0',

    loan_defaulted_volume: '0',
    loan_defaulted: [],

    amount_due: [],

    loandisbursed_graph_geographical:[],

    yearData: {},
    monthlyData:[],
    todayData: {},

};

const dashboardSlice = createSlice({
    name:'dashboard',
    initialState,
    extraReducers: {
        [getInterestData.fulfilled]: (state, action) => {
            state.interestCollected = action.payload.data;
            state.totalInterestCollected = action.payload.totalvolume;    
        },
        [getPreApprovedLoanOffers.fulfilled]: (state,action) => {
            state.preApprovedLoanOffers = action.payload.data;
            state.preApprovedLoanOffers_totalVolume = action.payload.totalvolume;
        },
        [getRepaymentsData.fulfilled]: (state, action) => {
            state.repayments = action.payload.data;
            state.repayments_totalVolume = action.payload.totalvolume
        },
        [getTrancheRequestData.fulfilled]: (state, action) => {
            state.tranche_request_count = action.payload.today_tanche_requests.count
            state.tranche_request_volume = action.payload.today_tanche_requests.volume
        },
        [getSanctionedData.fulfilled]: (state,action) => {
            state.loanSanctioned = action.payload.data;
            state.loanSanctioned_totalVolume = action.payload.totalvolume;
        },
        [getDisbursedData.fulfilled]: (state,action) => {
            state.loanDisbursed = action.payload.data;
            state.loanDisbursed_totalVolume = action.payload.totalvolume;
        },
        [getDefaultedData.fulfilled]: (state, action) => {
            state.loan_defaulted = action.payload.data;
            state.loan_defaulted_volume = action.payload.total_volume
        },
        [getRegionData.fulfilled]: (state, action) => {
            state.yearData = action.payload.data.yearbased;
            state.monthlyData= action.payload.data.monthly;
            state.todayData= action.payload.data.today;
        }
        
    }
})

export default dashboardSlice.reducer