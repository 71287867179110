import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => createStyles({
    gridContainerParent: {
        paddingLeft: "30px", 
        paddingTop: '20px', 
        paddingBottom: '20px'
    },
    card: {
      position: "absolute",
      width: "1000px",
      left: "300px",
      top: "183px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      borderRadius: "16px",
      backgroundColor: "#FFFFFF",
    },
    custContainer: {
      paddingLeft: "30px",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    pageContent: {
      // margin: theme.spacing(5),
      // padding: theme.spacing(3),
      width: "100%",
      boxShadow: "none",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
    toolBar: {
      top: 0,
    },
    employeeHeader: {
      position: "absolute",
      // width: '222px',
      height: "45px",
      top: "75px",
      left: "70px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "30px",
      lineHeight: "45px",
      letterSpacing: "0.08em",
      color: "#963460",
    },
    employeeHeaderBackIcon: {
      position: "absolute",
      // left: '39.29%',
      // right: '39.29%',
      top: "83px",
      height: "45px",
      fontFamily: "Poppins",
      color: "#963460",
      fontWeight: "600",
      fontSize: "30px",
      lineHeight: "45px",
    },
    employeeListing: {
      position: "absolute",
      width: "250px",
      height: "15px",
      left: "300px",
      top: "136px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "10px",
      lineHeight: "15px",
      color: "#242424",
      letterSpacing: "0.08em",
    },
    employeeItems: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#1A0045",
      letterSpacing: "0.08em",
    },
  })
);
