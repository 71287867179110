import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  ListItemText,
  Container,
} from "@material-ui/core";
import PageHeader from "../Common/PageHeader";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import makemyStyles from "../Sharedfeatures/SharedStyles";
import { LOAN_PRODUCT_URL } from "../../utils/links";
import {
  LOAN_DETAILS_HEADER,
  LOAN_DETAILS_SUBHEADER,
  BACK_BUTTON,
} from "../../utils/pageHeadings";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loanProductDetails } from "../../slice/loanproducts"
import CurrencyView from "./CurrencyView";
import Termsview from "./TermsView";
import SettingsView from "./SettingsView";
import Skeleton from 'react-loading-skeleton'
import ChargesView from "./ChargesView";

const LoanViewDetails = () => {
  const sharedClasses = useSharedClasses();
  const classes = makemyStyles();
  const { _id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loanProductDetails(_id))
  }, [_id])

  const { loanDetails } = useSelector(
    (store) => store.loanProducts
  );
 
  return (
    <>
      <Paper className={classes.pageContent}>
        <PageHeader
          header={LOAN_DETAILS_HEADER}
          subHeader={LOAN_DETAILS_SUBHEADER}
          icon={BACK_BUTTON}
          link={LOAN_PRODUCT_URL}
        />

        <Paper className={classes.detailsCard}>
          <Container className={sharedClasses.parentContainer}>
            <Grid container style={{ marginTop: "10px", marginBottom: "25px" }}>
              <Grid item xs={10}>
                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                  <Grid item md={6}>
                    <ListItemText
                      primary={<Typography className={classes.employeeItems}>Name:</Typography>}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <ListItemText
                      primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.name || <Skeleton />}</Typography>}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={10}>
                <Grid container spacing={25} style={{ paddingLeft: "30px" }}>
                  <Grid item md={6}>
                    <ListItemText
                      primary={<Typography className={classes.employeeItems}>Short Name:</Typography>}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <ListItemText primary={<Typography className={classes.employeeItemsDetails}>{loanDetails.short_name || <Skeleton />}</Typography>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CurrencyView />
            <Termsview />
            <ChargesView />
            <SettingsView />
          </Container>
        </Paper>
      </Paper>
    </>
  );
};

export default LoanViewDetails;
